import {OperationType_N} from "@enums/OperationType_N";
import {Expose, Type} from "class-transformer"
import ProjectOperation from "@models/operations/components/ProjectOperation";
import CurrencyOperation from "@models/operations/components/CurrencyOperation";
import PaymentSystemOperation from "@models/operations/components/PaymentSystemOperation";
import {BuyingOperationStatus} from "@enums/BuyingOperationStatus";
import RequisitesOperation from "@models/operations/components/RequisitesOperation";
import {PurchaseOperationSubtype} from "@enums/PurchaseOperationSubtype";
import Agent from "@models/operations/components/Agent";
import ChatOperation from "@models/operations/components/ChatOperation";
import DestinationOperation from "@models/operations/components/DestinationOperation";
import dayjs from "dayjs";

export default class PurchaseOperation {
    @Expose({name: 'id'})
    private _id: number | null = null;

    @Expose({name: 'project'})
    @Type(() => ProjectOperation)
    private _project: ProjectOperation | null = null;

    @Expose({name: 'msid'})
    private _msid: string | null = null;

    @Expose({name: 'agent'})
    @Type(() => Agent)
    private _agent: Agent | null = null;

    @Expose({name: 'subtype'})
    private _subtype: PurchaseOperationSubtype | null = null;

    @Expose({name: 'amount'})
    private _amount: number | null = null;

    @Expose({name: 'amountOrigin'})
    private _amountOrigin: number | null = null;

    @Expose({name: 'amountUsd'})
    private _amountUsd: number | null = null;

    @Expose({name: 'amountUsdOrigin'})
    private _amountUsdOrigin: number | null = null;

    @Expose({name: 'currency'})
    @Type(() => CurrencyOperation)
    private _currency: CurrencyOperation | null = null;

    @Expose({name: 'destination'})
    @Type(() => DestinationOperation)
    private _destination: DestinationOperation | null = null;

    @Expose({name: 'psystemObj'})
    @Type(() => PaymentSystemOperation)
    private _psystemObj: PaymentSystemOperation | null = null;

    @Expose({name: 'account'})
    private _account: string | null = null;

    @Expose({name: 'requisites'})
    @Type(() => RequisitesOperation)
    private _requisites: RequisitesOperation | null = null;

    @Expose({name: 'status'})
    private _status: BuyingOperationStatus | null = null;

    @Expose({name: 'chat'})
    @Type(() => ChatOperation)
    private _chat: ChatOperation | null = null;

    @Expose({name: 'endsIn'})
    private _endsIn: string | null = null;

    @Expose({name: 'acceptedAt'})
    private _acceptedAt: string | null = null;

    @Expose({name: 'operationType'})
    private _operationType: OperationType_N;

    @Expose({name: 'createdAt'})
    private _createdAt: string | null = null;

    @Expose({name: 'updatedAt'})
    private _updatedAt: string | null = null;

    @Expose({name: 'successUrl'})
    private _successUrl: string | null = null;

    @Expose({name: 'failUrl'})
    private _failUrl: string | null = null;

    public resetChatUnreadCount() {
        if (this._chat) {
            this._chat.unread = 0;
        }
    }

    public incrementChatUnreadCount() {
        if (this._chat) {
            this._chat.unread++;
        }
    }

    get id(): number | null {
        return this._id;
    }

    get failUrl(): string | null {
        return this._failUrl;
    }

    get amountUsdOrigin(): number | null {
        return this._amountUsdOrigin;
    }

    get successUrl(): string | null {
        return this._successUrl;
    }

    get msid(): string | null {
        return this._msid;
    }

    get agent(): Agent | null {
        return this._agent;
    }

    get amountOrigin(): number | null {
        return this._amountOrigin;
    }

    get amountUsd(): number | null {
        return this._amountUsd;
    }

    get chat(): ChatOperation | null {
        return this._chat;
    }

    get subtype(): PurchaseOperationSubtype | null {
        return this._subtype;
    }

    get amount(): number | null {
        return this._amount;
    }

    get account(): string | null {
        return this._account;
    }

    get updatedAt(): string | null {
        return this._updatedAt;
    }

    get currency(): CurrencyOperation | null {
        return this._currency;
    }

    get status(): BuyingOperationStatus | null {
        return this._status;
    }

    get destination(): DestinationOperation | null {
        return this._destination;
    }

    get requisites(): RequisitesOperation | null {
        return this._requisites;
    }

    set msid(msid: string | null) {
        this._msid = msid;
    }

    set status(status: BuyingOperationStatus) {
        this._status = status
    }

    get psystemObj(): PaymentSystemOperation | null {
        return this._psystemObj;
    }

    get project(): ProjectOperation | null {
        return this._project;
    }

    get operationType(): OperationType_N {
        return this._operationType;
    }

    get createdAt(): string | null {
        return this._createdAt;
    }

    public getHumanCreateAt() {
        return dayjs(this._createdAt).format('DD.MM.YYYY HH:mm');
    }
}
