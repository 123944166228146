<template>
    <div class="payment_requisite__addition">
        <div class="addition_advices">
            <p class="title">{{ $t('light-payment.additional.advices.title') }}</p>
            <ul class="steps">
                <li class="step">
                    <div class="number">1</div>
                    <p class="text">{{ $t('light-payment.additional.advices.first') }}</p>
                </li>
                <li class="step">
                    <div class="number">2</div>
                    <template v-if="addressType === 'card'">
                        <p class="text">{{ $t('light-payment.additional.advices.second.card') }}</p>
                    </template>
                    <template v-else>
                        <p class="text">{{ $t('light-payment.additional.advices.second.phone') }}</p>
                    </template>
                </li>
                <li class="step">
                    <div class="number">3</div>
                    <p class="text">{{ $t('light-payment.additional.advices.third') }}</p>
                </li>
                <li class="step">
                    <div class="number">4</div>
                    <p class="text">{{ $t('light-payment.additional.advices.fourth') }}</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">
import {PropType, Ref, toRef, UnwrapRef} from "vue";

const props = defineProps({
    addressType: {
        type: [String, null] as PropType<string | null>,
        required: true
    }
});
const addressType: Ref<string | null> = toRef(props, 'addressType');
</script>

<style lang="scss">
.payment_requisite__addition {
    margin-top: 16px;

    .addition_comment {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .title {
            margin-left: 12px;
            @include text(14px, 400, var(--grey2), 16.8px);
        }

        .comment__wrapper {
            border-radius: 18px;
            background-color: #F7F7F8;
            padding: 8px 12px;
            position: relative;

            .text {
                word-wrap: break-word;
                @include text(16px, 400, var(--brown1), 19.2px);
            }

            .corner {
                position: absolute;
                left: -5px;
                bottom: 0;
                width: 17px;
                height: 21px;
                background-image: url("data:image/svg+xml,%3Csvg width='17' height='21' viewBox='0 0 17 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.112661 20.1846C5.31266 20.9846 10.446 18.1212 12.1127 16.2879C10.3948 12.1914 21.0006 2.24186 14.0006 2.24148C12.382 2.24148 10.9995 -1.9986 5.11266 1.1846C5.09145 2.47144 5.11266 6.92582 5.11266 7.6842C5.11266 18.1842 -0.887339 19.5813 0.112661 20.1846Z' fill='%23F7F7F8'/%3E%3C/svg%3E%0A");
            }
        }
    }

    .addition_advices {
        margin-top: 32px;

        .title {
            @include text(16px, 700, var(--brown1), 19.2px);
        }

        .steps {
            display: flex;
            flex-direction: column;
            gap: 12px;
            list-style-type: none;
            padding: 0;

            .step {
                display: flex;
                align-items: center;
                height: 28px;
                gap: 12px;
                position: relative;

                & + .step {
                    &::after {
                        content: "";
                        background-color: rgba(0, 164, 41, 0.15);
                        position: absolute;
                        width: 1px;
                        height: 8px;
                        top: -10px;
                        left: 14px;
                        display: block;
                    }
                }

                .number {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    background-color: rgba(0, 164, 41, 0.15);
                    @include text(14px, 500, #32BF61, 16.8px);
                }

                .text {
                    @include text(14px, 400, var(--brown1), 17.2px);

                    @media (min-width: 375px) {
                        @include text(16px, 400, var(--brown1), 19.2px);
                    }
                }
            }
        }
    }
}

</style>
