import {Ref, ref, ToRef} from "vue";
import {BuyingOperationStatus} from "@enums/BuyingOperationStatus";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export default class PaymentTimerService {
    private _time: number = -1;
    private _staticTime: number = -1;
    private _timeText: Ref<string | null> = ref(null);
    private _percent: Ref<number> = ref(-1);
    private _interval: number = -1;
    private _callbackInterval: number = -1;
    private _callback: Function | null = null;
    private _callbackTime: number = -1;
    private _callbackPeriod: number = 15000;
    private _timeSet: Map<string, number> = new Map<string, number>();
    private _ready: ToRef<boolean> = ref(false);

    constructor() {
        this.updateStatusLiveTimerValues();
    }

    get ready(): ToRef<boolean> {
        return this._ready;
    }

    set ready(value: ToRef<boolean>) {
        this._ready = value;
    }

    public updateStatusLiveTimerValues(timeSet: Map<string, number> | null = null) {
        if (timeSet && timeSet.size === 4) {
            this._timeSet.clear();
            this._timeSet = timeSet;
            return;
        }
        this._timeSet.set("Empty".toUpperCase(), 3600000);
        this._timeSet.set("Created".toUpperCase(), 180000);
        this._timeSet.set("Accepted".toUpperCase(), 600000);
        this._timeSet.set("Cashed".toUpperCase(), 60000);
    }

    public setCallback(func: Function) {
        this._callback = func;
    }

    public start(status: BuyingOperationStatus, updatedAt: string) {
        let dayjsUpdatedAt = dayjs(updatedAt).utc();
        const staticSeconds = this._timeSet.get(status);
        if (typeof staticSeconds === "undefined") {
            this.resetData();
            return;
        }

        dayjsUpdatedAt = dayjsUpdatedAt.add(staticSeconds, "millisecond");
        const seconds = dayjsUpdatedAt.diff(dayjs().utc(), "millisecond");

        if (typeof seconds === "undefined" || seconds < 0) {
            this.resetData();
            return;
        }
        this._time = seconds;
        this._staticTime = seconds;
        this._percent.value = 1;
        this._timeText.value = this.constructTimeText(this._time);
        this.startInterval();

        this._callbackTime = seconds;
        this.startCallbackInterval();

        if (!this._ready.value) this._ready.value = true;
    }

    private dataToZero() {
        this._time = 0;
        this._percent.value = 0;
        this._timeText.value = this.constructTimeText(this._time);
    }

    private resetData() {
        this._time = -1;
        this._staticTime = -1;
        this._timeText.value = null;
        this._percent.value = -1;
        this._interval = -1;
        this._callback = null;
    }

    private startCallbackInterval() {
        clearInterval(this._callbackInterval);
        this._callbackInterval = setInterval(() => {
            this._callbackTime -= 1000;

            if (this._callback) {
                this._callback();
            }

            if (this._callbackTime < 0) {
                clearInterval(this._callbackInterval);
            }
        }, this._callbackPeriod);
    }

    private startInterval() {
        clearInterval(this._interval);
        this._interval = setInterval(() => {
            this._time -= 1000;
            if (this._time < 0) {
                clearInterval(this._interval);
                this._percent.value = -1;
            } else {
                this._timeText.value = this.constructTimeText(this._time);
            }
            this.calculatePercent();
        }, 1000);
    }

    public stopIntervals() {
        clearInterval(this._interval);
        clearInterval(this._callbackInterval);
        this.resetData();
    }

    private calculatePercent() {
        const less = +(1 / Math.round(this._staticTime / 1000)).toFixed(5);
        this._percent.value = +(this._percent.value - less).toFixed(5);
        if (this._percent.value < 0) this._percent.value = 0;
    }

    private constructTimeText(time: number): string {
        let ms = time / 1000;
        let seconds = Math.floor(ms % 60);
        let minutes = Math.floor(ms / 60 % 60);
        let hours = Math.floor(ms / (60 * 60) % 24);

        const secondsText = seconds < 10 ? `0${seconds}` : seconds;
        const minutesText = minutes < 10 ? `0${minutes}` : minutes;
        const hoursText = hours < 10 ? `0${hours}` : hours;

        if (hours <= 0) {
            return `${minutesText}:${secondsText}`;
        }
        return `${hoursText}:${minutesText}:${secondsText}`;
    }

    get getTimeText(): Ref<string | null> {
        return this._timeText;
    }

    get getPercent(): Ref<number> {
        return this._percent;
    }
}
