import ViewController from "@/interfaces/ViewController";
import {computed, Ref, ref, ToRef} from "vue";
// @ts-ignore
import AppController from '@target/components/App/ts/AppController';
import GlobalRequisitesController from "@/views/requisites-group/GlobalRequisitesController";
import {autobind} from "@/decorators/autobind";
import WithdrawalService, {createWithdrawalPayload} from "@/services/operations/withdrawal/WithdrawalService";
import Requisite from "@models/requisite/Requisite";
import MainApiService from "@/services/api-service/MainApiService";
import AgentsAmountRanges from "@models/errors/components/AgentsAmountRanges";
import ServerResponseError from "@/errors/ServerResponseError";
import ModelRates from "@models/v2/rates/ModelRates";
import ServiceRates from "@/services/v2/data/service-rates/ServiceRates";
import { FirebaseService } from "@/services/firebase/FirebaseService";
import { DocumentTypes } from "@/services/firebase/firestore/documents/DocumentTypes";
import DocumentWithdrawal from "@/services/firebase/firestore/documents/DocumentWithdrawal";
import ServiceAccount from "@/services/v2/data/service-account/ServiceAccount";

export default class WithdrawalPopupController implements ViewController {
    private static instance?: WithdrawalPopupController;
    public amount: ToRef<string>;
    public inputCurrency: ToRef<string>;
    private _amountErrors: ToRef<any> = ref({
        min: false,
        max: false,
        balance: false,
    });
    private rates: Ref<ModelRates>;

    private constructor() {
        const currencySign = ref("$");
        AppController.getInstance().setPaymentRequestData(null);

        const requisitePs = GlobalRequisitesController.getInstance();
        const doc = FirebaseService.of().firestoreService?.getDocument<DocumentWithdrawal>(DocumentTypes.WITHDRAWAL);

        let amount = 5;

        if (doc && doc.defaultAmount) {
            amount = doc.defaultAmount;
        }

        this.rates = ServiceRates.of().rates as Ref<ModelRates>;

        if (requisitePs.activeRequisiteAmountRange.value) {
            const {min, max} = requisitePs.activeRequisiteAmountRange.value!;

            if (max !== 0) {

                amount = +(min * this.rates.value.sell).toFixed(0);
            }
        }

        this.amount = ref(`${amount} ${currencySign.value}`);
        this.inputCurrency = ref("RUB");
    }

    public extractAmount() {
        if (this.inputCurrency.value === "USDT") {
            return +(
                (Math.ceil(
                    parseInt(this.amount.value.replaceAll(/\s+/g, '')
                    )) / this.rates.value.sell)
            ).toFixed(2);
        } else {
            return parseFloat(this.amount.value.replaceAll(/\s+/g, ''))
        }
    }

    get amountErrors(): ToRef<any> {
        return this._amountErrors;
    }

    set amountErrors(value: ToRef<any>) {
        this._amountErrors = value;
    }

    @autobind
    public async create2P2Withdrawal() {
        const requisitePs = GlobalRequisitesController.getInstance();
        const {min, max} = requisitePs.activeRequisiteAmountRange.value!;

        const payload: createWithdrawalPayload = {
            requisite_id: requisitePs.activeRequisite.value!.id,
            amount: -1,
        };
        if (this.inputCurrency.value === "USDT") {
            payload.amount = +(
                (Math.ceil(
                    parseInt(this.amount.value.replaceAll(/\s+/g, '')
                    )) / this.rates.value.sell)
            ).toFixed(2);
        } else {
            payload.amount = parseFloat(this.amount.value.replaceAll(/\s+/g, ''))
        }

        if (max === 0) {
            throw new ServerResponseError("Something went wrong", 70401);
        }

        // const account = computed((): Account => store.getters.account.value);

        // if (payload.amount > account.value.balance!.amount) {
        //     throw Error(`${i18n.global.t("g.errors.newPayment.no-enough")}`);
        // }
        //
        // if (payload.amount < min) {
        //     throw Error(`${i18n.global.t("g.errors.newPayment.minAmount")} ${min}$`);
        // }
        // if (payload.amount > max) {
        //     throw Error(`${i18n.global.t("g.errors.newPayment.maxAmount")} ${max}$`);
        // }

        const withdrawalService: WithdrawalService = AppController.getInstance().withdrawalService;
        AppController.getInstance().setPaymentRequestData({...payload, type: 'sail'});

        await withdrawalService.createWithdrawal(payload);
    }

    static getInstance() {
        if (typeof this.instance === 'undefined') this.instance = new WithdrawalPopupController();
        return this.instance;
    }

    //TODO not used
    public async fetchRequisiteMinMax(requisite: Requisite) {
        const response = await MainApiService.getInstance().get("/api/mobile/client/withdrawals/amount_range", {requisite_id: requisite.id});
        return response.data as AgentsAmountRanges;
    }

    //TODO not used
    public updateAmount() {
        const currencySign = ServiceAccount.of().accountCurrencyAbbr;
        const requisitePs = GlobalRequisitesController.getInstance();
        let amount = 100;

        if (requisitePs.activeRequisiteAmountRange.value) {
            const {min, max} = requisitePs.activeRequisiteAmountRange.value!;

            if (max !== 0) {
                amount = +(Math.ceil(min * this.rates.value.sell)).toFixed(0);
            }
        }

        this.amount.value = `${amount} ${currencySign.value}`;
    }

    destructor(): void {
        WithdrawalPopupController.instance = undefined;
    }
}
