<template>
    <div :class="
        [
            'operation-status',
            operation.status ? operation.status.toLocaleLowerCase() : ''
        ]
    ">
        {{ humanPaymentStatus }}
    </div>
</template>

<script setup lang="ts">
import Helper from "@/helpers/helper";
import { computed } from "vue";
import { Operation } from "@/repositories/v2/repository-operations/types";

interface Props {
    operation: Operation
}

const props = defineProps<Props>();

const humanPaymentStatus = computed(() => Helper.getInstance().convertToHumanPaymentStatus(props.operation));
</script>

<style lang="scss">
.operation-status {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    background-color: #E6F2FF;
    color: var(--blue1);
    border-radius: 100px;
    padding: 3px 8px;

    &.completed {
        background-color: #eaf9f0;
        color: #34C759;
    }

    &.confirmed {
        background-color: #eaf9f0;
        color: #32BF61;
    }

    &.onappeal {
        color: #CF7529;
        background-color: #FAEACC;
    }

    &.activated {
        background-color: #eaf9f0;
        color: #32BF61;
    }

    &.canceled {
        background-color: #f3f4f5;
        color: var(--grey2);
    }

    &.rejected {
        color: #FF3B30;
        background-color: #ffebeb;
    }

    &.disputed {
        background-color: #E6F2FF;
    }

    &.declined {
        color: #FF3B30;
        background-color: #ffebeb;
    }

    &.expired {
        background-color: var(--pink1);
        color: var(--red1);
    }
}
</style>
