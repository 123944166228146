/**
 * Deprecated (27.01.2024)
 * This is an outdated enum, use @models/operation/OperationType.ts
 */
export enum OperationType_N {
    sale = "sale",
    purchase = "purchase",
    income = "income",
    clientTransfer = "client_transfer"
}
