import {Expose} from "class-transformer";
import Requisite from "@models/requisite/Requisite";

export default class WithdrawalWalletOperationPayData {
    @Expose({name: "requisites"})
    private _requisites: Requisite;

    @Expose({name: "checkupUrl"})
    private _checkupUrl: string;

    constructor(
        requisites: Requisite,
        checkupUrl: string
    ) {
        this._requisites = requisites;
        this._checkupUrl = checkupUrl;
    }

    get requisites(): Requisite {
        return this._requisites;
    }

    set requisites(value: Requisite) {
        this._requisites = value;
    }

    get checkupUrl(): string {
        return this._checkupUrl;
    }

    set checkupUrl(value: string) {
        this._checkupUrl = value;
    }
}
