<template>
    <f7-sheet
        class="balance-hint-sheet"
        swipe-to-close
        backdrop
        ref="self"
        @sheet:closed="sheetEventClosed"
    >
        <span class="bdg"></span>
        <div class="content">
            <SlothBlock
                :type="SLOTH_TYPE.QUESTION"
            />
            <p class="balance-hint-sheet__title">{{ $t('sheets.balance-hint.title') }}</p>
            <p class="balance-hint-sheet__text">{{ $t('sheets.balance-hint.text1') }}</p>
        </div>
    </f7-sheet>
</template>

<script lang="ts" setup>
import SlothBlock from "@/components/messages/SlothBlock.vue";
import {SLOTH_TYPE} from "@/entities/enums/SlothType";
import {onMounted, ref} from "vue";
import {f7} from "framework7-vue";

const self = ref(null);
const emits = defineEmits(['closed']);
onMounted(() => {
    const selfEl = (self.value as any).$el;
    // @ts-ignore
    const sheet = f7.sheet.create({ el: selfEl });
    sheet.open();
});
const sheetEventClosed = () => {
    emits('closed');
}
</script>

<style lang="scss">
.balance-hint-sheet {
    --f7-sheet-bg-color: #fff;
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;
    height: auto;
    //height: calc(var(--f7-safe-area-bottom) + 590px) !important;

    .bdg {
        width: 36px;
        height: 4px;
        position: absolute;
        top: 6px;
        z-index: 9999;
        background: #818E95;
        border-radius: 4px;
        left: 50%;
        transform: translateX(-50%);
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 16px calc(var(--f7-safe-area-bottom) + 40px);
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;

        .info-block + .balance-hint-sheet__title {
            margin-top: 16px;
        }
    }
    &__title {
        @include text(24px, 700, var(--brown1), 24px);
        width: 100%;
        & + .balance-hint-sheet__text {
            margin-top: 12px;
        }
    }
    &__text {
        width: 100%;
        @include text(14px, 400, var(--brown1), 16.8px);

        & + .balance-hint-sheet__text {
            margin-top: 8px;
        }
    }
}
</style>
