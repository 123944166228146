import { computed, ComputedRef } from "vue";

export default class ServiceTelegram {
    private static _instance: ServiceTelegram | null = null;
    //@ts-ignore
    private readonly _tg: any = window.Telegram;
    private readonly _isTelegram: ComputedRef<boolean> = computed(() => !!this._tg?.WebApp?.initData);

    static of() {
        if (ServiceTelegram._instance === null) {
            ServiceTelegram._instance = new ServiceTelegram();
        }

        return ServiceTelegram._instance;
    }

    public init() {
        if (this._isTelegram.value) {
            this.setTelegramCssClassToHtml();
            this.forceScrollTop();
            this.preventPinchZoom();

            this._tg.WebApp.expand();
            this._tg.WebApp.disableVerticalSwipes();
            this._tg.WebApp.ready();
        }
    }

    private setTelegramCssClassToHtml() {
        const root = document.documentElement;
        root.classList.add('telegram');
    }

    private forceScrollTop() {
        window.addEventListener('scroll', (e) => {
            if (window.scrollY > 0) {
                window.scrollTo({ top: 0, left: 0 });
            }
        });
    }

    private preventPinchZoom() {
        window.addEventListener(
            'touchmove',
            function (e) {
                if (e.touches.length > 1) {
                    // Если два пальца — предотвращаем масштабирование
                    e.preventDefault();
                }
            },
            { passive: false }
        );
    }

    public get isTelegram(): ComputedRef<boolean> {
        return this._isTelegram;
    }
}