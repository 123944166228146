import { FirebaseService } from "@/services/firebase/FirebaseService";
import kodmobiApiService from "@/targets/main/services/project/kodmobiApiService";
import ProjectApiService from "@/targets/main/services/project/projectApiService";
import ServiceMainApi from "@/services/v2/service-main-api/ServiceMainApi";
import {f7} from "framework7-vue";
import ServiceReleazio from "@/services/v2/service-releazio/ServiceReleazio";
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import {DocumentTypes} from "@/services/firebase/firestore/documents/DocumentTypes";
import DocumentPurchase from "@/services/firebase/firestore/documents/DocumentPurchase";
import i18n from "@/langs/i18n"

export default class ViewTechController {
    private static instance?: ViewTechController;
    private techCount: number = 0;
    private token: string | null = null;
    private rt: string | null = null;
    private csp: string | null = null;
    private userAgent: string | null = null;
    private version: string | null = null;
    private isFirebaseInitialized: string | null = null;
    private kodmobiUrl: String | null = null;
    private projectUrl: String | null = null;
    private mainUrl: string | null = null;
    private releazio: string | null = null;
    private isCanSelectAgent: string | null = null;
    private fcmToken: string | null = null;

    public readSettings() {
        const serviceReleazio: ServiceReleazio | null = AppController.of().service<ServiceReleazio>("releazio");

        this.token = localStorage.getItem("ltoken");
        this.rt = localStorage.getItem("lrefreshToken");
        this.csp = localStorage.getItem("csp");
        this.userAgent = window.navigator.userAgent;
        this.version = import.meta.env.VITE_VERSION;
        // @ts-ignore
        this.isFirebaseInitialized = FirebaseService.of().isInitialized() ? i18n.global.t("g.tech.settings.connected") : i18n.global.t("g.tech.settings.disconnected");
        this.kodmobiUrl = kodmobiApiService.getInstance().url;
        this.projectUrl = ProjectApiService.of().getConfigDomain()
        this.mainUrl = ServiceMainApi.of().getConfigDomain();
        this.releazio = serviceReleazio?.initialized ? i18n.global.t("g.tech.settings.connected") : i18n.global.t("g.tech.settings.disconnected");
        this.isCanSelectAgent = this.fetchIsCanSelectAgent() ? i18n.global.t("g.tech.settings.enabled") : i18n.global.t("g.tech.settings.disabled");
        this.fcmToken = localStorage.getItem("fcm-token");

        return this;
    }

    public getSettings() {
        return {
            token: this.token,
            rt: this.rt,
            csp: this.csp,
            userAgent: this.userAgent,
            version: this.version,
            isFirebaseInitialized: this.isFirebaseInitialized,
            kodmobiUrl: this.kodmobiUrl,
            projectUrl: this.projectUrl,
            mainUrl: this.mainUrl,
            releazio: this.releazio,
            isCanSelectAgent: this.isCanSelectAgent,
            fcmToken: this.fcmToken
        }
    }

    private fetchIsCanSelectAgent() {
        const doc = FirebaseService.of().firestoreService?.getDocument(DocumentTypes.PURCHASE);
        if (!doc || !(doc as DocumentPurchase).isCanSelectAgent) return false;
        return (doc as DocumentPurchase).isCanSelectAgent!;
    }

    public static of() {
        if (typeof ViewTechController.instance === 'undefined') ViewTechController.instance = new ViewTechController();

        return ViewTechController.instance;
    }

    public openTechPage() {
        this.techCount++;

        if (this.techCount === 6) {
            f7.dialog.prompt("", "Password", (password: string) => {
                if (password === '666') {
                    f7.view.current.router.navigate('/settings/tech');
                }
            })
            this.techCount = 0;
        }
    }
}
