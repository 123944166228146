import humps from "lodash-humps-ts";
import {f7} from "framework7-vue";
import initPusher from "@/services/pusher";
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import ChatDataController from "@/controllers/ChatDataController";
import {plainToInstance} from "class-transformer";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import {validateOrReject} from "class-validator";
import PaymentService from "@/services/operations/payment/PaymentService";
import WithdrawalOperation from "@models/operations/WithdrawalOperation";
import WithdrawalService from "@/services/operations/withdrawal/WithdrawalService";
import WithdrawalWalletPageController from "@/views/withdrawal-group/wallet/wallet/WithdrawalWalletPageController";
import * as Sentry from "@sentry/vue";
import ModelAccountBalance from "@models/v2/account/ModelAccountBalance";
import ServiceAccount from "@/services/v2/data/service-account/ServiceAccount";
import ServiceOperations from "@/services/v2/data/service-operations/ServiceOperations";
import ServiceChat from "@/services/v2/data/service-chat/ServiceChat";

export default class SocketService {
    private static instance: SocketService | null = null;
    private accountID: number = -1;
    private connected: boolean = false;
    private _gate: any = null;

    get gate() {
        return this._gate;
    }

    public static getInstance() {
        if (this.instance === null) this.instance = new SocketService()
        return this.instance
    }

    public init(accountID: number, pusher: any) {
        this.accountID = accountID;
        initPusher(pusher);
        this._gate = window.Echo.private(`client.app.${this.accountID}`);
        this.bindConnectionStates();

        ChatDataController.getInstance().socketConnect();
        return this;
    }

    public connect() {
        try {
            if (this.connected) return;
            this.checkLiveData();
            const paymentService: PaymentService = AppController.getInstance().paymentService;
            const withdrawalService: WithdrawalService = AppController.getInstance().withdrawalService;

            this._gate
                .listen('.TransferPending', async (data: any) => {})
                .listen('.TransferFailed', async (data: any) => {
                    switch (f7.views.current.router.currentRoute.path) {
                        case '/payments':
                            await ServiceOperations.of().fetchOperations({ refresh: true });
                            break;
                        case '/withdrawal-wallet':
                            await ServiceOperations.of().fetchOperations({ refresh: true });
                            const operation = humps(data.payment);
                            if (operation.id === WithdrawalWalletPageController.of().withdrawal.value!.id) {
                                WithdrawalWalletPageController.of().withdrawal.value = humps(data.payment);
                            }
                            break;
                    }
                })
                .listen('.TransferCanceled', async (data: any) => {
                    switch (f7.views.current.router.currentRoute.path) {
                        case '/payments':
                            await ServiceOperations.of().fetchOperations({ refresh: true });
                            break;
                        case '/withdrawal-wallet':
                            await ServiceOperations.of().fetchOperations({ refresh: true });
                            const operation = humps(data.payment);
                            if (operation.id === WithdrawalWalletPageController.of().withdrawal.value!.id) {
                                WithdrawalWalletPageController.of().withdrawal.value = humps(data.payment);
                            }
                            break;
                    }
                })
                .listen('.TransferCompleted', async (data: any) => {
                    switch (f7.views.current.router.currentRoute.path) {
                        case '/payments':
                            await ServiceOperations.of().fetchOperations({ refresh: true });
                            break;
                        case '/withdrawal-wallet':
                            await ServiceOperations.of().fetchOperations({ refresh: true });
                            const operation = humps(data.payment);
                            if (operation.id === WithdrawalWalletPageController.of().withdrawal.value!.id) {
                                WithdrawalWalletPageController.of().withdrawal.value = humps(data.payment);
                            }
                            break;
                    }
                })
                .listen('.BalanceUpdated', async (data: any) => {
                    const modelAccountBalance: ModelAccountBalance = plainToInstance(ModelAccountBalance, humps(data.balance) as object, {
                        excludeExtraneousValues: true,
                        excludePrefixes: ['_']
                    });

                    await validateOrReject(modelAccountBalance);
                    ServiceAccount.of().account.value!.balance = modelAccountBalance;
                })
                .listen('.SaleCanceled', async (data: any) => {
                    switch (f7.views.current.router.currentRoute.path) {
                        case '/chat-withdrawal':
                        case '/withdrawal':
                            const localWithdrawal: WithdrawalOperation | null = withdrawalService.withdrawal.value;
                            const serverWithdrawal: WithdrawalOperation = plainToInstance(WithdrawalOperation, humps(data.payment), {
                                excludeExtraneousValues: true,
                                excludePrefixes: ['_']
                            });
                            await validateOrReject(serverWithdrawal);
                            if (localWithdrawal?.id === serverWithdrawal.id) {
                                withdrawalService.withdrawal.value = serverWithdrawal;
                            }
                            break;
                        case '/payments':
                            await ServiceOperations.of().fetchOperations({ refresh: true });
                            break;
                    }
                })
                .listen('.SaleAccepted', async (data: any) => {
                    switch (f7.views.current.router.currentRoute.path) {
                        case '/withdrawal':
                        case '/chat-withdrawal':
                            const localWithdrawal: WithdrawalOperation | null = withdrawalService.withdrawal.value;
                            const serverWithdrawal: WithdrawalOperation = plainToInstance(WithdrawalOperation, humps(data.payment), {
                                excludeExtraneousValues: true,
                                excludePrefixes: ['_']
                            });
                            await validateOrReject(serverWithdrawal);
                            if (localWithdrawal?.id === serverWithdrawal.id) {
                                withdrawalService.withdrawal.value = serverWithdrawal;
                            }
                            break;
                        case '/payments':
                            await ServiceOperations.of().fetchOperations({ refresh: true });
                            break;
                    }
                })
                .listen('.SaleCashed', async (data: any) => {
                    switch (f7.views.current.router.currentRoute.path) {
                        case '/chat-withdrawal':
                        case '/withdrawal':
                            const localWithdrawal: WithdrawalOperation | null = withdrawalService.withdrawal.value;
                            const serverWithdrawal: WithdrawalOperation = plainToInstance(WithdrawalOperation, humps(data.payment), {
                                excludeExtraneousValues: true,
                                excludePrefixes: ['_']
                            });
                            await validateOrReject(serverWithdrawal);
                            if (localWithdrawal?.id === serverWithdrawal.id) {
                                withdrawalService.withdrawal.value = serverWithdrawal;
                            }
                            break;
                        case '/payments':
                            await ServiceOperations.of().fetchOperations({ refresh: true });
                            break;
                    }
                })
                .listen('.PaymentAccepted', async (data: { payment: object }) => {
                    switch (f7.views.current.router.currentRoute.path) {
                        case '/payment/light':
                        case '/payment':
                            const localPayment: PurchaseOperation | null = paymentService.payment.value;
                            const serverPayment: PurchaseOperation = plainToInstance(PurchaseOperation, humps(data.payment), {
                                excludeExtraneousValues: true,
                                excludePrefixes: ['_']
                            });
                            await validateOrReject(serverPayment);
                            if (localPayment?.id === serverPayment.id) {
                                paymentService.payment.value = serverPayment;
                            }
                            break;
                        case '/payments':
                            await ServiceAccount.of().fetchAccount();
                            await ServiceOperations.of().fetchOperations({ refresh: true });
                            break;
                    }
                })
                .listen('.PaymentCanceled', async (data: { payment: object }) => {
                    switch (f7.views.current.router.currentRoute.path) {
                        case '/payment/light':
                        case '/payment':
                            const localPayment: PurchaseOperation | null = paymentService.payment.value;
                            const serverPayment: PurchaseOperation = plainToInstance(PurchaseOperation, humps(data.payment), {
                                excludeExtraneousValues: true,
                                excludePrefixes: ['_']
                            });
                            await validateOrReject(serverPayment);
                            if (localPayment?.id === serverPayment.id) {
                                paymentService.payment.value = serverPayment;
                            }
                            break;
                        case '/payments':
                            await ServiceAccount.of().fetchAccount();
                            await ServiceOperations.of().fetchOperations({ refresh: true });
                            break;
                    }
                })
                .listen('.PaymentExpired', async (data: { payment: object }) => {
                    switch (f7.views.current.router.currentRoute.path) {
                        case '/payments':
                            await ServiceAccount.of().fetchAccount();
                            await ServiceOperations.of().fetchOperations({ refresh: true });
                            break;
                        case '/payment/light':
                        case '/payment':
                            const localPayment: PurchaseOperation | null = paymentService.payment.value;
                            const serverPayment: PurchaseOperation = plainToInstance(PurchaseOperation, humps(data.payment), {
                                excludeExtraneousValues: true,
                                excludePrefixes: ['_']
                            });
                            await validateOrReject(serverPayment);
                            if (localPayment?.id === serverPayment.id) {
                                paymentService.payment.value = serverPayment;
                            }
                            break;
                    }
                    await ServiceAccount.of().fetchAccount();
                    await ServiceOperations.of().fetchOperations({ refresh: true });
                })
                .listen('.PaymentConfirmed', async (data: { payment: object }) => {
                    switch (f7.views.current.router.currentRoute.path) {
                        case '/chat':
                        case '/payment/light':
                        case '/payment':
                            const localPayment: PurchaseOperation | null = paymentService.payment.value;
                            const serverPayment: PurchaseOperation = plainToInstance(PurchaseOperation, humps(data.payment), {
                                excludeExtraneousValues: true,
                                excludePrefixes: ['_']
                            });
                            await validateOrReject(serverPayment);
                            if (localPayment?.id === serverPayment.id) {
                                paymentService.payment.value = serverPayment;
                            }
                            break;
                        case '/payments':
                            await ServiceAccount.of().fetchAccount();
                            await ServiceOperations.of().fetchOperations({ refresh: true });
                            break;
                    }
                })
                .listen('.PaymentRejected', async (data: { payment: object }) => {
                    switch (f7.views.current.router.currentRoute.path) {
                        case '/chat':
                        case '/payment/light':
                        case '/payment':
                            const localPayment: PurchaseOperation | null = paymentService.payment.value;
                            const serverPayment: PurchaseOperation = plainToInstance(PurchaseOperation, humps(data.payment), {
                                excludeExtraneousValues: true,
                                excludePrefixes: ['_']
                            });
                            await validateOrReject(serverPayment);
                            if (localPayment?.id === serverPayment.id) {
                                paymentService.payment.value = serverPayment;
                            }
                            break;
                        case '/payments':
                            await ServiceAccount.of().fetchAccount();
                            await ServiceOperations.of().fetchOperations({ refresh: true });
                            break;
                    }
                })
                .listen('.PaymentDeclined', async (data: { payment: object }) => {
                    switch (f7.views.current.router.currentRoute.path) {
                        case '/chat':
                        case '/payment/light':
                        case '/payment':
                            const localPayment: PurchaseOperation | null = paymentService.payment.value;
                            const serverPayment: PurchaseOperation = plainToInstance(PurchaseOperation, humps(data.payment), {
                                excludeExtraneousValues: true,
                                excludePrefixes: ['_']
                            });
                            await validateOrReject(serverPayment);
                            if (localPayment?.id === serverPayment.id) {
                                paymentService.payment.value = serverPayment;
                            }
                            break;
                        case '/payments':
                            await ServiceAccount.of().fetchAccount();
                            await ServiceOperations.of().fetchOperations({ refresh: true });
                            break;
                    }
                })
            this.connected = true;
        } catch (e: any) {
            console.info(e)
        }
    }

    public disconnect() {
        try {
            this.checkLiveData();
            this.gate.stopListeningToAll();
            this.connected = false;
        } catch (e: any) {
        }
    }

    private checkLiveData() {
        if (this.accountID === -1) throw new Error("SocketService needs id of account");
        if (window.Echo == undefined) throw new Error("Echo isn't defined");
        if (window.Pusher == undefined) throw new Error("Pusher isn't defined");
    }

    private bindConnectionStates() {
        try {
            this.checkLiveData();
            const self = this;
            window.Echo.connector.pusher.connection.bind('state_change', async function (states: any) {
                const appController = AppController.getInstance();

                if (states.previous === 'connected' || states.current === 'connected') {
                    appController.socketIsConnecting.value = true;
                    await self.updateChatView();
                    await self.updateView();

                    setTimeout(() => {
                        appController.socketIsConnecting.value = false;
                    }, 2000);
                }
            });
        } catch (e) {
            console.info(e)
        }
    }

    async updateView() {
        try {
            switch (f7.views.current.router.currentRoute.url) {
                case "/popup/new-payment":
                    await ServiceAccount.of().fetchAccount();
                    await AppController.of().paymentService.checkPaymentTransactionStatus();
                    break;
                case "/popup/withdrawal/create":
                    await ServiceAccount.of().fetchAccount();
                    await AppController.of().withdrawalService.checkWithdrawalTransactionStatus();
                    break;
                case "/payments":
                    await ServiceAccount.of().fetchAccount();
                    await ServiceOperations.of().fetchOperations({ refresh: true });
                    break;
                case "/payment":
                    await ServiceAccount.of().fetchAccount();
                    await AppController.of().paymentService.checkPaymentTransactionStatus();
                    break;
                case "/withdrawal":
                    await ServiceAccount.of().fetchAccount();
                    await AppController.of().withdrawalService.checkWithdrawalTransactionStatus();
                    break;
            }
            AppController.getInstance().socketIsConnecting.value = false;
        } catch (e: any) {
            Sentry.captureMessage("UpdateView error: " + e.message);
            Sentry.captureException(e);
        }
    }

    async updateChatView() {
        try {
            const chatRoutes = ["/chat", "/chat-withdrawal"];
            if (chatRoutes.includes(f7.views.current.router.currentRoute.url)) {
                await ServiceChat.of().updateCurrentChat();
            }
        } catch (e: any) {
            Sentry.captureMessage("UpdateChatView error: " + e.message);
            Sentry.captureException(e);
        }
    }
}
