<template>
    <f7-popup @popup:open="popupOpen" ref="self" class="payment-details-popup" swipe-to-close="to-bottom"
              v-model:opened="isOpened">
        <span class="tint"></span>
        <f7-page no-navbar :page-content="false">
            <f7-page-content>
                <div class="wrapper-elements" v-if="payment">
                    <p class="payment-details-popup__title">{{ $t('sheets.payment-details.title') }}</p>
                    <PaymentCard :payment="payment!" />
                    <PaymentRequisiteComment key="1" :comment="payment.requisites!.comment"/>
                    <PaymentMeta :payment="payment"/>
                </div>
            </f7-page-content>
        </f7-page>
    </f7-popup>
</template>

<script setup lang="ts">
import {ref} from "vue";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import {ToRef} from "@vue/runtime-core";
import PaymentCard from "@/views/payment/components/atomic/payment-card/PaymentCard.vue";
import PaymentRequisiteComment from "@/views/payment/components/atomic/PaymentRequisiteComment.vue";
import PaymentMeta from "@/views/payment/components/atomic/PaymentMeta.vue";

const isOpened = ref(false);
const payment: ToRef<PurchaseOperation | null> = ref(null);
const self = ref(null);
defineExpose({isOpened, payment, detectHeight});

function popupOpen() {
    setTimeout(() => {
        detectHeight();
    }, 100);
}

function detectHeight() {
    const mainEl = (self.value! as any);
    const style = getComputedStyle(document.body);
    const contentHeight = mainEl.$el.querySelector('.wrapper-elements').offsetHeight;
    mainEl.$el.style.height = contentHeight + parseInt(style.getPropertyValue('--f7-safe-area-bottom')) + 17 + 'px';
}
</script>

<style lang="scss">
.payment-details-popup {
    --f7-page-content-extra-padding-top: 16px;
    --f7-page-bg-color: #FFF;
    bottom: 0 !important;
    top: unset !important;
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;
    padding-bottom: calc(0px + var(--f7-safe-area-bottom)) !important;
    overflow: hidden;
    .wrapper-elements {
        padding: 24px 16px;
    }

    &__title {
        margin-bottom: 16px;
        @include text(16px, 700, var(--brown1), 19.2px);
    }

    .tint {
        width: 36px;
        height: 4px;
        position: absolute;
        top: 6px;
        z-index: 9999;
        background: var(--grey4);
        border-radius: 4px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }
}
</style>
