import {Expose} from "class-transformer";

export default class PaymentFeedbackCancelOptionParamsInput {
    @Expose({name: 'enabled'})
    private _enabled: boolean | null = null;

    @Expose({name: 'allowedTypes'})
    private _allowedTypes: string[] = [];

    get enabled(): boolean | null {
        return this._enabled;
    }

    get allowedTypes(): any[] {
        return this._allowedTypes;
    }
}