<template>
    <div class="payment__status created">
        <slot name="timer"></slot>
        <PaymentStatusText
            :title="''"
            :text="$t('light-payment.status.created.text')"
        />
        <span v-if="cancelState" class="cancel" @click="cancelPayment">{{ $t('light-payment.status.created.cancel') }}</span>
    </div>
</template>

<script setup lang="ts">
import PaymentStatusText from "../atomic/PaymentStatusText.vue";
import {PropType, toRef} from "vue";

const props = defineProps({
    cancel: {
        type: [Boolean] as PropType<Boolean>,
        default: false
    },
});
const emits = defineEmits(['cancelPayment']);
const cancelState = toRef(props, 'cancel');

function cancelPayment() {
    emits('cancelPayment');
}

</script>

<style src="./index.scss"></style>
<style lang="scss">
.payment__status {
    &.created {
        display: flex;
        align-items: center;
        margin-top: 56px;
        flex-direction: column;

        .light-buying__status-timer {
            height: 100px;
        }

        .status-image {
            width: 100px;
            height: 100px;
            background-image: url("data:image/svg+xml,%3Csvg width='101' height='100' viewBox='0 0 101 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_4372_198520)'%3E%3Cpath d='M50.3584 49.0781L57.8428 41.7188C59.3115 40.2656 61.6865 40.2656 63.0147 41.7188C64.6084 43.1875 64.6084 45.5625 63.0147 46.8906L55.7959 54.2344L63.0147 61.7188C64.6084 63.1875 64.6084 65.5625 63.0147 66.8906C61.6865 68.4844 59.3115 68.4844 57.8428 66.8906L50.3584 59.6719L43.0146 66.8906C41.6865 68.4844 39.3115 68.4844 37.8428 66.8906C36.3896 65.5625 36.3896 63.1875 37.8428 61.7188L45.2021 54.2344L37.8428 46.8906C36.3896 45.5625 36.3896 43.1875 37.8428 41.7188C39.3115 40.2656 41.6865 40.2656 43.0146 41.7188L50.3584 49.0781Z' fill='%23818E95'/%3E%3Cpath opacity='0.4' d='M23 84.377C10.5734 84.377 0.5 74.2988 0.5 61.877C0.5 52.0645 6.77656 43.7207 15.5312 40.6426C15.5109 40.2207 15.5 39.7988 15.5 39.377C15.5 25.5645 26.6875 14.377 40.5 14.377C49.7656 14.377 57.8438 19.416 62.1719 26.9082C64.5469 25.1738 67.4219 24.377 70.5 24.377C78.7812 24.377 85.5 30.9551 85.5 39.377C85.5 41.2832 85.1406 43.0957 84.5 44.7832C93.625 46.627 100.5 54.7051 100.5 64.377C100.5 75.4238 91.5469 84.377 80.5 84.377H23ZM37.8438 46.8926L45.2031 54.2363L37.8438 61.7207C36.3906 63.1895 36.3906 65.5645 37.8438 66.8926C39.3125 68.4863 41.6875 68.4863 43.0156 66.8926L50.3594 59.6738L57.8438 66.8926C59.3125 68.4863 61.6875 68.4863 63.0156 66.8926C64.6094 65.5645 64.6094 63.1895 63.0156 61.7207L55.7969 54.2363L63.0156 46.8926C64.6094 45.5645 64.6094 43.1895 63.0156 41.7207C61.6875 40.2676 59.3125 40.2676 57.8438 41.7207L50.3594 49.0801L43.0156 41.7207C41.6875 40.2676 39.3125 40.2676 37.8438 41.7207C36.3906 43.1895 36.3906 45.5645 37.8438 46.8926Z' fill='%23818E95'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_4372_198520'%3E%3Crect width='100' height='100' fill='white' transform='translate(0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        }

        .cancel {
            cursor: pointer;
            margin-top: 24px;
            font-style: normal;
            font-weight: 500;
            font-size: 17px;
            line-height: 22px;
            letter-spacing: -0.41px;
            color: #FF3B30;
            display: block;
            text-align: center;
        }
    }
}
</style>
