<template>
    <f7-page
        class="withdrawal-view"
        :page-content="false"
        ref="self"
    >
        <WithdrawalViewNavbar
            ref="navbar"
            :withdrawal="withdrawal"
            @closeView="toPayments"
            :timer-service="withdrawalService.timerService"
        />
        <WithdrawalViewSubnavbar
            ref="subnavbar"
            :withdrawal="withdrawal"
            :with-transition="withTransitionSubnavbar"
            @toChat="toChat"
            payment=""/>
        <f7-page-content ptr @ptr:refresh="refreshPage" :class="{'with-transition-subnavbar': withTransitionSubnavbar}">
            <transition name="fade" mode="out-in">
                <template v-if="withdrawal && !pageLoading">
                    <WithdrawalViewContent
                        :withdrawal="withdrawal"
                        :timer-service="withdrawalService.timerService"
                        @cancelPayment="cancelPayment"
                        @toChat="toChat"
                        @recreatePayment="recreatePayment"
                    />
                </template>
                <template v-else>
                    <WithdrawalViewLoader/>
                </template>
            </transition>
        </f7-page-content>
        <template v-if="withdrawal && !pageLoading">
            <WithdrawalViewBottomButtons
                :withdrawal="withdrawal"
                @closeView="toPayments"
                @confirmWithdrawal="confirmWithdrawal"
                @finishOnappeal="finishOnappeal"
            />
            <WithdrawalViewDetailsPopup ref="withdrawalViewDetailsPopup"/>
        </template>
    </f7-page>
</template>

<script lang="ts" setup>
//@ts-ignore
import AppController from "@target/components/App/ts/AppController";
import {f7} from "framework7-vue";
import WithdrawalViewContent from "@/views/withdrawal-group/withdrawal/components/WithdrawalViewContent.vue";
import WithdrawalViewNavbar from "@/views/withdrawal-group/withdrawal/components/WithdrawalViewNavbar.vue";
import WithdrawalService from "@/services/operations/withdrawal/WithdrawalService";
import {onBeforeMount, onMounted, onUnmounted, ref, watch} from "vue";
import WithdrawalViewLoader from "@/views/withdrawal-group/withdrawal/components/atomic/WithdrawalViewLoader.vue";
import {useI18n} from "vue-i18n";
import {ToRef} from "@vue/runtime-core";
import WithdrawalViewSubnavbar from "@/views/withdrawal-group/withdrawal/components/atomic/WithdrawalViewSubnavbar.vue";
import {P_WITHDRAWAL_STATUS} from "@/entities/enums/PWithdrawalStatus";
import WithdrawalViewDetailsPopup from "@/views/withdrawal-group/withdrawal/components/WithdrawalViewDetailsPopup.vue";
import WithdrawalViewBottomButtons
    from "@/views/withdrawal-group/withdrawal/components/atomic/WithdrawalViewBottomButtons.vue";
import {BuyingOperationStatus} from "@enums/BuyingOperationStatus";

const {t} = useI18n({useScope: 'global'});
const withdrawalService: WithdrawalService = AppController.getInstance().withdrawalService;
let {withdrawal} = withdrawalService;
const pageLoading = ref(false);
const self: any = ref(null);
const navbar: any = ref(null);
const subnavbar: any = ref(null);
const withTransitionSubnavbar: ToRef<boolean> = ref(false);
const withdrawalViewDetailsPopup = ref(null);

const refreshPage = async (done: Function) => {
    try {
        await withdrawalService.updateWithdrawal();
    } catch (e: any) {
        f7.dialog.alert(t('g.errors.alert.default-text'), t('g.errors.alert.default-title'));
    } finally {
        setTimeout(async () => {
            done();
        }, 1000);
    }
};
const finishOnappeal = (callback: Function) => {
    f7.dialog.create({
        title: t("alerts.onappeal-finish.title"),
        text: t("alerts.onappeal-finish.text"),
        cssClass: "dialog_complain",
        buttons: [
            {
                text: t("alerts.onappeal-finish.no"),
                onClick: () => {
                    callback();
                }
            },
            {
                text: t("alerts.onappeal-finish.yes"),
                onClick: async () => {
                    await withdrawalService.confirmWithdrawal();
                    callback();
                }
            }
        ]
    }).open();
}
const toPayments = () => {
    f7.view.main.router.back('/payments', {force: true, clearPreviousHistory: true});
};
const recreatePayment = () => {

}

const confirmWithdrawal = async (callback: Function) => {
    try {
        await withdrawalService.confirmWithdrawal();
        toPayments();
    } catch (e: any) {
        f7.dialog.alert(t('g.errors.alert.default-text'), t('g.errors.alert.default-title'));
    } finally {
        callback();
    }
}
const cancelPayment = () => {
    const title = t("withdrawal.statuses.created.alerts.cancel.title");
    const text = "";
    f7.dialog.create({
        title: title,
        text: text,
        buttons: [
            {
                text: t("findAgent.cancel.no"),
            },
            {
                text: t("findAgent.cancel.yes"),
                onClick: async () => {
                    try {
                        f7.preloader.show();
                        await withdrawalService.cancelWithdrawal();
                        toPayments();
                    } catch (e: any) {
                        f7.dialog.alert(t('g.errors.alert.default-text'), t('g.errors.alert.default-title'));
                    } finally {
                        f7.preloader.hide();
                    }
                }
            }
        ]
    }).open();
}
const toChat = () => {
    // @ts-ignore
    f7.views.higher.router.navigate('/chat-withdrawal', {
        openIn: 'popup'
    });
}

const showSubnavbar = () => {
    const navbarDOM = (navbar.value.$el) as HTMLElement;
    navbarDOM.classList.add('with-custom-subnavbar');
    const pageContentDOM = (self.value.$el as HTMLElement).querySelector('.page-content') as HTMLElement;
    pageContentDOM.style.setProperty('--f7-page-content-extra-padding-top', '44px');
    subnavbar.value.showSubnavbar = true;
}
const hideSubnavbar = () => {
    const navbarDOM = (navbar.value.$el) as HTMLElement;
    navbarDOM.classList.remove('with-custom-subnavbar');
    const pageContentDOM = (self.value.$el as HTMLElement).querySelector('.page-content') as HTMLElement;
    pageContentDOM.style.setProperty('--f7-page-content-extra-padding-top', '0px');
    subnavbar.value.showSubnavbar = false;
}

const checkShowSubnavbar = () => {
    return !!(withdrawal.value &&
        withdrawal.value?.status && [
            P_WITHDRAWAL_STATUS.ACCEPTED,
            P_WITHDRAWAL_STATUS.CONFIRMED,
            P_WITHDRAWAL_STATUS.ONAPPEAL,
            P_WITHDRAWAL_STATUS.CASHED,
        ].includes(withdrawal.value?.status));
}

const checkAndChangePagePaddingBottom = () => {
    const pageContentEL: HTMLElement = ((self.value as any).$el as HTMLElement).querySelector('.page-content')!;
    if (withdrawal.value &&
        withdrawal.value?.status && [
            P_WITHDRAWAL_STATUS.CONFIRMED,
            P_WITHDRAWAL_STATUS.ONAPPEAL,
            P_WITHDRAWAL_STATUS.CASHED,
            P_WITHDRAWAL_STATUS.CANCELED
        ].includes(withdrawal.value?.status)
    ) {
        pageContentEL.style.cssText += "--f7-page-content-extra-padding-bottom: 78px";
    } else {
        pageContentEL.style.cssText += "--f7-page-content-extra-padding-bottom: 0px";
    }
};

const checkAndRunTimerCallbackFunc = () => {
    if (withdrawal.value &&
        withdrawal.value?.status && [
            BuyingOperationStatus.CREATED,
            P_WITHDRAWAL_STATUS.ONAPPEAL,
            P_WITHDRAWAL_STATUS.CASHED
        ].includes(withdrawal.value?.status)) {
        withdrawalService.timerService.setCallback(withdrawalService.updateWithdrawal.bind(withdrawalService));
    }
}

onMounted(() => {
    checkAndRunTimerCallbackFunc();
    if (checkShowSubnavbar()) showSubnavbar();
    checkAndChangePagePaddingBottom();
});
watch(withdrawal, (value) => {
    checkAndRunTimerCallbackFunc();
    if (checkShowSubnavbar()) {
        withTransitionSubnavbar.value = true;
        setTimeout(() => {
            showSubnavbar();
        }, 0)
    } else hideSubnavbar();
    checkAndChangePagePaddingBottom();
})
onUnmounted(() => {
    withdrawalService.reset();
});

</script>

<style lang="scss">
.withdrawal-view {
    --f7-page-bg-color: #FFF;

    .page-content {
        padding-top: calc(var(--f7-page-navbar-offset, 0px) + var(--f7-page-toolbar-top-offset, 0px) + var(--f7-page-subnavbar-offset, 0px) + var(--f7-page-searchbar-offset, 0px) + var(--f7-page-content-extra-padding-top, 0px)) !important;
        //--f7-page-content-extra-padding-bottom: 94px;

        //&.with-transition-subnavbar {
        //    transform: translate3d(0, 0, 0);
        //    will-change: transform;
        //    transition: 0.5s padding-top cubic-bezier(0.250, 0.460, 0.450, 0.940);
        //}

        &::-webkit-scrollbar {
            width: 0;
        }
    }
}

</style>
