<template>
    <div class="payment-amount-title">
        <p>{{ amount }} {{ abbr }}</p>
    </div>
</template>

<script setup lang="ts">
import {PropType, toRef} from "vue";

const props = defineProps({
    amount: {
        type: [Number, null] as PropType<number | null>,
        required: true
    },
    abbr: {
        type: [String, null] as PropType<string | null>,
        required: true
    },
});

const amount = toRef(props, "amount");
const abbr = toRef(props, "abbr");
</script>

<style lang="scss">
.payment-amount-title {
    text-align: center;
    margin-bottom: 16px;
    p {
        @include text(44px, 700, var(--brown1), 52.8px);
    }
}
</style>
