<template>
    <f7-page
        ref="self"
        class="withdrawal-wallet-created-page"
        :no-navbar="pageOpenIn === PageOpenIn.POPUP"
        :class="{'without-navbar': pageOpenIn === PageOpenIn.POPUP}"
        @page:beforein="pageBeforeIn"
    >
        <popup-navbar
            :title="$t('withdrawal-create.title')"
            v-if="pageOpenIn === PageOpenIn.POPUP"
            @on-left-click="routerService.toBack"
        />

        <template v-if="createWalletWithdrawalDto">
            <div class="withdrawal-wallet-created-page_amount">
                <p>{{  createWalletWithdrawalDto.amount }} USDT</p>
            </div>
            <div class="withdrawal-wallet-created-page_data">
                <div class="withdrawal-wallet-created-page_psystem">
                    <p class="withdrawal-wallet-created-page_title">{{ $t("withdrawal-created.network") }}</p>
                    <div class="withdrawal-wallet-created-page_text-wrapper">
                        <img :src="createWalletWithdrawalDto.psystem.imgPath!" alt="">
                        <p class="withdrawal-wallet-created-page_text">
                            {{ createWalletWithdrawalDto.psystem.name }}
                        </p>
                    </div>
                </div>
                <div class="withdrawal-wallet-created-page_address">
                    <p class="withdrawal-wallet-created-page_title">{{ $t("withdrawal-created.address") }}</p>
                    <p class="withdrawal-wallet-created-page_text">{{ createWalletWithdrawalDto.requisite.address }}</p>
                </div>
            </div>

            <div class="withdrawal-wallet-created-page_data">
                <div class="withdrawal-wallet-created-page_fee">
                    <p class="withdrawal-wallet-created-page_title">{{ $t("withdrawal-created.fee") }}</p>
                    <p class="withdrawal-wallet-created-page_text">0.5$</p>
                </div>
                <div class="withdrawal-wallet-created-page_amount-with-fee">
                    <p class="withdrawal-wallet-created-page_title">{{ $t("withdrawal-created.amount-with-fee") }}</p>
                    <p class="withdrawal-wallet-created-page_text">{{ amountWithFee }}$</p>
                </div>
            </div>

            <div class="withdrawal-wallet-created-page_hint">
                <i class="icon svg-warning"></i>
                <p>{{ $t("withdrawal-created.hint") }}</p>
            </div>

            <f7-block class="withdrawal-wallet-created-page_actions">
                <f7-button
                    class="theme-type"
                    preloader
                    :loading="submitLoading"
                    :disabled="submitLoading"
                    @click="submit"
                >
                    {{ $t('withdrawal-created.submit') }}
                </f7-button>
            </f7-block>
        </template>
    </f7-page>
</template>

<script setup lang="ts">
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import WithdrawalWalletCreatedPageController
    from "@/views/withdrawal-group/wallet/wallet-created/WithdrawalWalletCreatedPageController";
import {PageOpenIn} from "@enums/PageOpenIn";
import PopupNavbar from "@components/popup/popup-navbar/PopupNavbar.vue";
import RouterService from "@/services/RouterService";
import {computed, ref} from "vue";
import WithdrawalService from "@/services/operations/withdrawal/WithdrawalService";
import {f7} from "framework7-vue";
import {useI18n} from "vue-i18n";
import ErrorsService from "@/services/errors-service/ErrorsService";
import ServiceOperations from "@/services/v2/data/service-operations/ServiceOperations";

const amountWithFee = computed(() => {
    return +(createWalletWithdrawalDto.value!.amount + 0.5).toFixed(2)
});
const {t} = useI18n({useScope: 'global'});
const self = ref(null);
const routerService: RouterService = AppController.getInstance().routerService;
const controller = WithdrawalWalletCreatedPageController.of();
const { createWalletWithdrawalDto, pageOpenIn } = controller;
const submitLoading = ref(false);
const withdrawalService: WithdrawalService = AppController.getInstance().withdrawalService;
async function submit() {
    try {
        submitLoading.value = true;
        await withdrawalService.createWalletWithdrawal({
            amount: createWalletWithdrawalDto.value!.amount,
            requisite_id: createWalletWithdrawalDto.value!.requisite.id
        });
        await ServiceOperations.of().fetchOperations({ refresh: true });
        closePopup();
    } catch (e: any) {
        ErrorsService.of().handle(e);
    } finally {
        submitLoading.value = false;
    }
}
function closePopup() {
    // @ts-ignore
    const popupEl: HTMLDivElement = self.value.$el.closest('.withdrawal-popup')!;
    f7.popup.get(popupEl).close();
}
function pageBeforeIn() {}
</script>

<style lang="scss">
.withdrawal-wallet-created-page {
    .page-content {
        --f7-page-navbar-offset: 0px !important;
    }
    &_hint {
        width: calc(100% - 32px);
        display: flex;
        gap: 12px;
        margin-top: 24px;
        margin-left: 16px;
        padding: 12px;
        border-radius: 12px;
        background-color: rgba(230, 151, 0, 0.1);
        p {
            @include text(14px, 400, #CF7529, 16.8px);
        }
    }
    &_fee {
        padding: 10px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

    }
    &_amount-with-fee {
        padding: 10px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            background-color: var(--f7-list-border-color);
            display: block;
            z-index: 15;
            top: 0;
            right: auto;
            bottom: auto;
            left: 16px;
            height: 1px;
            width: calc(100% - 16px);
            transform-origin: 50% 0%;
            transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
        }
    }
    &_actions {
        margin-top: 32px;
    }
    &.without-navbar {
        --f7-page-content-extra-padding-top: 24px;
    }
    &_psystem {
        padding: 10px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &_address {
        padding: 10px 16px;
        //display: flex;
        //align-items: flex-start;
        //gap: 8px;
        //flex-direction: column;
        position: relative;

        p:nth-child(2) {
            margin-top: 8px;
            word-break: break-all;
        }

        &::before {
            content: "";
            position: absolute;
            background-color: var(--f7-list-border-color);
            display: block;
            z-index: 15;
            top: 0;
            right: auto;
            bottom: auto;
            left: 16px;
            height: 1px;
            width: calc(100% - 16px);
            transform-origin: 50% 0%;
            transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
        }
    }
    &_text-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        img {
            width: 24px;
        }
    }
    &_title {
        @include text(16px, 400, var(--grey2), 24px);
    }
    &_text {
        @include text(16px, 500, var(--brown1), 24px);
    }
    &_amount {
        margin-top: 32px;
        margin-bottom: 32px;
        display: flex;
        justify-content: center;
        p {
            @include text(28px, 700, var(--brown1), 33.6px);
        }
    }
    &_data {
        position: relative;
        background: #FFF;

        & + .withdrawal-wallet-created-page_data {
            margin-top: 32px;
        }

        &::before {
            content: "";
            position: absolute;
            background-color: var(--f7-list-border-color);
            display: block;
            z-index: 15;
            top: 0;
            right: auto;
            bottom: auto;
            left: 0;
            height: 1px;
            width: 100%;
            transform-origin: 50% 0%;
            transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
        }
        &::after {
            content: "";
            position: absolute;
            background-color: var(--f7-list-border-color);
            display: block;
            z-index: 15;
            top: auto;
            right: auto;
            bottom: 0;
            left: 0;
            height: 1px;
            width: 100%;
            transform-origin: 50% 0%;
            transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
        }
    }
}
</style>
