<template>
    <f7-page
        ref="self"
        :page-content="false"
        class="simple-requisites-view"
        :no-navbar="openIn === PageOpenIn.POPUP"
        :class="{'without-navbar': openIn === PageOpenIn.POPUP}"
        @page:beforein="handlePageBeforeIn"
    >
        <f7-navbar v-if="openIn === PageOpenIn.PAGE" class="simple-requisites-view__navbar">
            <f7-nav-left>
                <a href="#" class="link icon-only" @click="routerService.toBack">
                    <i class="icon-back icon"></i>
                </a>
            </f7-nav-left>
            <f7-nav-title>{{ $t('simple-requisites-view.navbar.title') }}</f7-nav-title>
            <f7-nav-right>
                <transition name="fade" mode="out-in">
                    <f7-button
                        @click="toRequisitePage(null)"
                        class="theme-type mini"
                        v-if="controller.requisites.value.length > 0"
                    >
                        <i class="icon plus-svg svg-filter-FFFFFF"></i>
                        {{ $t('simple-requisites-view.navbar.right.submit') }}
                    </f7-button>
                </transition>
            </f7-nav-right>
        </f7-navbar>
        <f7-page-content :ptr="openIn === PageOpenIn.PAGE" @ptr:refresh="refreshPageEvent">
            <popup-navbar
                :title="$t('requisites.popup-title')"
                v-if="openIn === PageOpenIn.POPUP"
                @on-left-click="routerService.toBack"
            >
                <template #right>
                    <f7-button
                        @click="toRequisitePage(null)"
                        class="theme-type mini"
                        v-if="controller.requisites.value.length > 0"
                    >
                        <i class="icon plus-svg svg-filter-FFFFFF"></i>
                        {{ $t('simple-requisites-view.navbar.right.submit') }}
                    </f7-button>
                </template>
            </popup-navbar>

            <transition name="fade" mode="out-in">
                <template v-if="!pageLoading">
                    <requisites-page-requisites
                        v-if="controller.requisites.value.length > 0"
                        :requisites="requisites"
                        :page-open-in="openIn"
                        @on-filter-event="handleRequisiteFilterEvent"
                        @on-edit-item="toRequisitePage"
                        @handle-select-item="handleSelectRequisite"
                    />
                    <SimpleRequisitesViewEmptyState v-else @submit="toRequisitePage"/>
                </template>
                <SimplePageContentLoader v-else/>
            </transition>
        </f7-page-content>
    </f7-page>
</template>

<script setup lang="ts">
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import RouterService from "@/services/RouterService";
import SimpleRequisitesViewEmptyState from "./components/SimpleRequisitesViewEmptyState.vue";
import {computed, onBeforeMount, ref, ToRef} from "vue";
import SimplePageContentLoader from "@components/atomic/simple-page-content-loader/SimplePageContentLoader.vue";
import {f7} from "framework7-vue";
import {useI18n} from "vue-i18n";
import RequisitesPageController from "@/views/requisites-group/requisites/RequisitesPageController";
import RequisitesPageRequisites from "@/views/requisites-group/requisites/components/RequisitesPageRequisites.vue";
import Requisite from "@models/requisite/Requisite";
import {RequisitesTypeFilter} from "@/views/requisites-group/requisites/types/RequisitesTypeFilter";
import PopupNavbar from "@components/popup/popup-navbar/PopupNavbar.vue";
import {PageOpenIn} from "@enums/PageOpenIn";
import GlobalRequisitesController from "@/views/requisites-group/GlobalRequisitesController";
import {RequisitePsystemTargetType} from "@enums/RequisitePsystemTargetType";

const {t} = useI18n({useScope: 'global'});
const pageLoading = ref(false);
const controller = RequisitesPageController.of();
const {openIn} = controller;
const requisites: ToRef<Requisite[]> = ref([]);
let activeFilter: RequisitesTypeFilter | null = null;
updateLocalRequisites();
const routerService: RouterService = AppController.getInstance().routerService;

onBeforeMount(() => {
    initPageContent();
});

async function handleSelectRequisite(requisite: Requisite) {
    try {
        GlobalRequisitesController.getInstance().selectActiveRequisite(requisite);
        // if (requisite.getType() === RequisitePsystemTargetType.P2P) {
        //     f7.preloader.show();
        //     await GlobalRequisitesController.getInstance().fetchActiveRequsiteAmountRange(requisite.id);
        // } else {
        //     GlobalRequisitesController.getInstance().activeRequisiteAmountRange.value = null;
        // }
    } catch (e: any) {

    } finally {
        f7.preloader.hide();
        routerService.toBack();
    }
}

function handleRequisiteFilterEvent(filter: RequisitesTypeFilter) {
    activeFilter = filter;
    const regex = new RegExp(filter.type ? filter.type.toString() : "", 'g');
    requisites.value = controller.requisites.value.filter((requisite: Requisite) => requisite.psystem.targetType.match(regex))
}

async function toRequisitePage(requisite: Requisite | null) {
    try {
        f7.preloader.show();
        await routerService.toRequisitePage(requisite, openIn)
    } catch (e: any) {

    } finally {
        f7.preloader.hide();
    }
}

const refreshPageEvent = async (done: Function) => {
    try {
        await updateRequisites();
    } catch (e: any) {
        f7.dialog.alert(t('g.errors.alert.default-text'), t('g.errors.alert.default-title'));
    } finally {
        done();
    }
}
const makeShortAddress = (value: string) => {
    return '*'.concat(value.slice(-4));
}
const initPageContent = async () => {
    // try {
    //     if (requisites.value.length === 0) {
    //         pageLoading.value = true;
    //         await requisitePs.fetchAllRequisites();
    //     }
    // } catch (e: any) {
    //     f7.dialog.alert(t('g.errors.alert.default-text'), t('g.errors.alert.default-title'), () => {
    //         routerService.toBack();
    //     });
    // } finally {
    //     pageLoading.value = false;
    // }
}

async function updateRequisites(preloader: boolean = false) {
    try {
        if (preloader) f7.preloader.show();
        await controller.fetchAndSetRequisites();
        updateLocalRequisites();
    } catch (e: any) {

    } finally {
        setTimeout(() => {
            if (preloader) f7.preloader.hide();
        }, 314);
    }
}

async function handlePageBeforeIn() {
    updateLocalRequisites();
    if (activeFilter) handleRequisiteFilterEvent(activeFilter)
}

function updateLocalRequisites() {
    requisites.value = [];
    requisites.value.push(...controller.requisites.value);
}

async function checkForceUpdateRequisites() {
    if (RequisitesPageController.of().forceUpdateRequisite) {
        await updateRequisites();
        RequisitesPageController.of().forceUpdateRequisite = false;
    }
}
</script>

<style lang="scss">
.simple-requisites-view {
    &__navbar {
        //--f7-navbar-inner-padding-right: 16px;
        //.right {
        //    min-width: 100px;
        //    justify-content: flex-end;
        //    padding-right: 8px;
        //    margin-left: 0 !important;
        //}
    }

    &.without-navbar {
        --f7-page-navbar-offset: 0px;
        --f7-page-content-extra-padding-top: 24px;

        .popup-navbar {
            padding-right: 8px;
        }
        .requisites-page_requisites {
            margin-top: 16px;
        }
    }

    .page-content {
        .list {
            margin: 0;

            .requisite {
                &__address {
                    @include text(16px, 400, var(--grey2), 19.2px);
                }

                img {
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .info-block {
            margin-top: 56px;
        }
    }
}
</style>
