import {Expose, Type} from "class-transformer";
import PaymentFeedbackCancelOptionParamsInput
    from "@models/operations/payment/feedback/PaymentFeedbackCancelOptionParamsInput";

export default class PaymentFeedbackCancelOptionParams {
    @Expose({name: 'input'})
    @Type(() => PaymentFeedbackCancelOptionParamsInput)
    private _input: PaymentFeedbackCancelOptionParamsInput | null = null;

    get input(): PaymentFeedbackCancelOptionParamsInput | null {
        return this._input;
    }
}