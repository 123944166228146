import {ref, ToRef} from "vue";
import PaymentService from "@/services/operations/payment/PaymentService";
import LightAppService from "@/services/app/LightAppService";
import SocketService from "@/services/SockerService";
import {plainToInstance} from "class-transformer";
import ResponseJson from "@models/responses/ResponseJson";
import ServiceDI from "@/services/v2/service-DI/ServiceDI";
import ServiceAccount from "@/services/v2/data/service-account/ServiceAccount";
import ServiceFetchOperation from "@/services/v2/data/service-fetch-operation/ServiceFetchOperation";
import RepositoryFetchOperation from "@/repositories/v2/repository-fetch-operation/RepositoryFetchOperation";
import ServiceTelegram from "@/services/v2/service-telegram/ServiceTelegram";

export default class LightAppController {
    protected _serviceDI: ServiceDI | null = null;
    protected _paymentService: PaymentService;
    private _token: ToRef<string | null> = ref(null);
    private _criticalErrorResponse: ResponseJson<any> | null = null;

    constructor() {
        this._paymentService = new PaymentService();
    }

    public async init(callback?: Function) {
        this._serviceDI = this.createServiceDI();
    }

    protected createServiceDI() {
        return ServiceDI.builder()
            .addPack([
                {key: "fetchOperation", value: new ServiceFetchOperation(new RepositoryFetchOperation())},
                {key: "telegram", value: new ServiceTelegram()},
            ])
            .build();
    }

    public service<T>(name: string): T | null {
        if (!this._serviceDI) throw new Error("Service DI is not initialized");
        return this._serviceDI.get<T>(name);
    }

    get criticalErrorResponse(): ResponseJson<any> | null {
        return this._criticalErrorResponse;
    }

    set criticalErrorResponse(value: ResponseJson<any> | null) {
        this._criticalErrorResponse = value;
    }

    protected retrieveCredentialsFromUrl(url: URL) {
        localStorage.setItem("url", url.href);
        const token = url.searchParams.get("token");
        const rt = url.searchParams.get("rt");
        const csp = url.searchParams.get("csp");
        return {token, refreshToken: rt, csp};
    }

    protected persistCredentials(token: string | null, refreshToken: string | null, csp: string | null) {
        if (token) localStorage.setItem('ltoken', token);
        if (refreshToken) localStorage.setItem('lrefreshToken', refreshToken);
        if (csp) localStorage.setItem('csp', csp);
    }

    protected retrieveMsidFromUrl(url: URL) {
        return url.searchParams.get("bp_id");
    }

    protected isUrlHasTokenRequiredData(url: URL) {
        return !!(
            url.searchParams.get("token") &&
            url.searchParams.get("bp_id") &&
            url.searchParams.get("csp")
        );
    }

    protected isUrlHasError(url: URL) {
        return !!(
            url.searchParams.get("err")
        );
    }

    protected isUrlHasPid(url: URL) {
        return !!url.searchParams.get("pid");
    }

    protected async initializeWithPid(url: URL) {
        const lightAppService = new LightAppService();

        const pid = this.retrievePidFromUrl(url) as string;
        const aggregateData = await lightAppService.fetchDataWithPid(pid);

        // LogService.of().log("MainResponse", aggregateData);

        const {token, refreshToken, csp} = aggregateData.credentials;
        this.persistCredentials(token, refreshToken, csp);
        this.token = token;
        this._paymentService.payment = aggregateData.payment;

        ServiceAccount.of().account = aggregateData.profile;

        SocketService.getInstance().init(aggregateData.profile.id).connect();
    }

    protected async initializeWithMsid(url: URL) {
        const {token, refreshToken, csp} = this.retrieveCredentialsFromUrl(url);
        this.persistCredentials(token, refreshToken, csp);
        this.token = token;

        const msid = this.retrieveMsidFromUrl(url) as string;

        if (msid) {
            this._paymentService.isExternalCreated.value = true;
            this._paymentService.paymentFactory(msid);
        }

        await ServiceAccount.of().fetchAccount();

        SocketService.getInstance().init(ServiceAccount.of().account.value!.id).connect();
    }

    public retrievePidFromUrl(url: URL) {
        return url.searchParams.get("pid");
    }

    public get token(): ToRef<string | null> {
        return this._token;
    }

    public set token(value: string | null) {
        this._token.value = value;
    }

    protected resetCredentials() {
        localStorage.removeItem("token");
        localStorage.removeItem("ltoken");
        localStorage.removeItem("lrefreshToken");
        localStorage.removeItem("csp");
    }

    public parseUrlErrorAndSave(url: URL) {
        try {
            const errHash = url.searchParams.get("err")!;
            const errJSON = atob(errHash);
            const errObj: any = JSON.parse(errJSON);
            this._criticalErrorResponse = plainToInstance(ResponseJson, errObj);
        } catch (e) {
        }
    }
}
