import { Expose } from "class-transformer";

export default class ChatOperation {
    @Expose({name: 'id'})
    private readonly _id: number;
    @Expose({name: 'unread'})
    private _unread: number;

    constructor(id: number, unread: number) {
        this._id = id;
        this._unread = unread;
    }

    public get id(): number {
        return this._id;
    }

    public get unread(): number {
        return this._unread;
    }

    public set unread(value: number) {
        this._unread = value;
    }
}
