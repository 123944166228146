<template>
    <f7-page ref="self" :page-content="false" class="settings-page" @page:beforein="pageBeforeInEvent">
        <f7-navbar>
            <f7-nav-left>
                <a href="#" class="link icon-only" @click="CLOSE">
                    <i class="icon-back icon"></i>
                </a>
            </f7-nav-left>
            <f7-nav-title>
                {{ $t('g.settings.page.title') }}
            </f7-nav-title>
            <f7-nav-right></f7-nav-right>
        </f7-navbar>
        <f7-page-content>
            <p class="list-title">{{ $t('settings.my-profile') }}</p>
            <f7-list class="list-outline list-strong list-dividers">
                <f7-list-item :title="$t('g.settings.page.links.phone')"
                              :after="account.contact.phone"></f7-list-item>
                <f7-list-item :title="$t('g.settings.page.links.name')" link="/settings/change-short-name">
                    <template #after>
                        <template v-if="account.shortName && account.shortName.length > 0">
                            {{ account.shortName }}
                        </template>
                        <template v-else>
                            <i class="icon i-svg-warning"></i>
                        </template>
                    </template>
                </f7-list-item>
                <f7-list-item :title="$t('settings.user-id')" :after="account.id"/>
                <f7-list-item :title="$t('g.settings.page.links.language')" link="/settings/lang"
                              :after="detectCountryName(account.settings.localization)"></f7-list-item>
                <f7-list-item :title="$t('settings.remove-account')" link="/remove-account"></f7-list-item>
                <!-- <f7-list-item :title="$t('g.settings.page.links.currency')" link="/settings/currency" :after="account.currency.name"></f7-list-item> -->
            </f7-list>
<!--            <f7-list class="separate-list list-outline list-strong list-dividers">-->
<!--                <f7-list-item @click="removeAccountEvent">-->
<!--                    <template #title>-->
<!--                        <span>{{ $t('settings.remove-account') }}</span>-->
<!--                    </template>-->
<!--                </f7-list-item>-->
<!--            </f7-list>-->
            <version
                ref="versionRef"
                :releazioConfig="serviceRealizio?.activeConfig.value"
                :currentVersion="currentVersion"
                :isNeedUpdate="serviceRealizio?.isNeedUpdate.value"
                :isViewLastPost="serviceRealizio?.isViewedLastPost.value || !serviceRealizio.hasVersionPost()"
                :isCanShowUpdateButton="getDevice().cordova"
                @openDeeplink="openReleazioDeeplink"
                @openPosts="toReleazioPostsPage"
            />
        </f7-page-content>
    </f7-page>
</template>

<script lang="ts" setup>
import {useI18n} from 'vue-i18n'
import {f7} from 'framework7-vue';
import {Ref, ref} from "@vue/runtime-core";
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import Version from "@components/atomic/version/Version.vue";
import {getDevice} from "framework7/shared/get-device";
import ServiceAccount from "@/services/v2/data/service-account/ServiceAccount";
import ModelAccount from "@models/v2/account/ModelAccount";
import RouterService from "@/services/RouterService";
import ServiceReleazio from "@/services/v2/service-releazio/ServiceReleazio";
import {onMounted} from "vue";

const {t} = useI18n({useScope: 'global'});
const account = ServiceAccount.of().account as Ref<ModelAccount>;
const version = import.meta.env.VITE_VERSION;
const serviceRouter = AppController.of().service<RouterService>("router");
const serviceRealizio: ServiceReleazio = AppController.of().service<ServiceReleazio>("releazio");
const currentVersion = import.meta.env.VITE_VERSION ?? "1.0.0";
const versionRef = ref(null);
let versionTimerRef: any = null;

onMounted(() => {
    initVersionEvents();
});

function pageBeforeInEvent() {
    if (serviceRealizio) {
        serviceRealizio.updateViewPost();
    }
}

function toReleazioPostsPage() {
    if (serviceRouter) {
        serviceRouter.toReleazioPostsPage();
    }
}

function openReleazioDeeplink() {
    if (serviceRealizio) {
        serviceRealizio.openDeepLink();
    }
}

function initVersionEvents() {
    if (versionRef.value && serviceRealizio) {
        const serviceRouter = AppController.of().service<RouterService>("router");
        serviceRealizio.initVersionEvents((versionRef.value as any).self, versionTimerRef, serviceRouter);
    }
}

const CLOSE = () => {
    f7.view.main.router.back('/payments', {force: true, clearPreviousHistory: true});
}

const detectCountryName = (systemName: string) => {
    switch (systemName) {
        case 'ru':
            return t("g.settings.selectLanguage.languages.ru");
        case 'en':
            return t("g.settings.selectLanguage.languages.en");
    }
    return systemName;
}
</script>

<style lang="scss" src="@/scss/views/settings-view.scss"></style>
<style lang="scss">
.settings-page__navbar {
    --f7-navbar-bg-color: #FFF;
}
</style>
