import AbstractDocument from "@/services/firebase/firestore/documents/AbstractDocument";
import {DocumentTypes} from "@/services/firebase/firestore/documents/DocumentTypes";
import {Expose} from "class-transformer";

export default class DocumentPurchase extends AbstractDocument {
    @Expose({name: 'isCanSelectAgent'})
    private _isCanSelectAgent: boolean | null = null;
    @Expose({name: 'isFractionalNumbersView'})
    private _isFractionalNumbersView: boolean | null = null;
    @Expose({name: 'defaultAmount'})
    private _defaultAmount: number | null = null;
    @Expose({name: 'minAmountEnabled'})
    private _minAmountEnabled: boolean = false;
    @Expose({name: 'isFeedbackCancelEnabled'})
    private _isFeedbackCancelEnabled: boolean = false;
    @Expose({name: 'minAmount'})
    private _minAmount: number | null = null;
    protected _type: DocumentTypes = DocumentTypes.PURCHASE;

    constructor() {
        super();
    }

    get isCanSelectAgent(): boolean | null {
        return this._isCanSelectAgent;
    }

    get isFeedbackCancelEnabled(): boolean {
        return this._isFeedbackCancelEnabled;
    }

    get isFractionalNumbersView(): boolean | null {
        return this._isFractionalNumbersView;
    }

    get defaultAmount() {
        return this._defaultAmount;
    }

    get minAmountEnabled(): boolean {
        return this._minAmountEnabled;
    }

    get minAmount() {
        return this._minAmount;
    }
}
