import {f7} from "framework7-vue";
import i18n from "@/langs/i18n";
import {FirebaseService} from "@/services/firebase/FirebaseService";
import LogService from "@/services/log-service/LogService";
import {FirebaseEvents} from "@/services/firebase/analytics/FirebaseEvents";
import FirebaseEventBuilder from "@/services/firebase/analytics/FirebaseEventBuilder";
import {AccountError, UserError} from "@enums/ServerErrorCodes";
import ServiceAccount from "@/services/v2/data/service-account/ServiceAccount";

export default class ErrorsService {
    private static _instance: ErrorsService | null = null;

    public static of() {
        if (this._instance === null) this._instance = new ErrorsService();
        return this._instance;
    }

    public async handle(e: any, callback: Function | null = null) {
        if (e.code && i18n.global.te(`errors.${e.code}`)) {
            switch (e.code) {
                case AccountError.AgentNotFound:
                    FirebaseService.of().analyticsEvent(
                        FirebaseEvents.PAYMENT_ERROR_NO_AGENTS,
                        FirebaseEventBuilder.of().build()
                    );
                    typeof callback === "function"
                        ? (
                            () => {
                                callback()
                            }
                        )()
                        : f7.dialog.alert(i18n.global.t(`errors.${e.code}`), i18n.global.t('g.errors.alert.default-title'));
                    break;
                case AccountError.AgentsUnavailableForAmount:
                    LogService.of().log("ErrorService@handle", AccountError.AgentsUnavailableForAmount);
                    FirebaseService.of().analyticsEvent(
                        FirebaseEvents.PAYMENT_ERROR_WRONG_RANGE,
                        FirebaseEventBuilder.of().build()
                    );
                    FirebaseService.of().emptyRangeMetricSuccess.reset();
                    FirebaseService.of().wrongRangeMetricSuccess.startObserve();
                    typeof callback === "function"
                        ? (
                            () => {
                                callback()
                            }
                        )()
                        : f7.dialog.alert(i18n.global.t(`errors.${e.code}`), i18n.global.t('g.errors.alert.default-title'));
                    break;
                case AccountError.AgentsUnavailableForRangeAmount:
                    LogService.of().log("ErrorService@handle", AccountError.AgentsUnavailableForRangeAmount);
                    FirebaseService.of().analyticsEvent(
                        FirebaseEvents.PAYMENT_ERROR_EMPTY_RANGE,
                        FirebaseEventBuilder.of().build()
                    );
                    FirebaseService.of().wrongRangeMetricSuccess.reset();
                    FirebaseService.of().emptyRangeMetricSuccess.startObserve();
                    typeof callback === "function"
                        ? (
                            () => {
                                callback()
                            }
                        )()
                        : f7.dialog.alert(i18n.global.t(`errors.${e.code}`), i18n.global.t('g.errors.alert.default-title'));
                    break;
                case UserError.UserTemporarilyBlocked:
                    await ServiceAccount.of().fetchAccount();
                    setTimeout(() => {
                        //@ts-ignore
                        const sheet = f7.sheet.create({
                            el: document.querySelector('.ban-sheet')! as HTMLElement,
                        });

                        sheet.open();
                    }, 314);
                    break;
                default:
                    f7.dialog.alert(i18n.global.t(`errors.${e.code}`), i18n.global.t('g.errors.alert.default-title'));
            }
        } else {
            f7.dialog.alert(i18n.global.t('g.errors.alert.default-text'), i18n.global.t('g.errors.alert.default-title'));
        }
    }
}
