import {ref, ToRef} from "vue";
import {PageOpenIn} from "@enums/PageOpenIn";
import CreateWalletWithdrawalDto from "@/services/operations/withdrawal/dto/CreateWalletWithdrawalDto";

export default class WithdrawalWalletCreatedPageController {
    private static _instance: WithdrawalWalletCreatedPageController | null = null;
    private _createWalletWithdrawalDto: ToRef<CreateWalletWithdrawalDto | null> = ref(null);
    private _pageOpenIn: PageOpenIn = PageOpenIn.POPUP;

    public static of () {
        if (this._instance === null) this._instance = new WithdrawalWalletCreatedPageController();
        return this._instance;
    }

    get createWalletWithdrawalDto(): ToRef<CreateWalletWithdrawalDto | null> {
        return this._createWalletWithdrawalDto;
    }

    set createWalletWithdrawalDto(value: CreateWalletWithdrawalDto | null) {
        this._createWalletWithdrawalDto.value = value;
    }

    get pageOpenIn(): PageOpenIn {
        return this._pageOpenIn;
    }

    async init(createWalletWithdrawalDto: CreateWalletWithdrawalDto, pageOpenIn: PageOpenIn) {
        this._pageOpenIn = pageOpenIn;
        this._createWalletWithdrawalDto.value = createWalletWithdrawalDto;
    }
}
