import {Expose, Type} from "class-transformer";
import PaymentFeedbackCancelOption from "@models/operations/payment/feedback/PaymentFeedbackCancelOption";

export default class PaymentFeedbackCancel {
    @Expose({name: 'id'})
    private _id: number | null = null;
    @Expose({name: 'title'})
    private _title: string | null = null;
    @Expose({name: 'description'})
    private _description: string | null = null;
    @Expose({name: 'actionKey'})
    private _actionKey: string | null = null;
    @Expose({name: 'options'})
    @Type(() => PaymentFeedbackCancelOption)
    private _options: PaymentFeedbackCancelOption[] = [];

    get options(): PaymentFeedbackCancelOption[] {
        return this._options;
    }

    get actionKey(): string | null {
        return this._actionKey;
    }

    get description(): string | null {
        return this._description;
    }

    get title(): string | null {
        return this._title;
    }

    get id(): number | null {
        return this._id;
    }
}