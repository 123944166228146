<template>
    <f7-page
        class="payments-view"
        name="main"
        :page-content="false"
        @page:beforein="pageBeforeInEvent"
        @page:afterin="afterIn"
        @page:beforeout="pageBeforeOut"
    >
        <Navbar
            :viewNavbarBalance="viewNavbarBalance"
            :viewNavbarLogo="viewNavbarLogo"
            @openSheetModalForNewPayment="openSheetModalForNewPayment"
        />
        <f7-page-content ptr @ptr:refresh="eventPtrRefresh" infinite @infinite="eventInfinite">
            <template v-if="(account && payments) && payments.length > 0 && account.balance!.amountC != 0">
                <Transition name="fade" mode="out-in">
                    <BalanceBlock
                        v-if="account"
                        :account="account"
                        @openSheetModalForWithdrawal="openSheetModalForWithdrawal"
                        @openSheetModalForNewPayment="openSheetModalForNewPayment"
                        @infoClick="openBalanceHintSheet"
                    />
                    <BalanceBlockSkeleton v-else/>
                </Transition>
            </template>

            <Transition name="fade" mode="out-in">
                <template v-if="payments">
                    <Transition name="fade" mode="out-in">
                        <template v-if="payments.length > 0">
                            <MyPayments
                                :operations="payments"
                                :account="account"
                                @toOperationIncome="toOperationIncomeHandler"
                                @toOperationPurchase="toOperationPurchaseHandler"
                                @toOperationWithdrawal="toOperationWithdrawalHandler"
                                @toOperationWithdrawalWallet="toOperationWithdrawalWalletHandler"
                            />
                        </template>
                        <template v-else>
                            <SlothBlock
                                :title="$t('g.payments.noPayments.title')"
                                :subtitle="$t('g.payments.noPayments.text')"
                                :type="SLOTH_TYPE.WIN"
                            >
                                <template #button>
                                    <f7-button
                                        preloader
                                        class="theme-type width-100"
                                        :loading="nextbtnLoading"
                                        :disabled="nextbtnLoading"
                                        @click="openSheetModalForNewPayment"
                                    >{{ $t('payments.newPayment') }}
                                    </f7-button>
                                </template>
                            </SlothBlock>
                        </template>
                    </Transition>
                </template>
                <MyPaymentsSkeleton v-else/>
            </Transition>
        </f7-page-content>

        <Transition name="fade" mode="out-in">
            <template v-if="(payments && account) && payments.length > 0 && account.balance!.amountC == 0">
                <div class="bottom-block" ref="bottom-block">
                    <div class="row-actions">
                        <f7-button preloader @click="openSheetModalForNewPayment" :loading="nextbtnLoading"
                                   :disabled="nextbtnLoading" fill class="primary">{{ $t('payments.newPayment') }}
                        </f7-button>
                    </div>
                </div>
            </template>
        </Transition>

        <no-agents-sheet/>
        <ban-sheet
            v-if="account?.settings.ban"
            ref="ban-sheet"
            :account="account"
        />
        <balance-hint-sheet v-if="isBalanceHintSheet" @closed="isBalanceHintSheet = false"/>
    </f7-page>
</template>

<script lang="ts">
import {useI18n} from 'vue-i18n'
import PaymentsViewController from "./ts/PaymentsViewController";
import {computed, defineAsyncComponent, ref} from "vue";
import Navbar from "@/targets/integration/views/payments/components/Navbar.vue";
import SlothBlock from "@/components/messages/SlothBlock.vue";
import {f7} from "framework7-vue";
import GlobalRequisitesController from "@/views/requisites-group/GlobalRequisitesController";
import BalanceBlock from '@components/atomic/balance/BalanceBlock.vue';
import BalanceBlockSkeleton from "@components/atomic/balance/BalanceBlockSkeleton.vue";
import {SLOTH_TYPE} from "@/entities/enums/SlothType";
import AppController from "@/targets/integration/components/App/ts/AppController";
import MyPaymentsSkeleton from "@components/atomic/operations/MyPaymentsSkeleton.vue";
import ErrorsService from "@/services/errors-service/ErrorsService";
import RouterService from "@/services/RouterService";
import ServiceAccount from "@/services/v2/data/service-account/ServiceAccount";
import ServiceOperations from "@/services/v2/data/service-operations/ServiceOperations";
import PaymentIncomeOperation from "@models/operations/PaymentIncomeOperation";
import ServicePaymentIncome from "@/services/v2/data/service-payment-income/ServicePaymentIncome";
import {OperationType} from "@enums/operation/OperationType";
import WithdrawalOperation from "@models/operations/WithdrawalOperation";
import ServiceFetchOperation from "@/services/v2/data/service-fetch-operation/ServiceFetchOperation";
import WithdrawalWalletOperation from "@models/operations/withdrawal/wallet/WithdrawalWalletOperation";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import {FirebaseService} from "@/services/firebase/FirebaseService";

export default {
    computed: {
        SLOTH_TYPE() {
            return SLOTH_TYPE
        }
    },
    components: {
        BalanceBlockSkeleton,
        MyPaymentsSkeleton,
        SlothBlock,
        BalanceBlock,
        'ban-sheet': defineAsyncComponent(() => import('@/components/sheets/BanSheet.vue')),
        'balance-hint-sheet': defineAsyncComponent(() => import('@components/sheets/BalanceHintSheet.vue')),
        'no-agents-sheet': defineAsyncComponent(() => import('@/components/sheets/NoAgentsSheet.vue')),
        'MyPayments': defineAsyncComponent(() => import('./components/MyPayments.vue')),
        Navbar
    },
    setup() {
        const routerService: RouterService = AppController.getInstance().routerService;
        const servicePaymentIncome: ServicePaymentIncome | null = AppController.of().service<ServicePaymentIncome>('paymentIncome');
        const {t} = useI18n({useScope: 'global'});
        const vc = PaymentsViewController.of();
        const account = ServiceAccount.of().account;
        const payments = ServiceOperations.of().operations;
        const allowInfinity = false;
        const nextbtnLoading = ref(false);
        const requisitePs = GlobalRequisitesController.getInstance();
        const viewNavbarBalance = ref(false);
        const viewNavbarLogo = ref(false);
        const appIsReady = AppController.getInstance().isReady;
        const isAuth = AppController.of().isAuth;
        const isBalanceHintSheet = ref(false);
        const totalPages = ServiceOperations.of().totalPages;
        const currentPage = ServiceOperations.of().page;
        const isDisallowFetchInfinityScroll = computed(() => currentPage.value > totalPages.value);

        return {
            routerService,
            servicePaymentIncome,
            isBalanceHintSheet,
            appIsReady,
            viewNavbarLogo,
            viewNavbarBalance,
            requisitePs,
            t,
            nextbtnLoading,
            payments,
            account,
            vc,
            allowInfinity,
            isDisallowFetchInfinityScroll,
            isAuth
        }
    },
    async mounted() {
        this.updateInfinityScroll();
    },
    watch: {
        'payments'() {
            this.detectBottomPadding();
            setTimeout(() => {
                this.updateInfinityScroll();
            }, 700)
        },
        async 'appIsReady'(value: boolean) {
            //TODO в будущем переработать шаблоны так чтобы не нужен был this.isAuth и watch за appIsReady (Связано с неправильно инициализацией приложения)
            if (value && this.isAuth) {
                await this.vc.updateAccountData();
                await this.fetchOperations({refresh: true});
            }
        }
    },
    methods: {
        async fetchOperations(config: any) {
            try {
                await ServiceOperations.of().fetchOperations(config)
            } catch (e) {
                await ErrorsService.of().handle(e);
            }
        },
        openBalanceHintSheet() {
            this.isBalanceHintSheet = true;
        },
        pageBeforeOut() {
            this.hideBottomBlock();
        },
        hideBottomBlock() {
            const el = this.$refs['bottom-block'] as HTMLDivElement;
            if (el) {
                el.classList.remove('slide-in-bottom');
            }
        },
        detectBottomPadding() {
            if (this.payments.length > 0 && this.account.balance!.amountC != 0) {
                const self = document.querySelector('.payments-view')!;
                (self as HTMLElement).style.setProperty('--payments-view_bottom-block', '0');
            }
        },
        prepareLinkName(link: string) {
            return link.replace(/(http)s?:\/\//, '').replace(/\..+/, '');
        },
        TO_SETTINGS() {
            f7.views.current.router.navigate('/settings');
        },
        async pageBeforeInEvent() {
            if (this.appIsReady) {
                await this.vc.updateAccountData();
                await this.fetchOperations({refresh: true});
            }
        },
        async afterIn() {
            AppController.getInstance().setPaymentRequestData(null);
        },
        updateInfinityScroll() {
            if (!this.payments || this.isDisallowFetchInfinityScroll) {
                (document.querySelector('.infinite-scroll-preloader') as HTMLDivElement).style.display = 'none';
                this.allowInfinity = false;
            } else {
                (document.querySelector('.infinite-scroll-preloader') as HTMLDivElement).style.display = 'block';
                this.allowInfinity = true;
            }
        },
        async openSheetModalForWithdrawal(callback?: Function) {
            try {
                // await store.dispatch('setPaymentWithdrawal', undefined);
                // await this.requisitePs.fetchAllRequisites();
                // await FirebaseService.of().firestoreService?.fetchWithdrawalDocument();
                // await store.dispatch('fetchRates');
                // await this.requisitePs.checkActiveRequisite();
                // await this.requisitePs.fetchAddressTypes();

                this.openPopupNoAgents();
                return;

                // f7.view.main.router.navigate('/popup/withdrawal')
            } catch (e: any) {
                f7.dialog.alert(this.t('g.errors.alert.default-text'), this.t('g.errors.alert.default-title'));
            } finally {
                if (typeof callback === "function") callback();
                // this.btnWithdrawalLoading = false;
            }
        },
        openPopupNoAgents() {
            const el: HTMLDivElement = document.querySelector('.no-agents-sheet')!;
            const sheet = f7.sheet.create({
                el,
            });
            sheet.open();
        },
        async openSheetModalForNewPayment(callback?: Function) {
            const self = this;
            try {
                self.nextbtnLoading = true;
                await FirebaseService.of().firestoreService?.fetchPurchaseDocument();
                await this.routerService.toCreatePaymentPopup();
            } catch (e) {
                f7.dialog.alert(this.t("g.payments.alert.first"), this.t("g.payments.alert.second"), () => {
                });
            } finally {
                if (typeof callback === "function") callback();
                else this.nextbtnLoading = false;
            }
        },
        async eventInfinite() {
            if (this.allowInfinity) {
                this.allowInfinity = false;
                await this.fetchOperations({refresh: false});
            }
        },
        async eventPtrRefresh(done: any) {
            try {
                await this.vc.updateMyAccount();
                await this.vc.updateMyPayments();
                // await store.dispatch('fetchPaymentSystems', null);
                // await store.dispatch('fetchCurrencies', null);
                this.updateInfinityScroll();
                this.allowInfinity = true;
            } catch (e: any) {
                ErrorsService.of().handle(e);
            } finally {
                done();
            }
        },
        //TODO в будущем вынести в контроллер(переписать на единый стиль все toOperation)
        toOperationIncomeHandler(operation: PaymentIncomeOperation) {
            if (this.servicePaymentIncome) {
                this.servicePaymentIncome.income = operation;
                f7.views.current.router.navigate('/payment/income')
            }
        },
        async toOperationPurchaseHandler(operation: PurchaseOperation) {
            try {
                f7.preloader.show();
                if (!operation.msid) return;

                const modelOperation = await AppController.of()?.service<ServiceFetchOperation>("fetchOperation")
                    ?.fetchOperation({
                        type: OperationType.CLIENT_P2P_BUY,
                        msid: operation.msid
                    });

                AppController.of().paymentService.payment = modelOperation;

                f7.views.main.router.navigate('/payment');
            } catch (e) {
                await ErrorsService.of().handle(e);
            } finally {
                f7.preloader.hide();
            }
        },
        async toOperationWithdrawalHandler(operation: WithdrawalOperation) {
            try {
                f7.preloader.show();
                const modelOperation = await AppController.of()?.service<ServiceFetchOperation>("fetchOperation")
                    ?.fetchOperation({
                        type: OperationType.CLIENT_P2P_SELL,
                        msid: operation!.msid!
                    });
                AppController.of().withdrawalService.withdrawal = modelOperation;

                f7.views.main.router.navigate('/withdrawal');
            } catch (e: any) {
                ErrorsService.of().handle(e);
            } finally {
                f7.preloader.hide();
            }
        },
        async toOperationWithdrawalWalletHandler(operation: WithdrawalWalletOperation) {
            await this.routerService.toTransferOperationByMsid(operation.msid);
        }
    },
    unmounted() {
        this.vc.destructor();
    }
}
</script>

<style src="./index.scss" lang="scss"></style>
