import {ref, Ref} from "vue";
import PaymentIncomeOperation from "@models/operations/PaymentIncomeOperation";

export default class ServicePaymentIncome {
    private static _instance: ServicePaymentIncome | null = null;
    private _income: Ref<PaymentIncomeOperation | null> = ref(null);

    public static of() {
        if (ServicePaymentIncome._instance === null) {
            ServicePaymentIncome._instance = new ServicePaymentIncome();
        }

        return ServicePaymentIncome._instance;
    }

    public get income(): Ref<PaymentIncomeOperation | null> {
        return this._income;
    }

    public set income(value: PaymentIncomeOperation | null) {
        this._income.value = value;
    }
}
