import {getDevice} from "framework7/shared/get-device";
import LogService from "@/services/log-service/LogService";
import FirebaseFcmToken from "@models/firebase/FirebaseFcmToken";
import RouterService from "@/services/RouterService";
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import {FirebaseNotificationCordovaHandlePush} from "@/services/firebase/FirebaseNotificationCordovaHandlerPush";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import {plainToInstance} from "class-transformer";
import {validateOrReject} from "class-validator";
import FirebasePushNotificationPayload from "@models/firebase/push-notification/FirebasePushNotificationPayload";

export default class FirebaseNotificationCordovaPluginService {
    public fcmToken: string | null = null;
    private _handlerPush: FirebaseNotificationCordovaHandlePush;

    public constructor() {
        this._handlerPush = new FirebaseNotificationCordovaHandlePush();
    }

    public async initPushNotifications(funcUpdateDeviceTokens: Function, funcCheckAuth: Function) {
        try {
            const self = this;
            if (getDevice().cordova) {
                // @ts-ignore
                cordova.plugins.firebase.messaging.requestPermission({forceShow: true})
                    .then(async () => {
                        await self.retrieveAndSaveToken(funcUpdateDeviceTokens, funcCheckAuth);
                        await self.subscribeToSaveAndUpdateFcmToken(funcUpdateDeviceTokens, funcCheckAuth);
                        await self.subscribeBackgroundPush(funcCheckAuth);
                    })
            }
        } catch (e: any) {
        }
    }

    private async subscribeBackgroundPush(funcCheckAuth: Function) {
        try {
            // @ts-ignore
            cordova.plugins.firebase.messaging.onBackgroundMessage((payload: any) => {
                if (payload.operation) {
                    payload.operation = JSON.parse(payload.operation);
                }

                const payloadAsClass = plainToInstance(FirebasePushNotificationPayload, payload, {
                    excludeExtraneousValues: true,
                    excludePrefixes: ['_']
                });

                this._handlerPush.handle(funcCheckAuth, payloadAsClass);
            });
        } catch (e) {
            console.error(e)
        }
    }

    private async subscribeToSaveAndUpdateFcmToken(funcUpdateDeviceTokens: Function, funcCheckAuth: Function) {
        try {
            // @ts-ignore
            cordova.plugins.firebase.messaging.onTokenRefresh((newToken) => {
                localStorage.setItem("fcm-token", newToken);
                if (funcCheckAuth()) {
                    funcUpdateDeviceTokens([new FirebaseFcmToken(newToken)]);
                }
            });
        } catch (e) {

        }
    }

    private async retrieveAndSaveToken(funcUpdateDeviceTokens: Function, funcCheckAuth: Function) {
        try {
            if (!localStorage.getItem("fcm-token")) {
                // @ts-ignore
                const newToken = await cordova.plugins.firebase.messaging.getToken();
                localStorage.setItem("fcm-token", newToken);
                if (funcCheckAuth()) {
                    funcUpdateDeviceTokens([new FirebaseFcmToken(newToken)]);
                }
            }
        } catch (e: any) {
            LogService.of().error("FirebaseNotificationCordovaPluginService@checkAndSaveFcmToken", e.message);
        }
    }
}