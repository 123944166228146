import {Expose, Type} from "class-transformer";
import ProjectOperation from "@models/operations/components/ProjectOperation";
import Agent from "@models/operations/components/Agent";
import CurrencyOperation from "@models/operations/components/CurrencyOperation";
import RequisitesOperation from "@models/operations/components/RequisitesOperation";
import {OperationType_N} from "@enums/OperationType_N";
import {P_WITHDRAWAL_STATUS} from "@/entities/enums/PWithdrawalStatus";
import PaymentSystemOperation from "@models/operations/components/PaymentSystemOperation";
import ChatOperation from "@models/operations/components/ChatOperation";
import dayjs from "dayjs";

export default class WithdrawalOperation {
    @Expose({name: 'id'})
    private _id: number | null = null;

    @Expose({name: 'msid'})
    private _msid: string | null = null;

    @Expose({name: 'project'})
    @Type(() => ProjectOperation)
    private _project: ProjectOperation | null = null;

    @Expose({name: 'agent'})
    @Type(() => Agent)
    private _agent: Agent | null = null;

    @Expose({name: 'psystem'})
    @Type(() => PaymentSystemOperation)
    private _psystem: PaymentSystemOperation | null = null;

    @Expose({name: 'amount'})
    private _amount: number | null = null;

    @Expose({name: 'amountUsd'})
    private _amountUsd: number | null = null;

    @Expose({name: 'currency'})
    @Type(() => CurrencyOperation)
    private _currency: CurrencyOperation | null = null;

    @Expose({name: 'account'})
    private _account: string | null = null;

    @Expose({name: 'requisites'})
    @Type(() => RequisitesOperation)
    private _requisites: RequisitesOperation | null = null;

    @Expose({name: 'status'})
    private _status: P_WITHDRAWAL_STATUS | null = null;

    @Expose({name: 'chat'})
    @Type(() => ChatOperation)
    private _chat: ChatOperation | null = null;

    @Expose({name: 'operationType'})
    private _operationType: OperationType_N;

    @Expose({name: 'createdAt'})
    private _createdAt: string | null = null;

    @Expose({name: 'updatedAt'})
    private _updatedAt: string | null = null;

    get updatedAt(): string | null {
        return this._updatedAt;
    }

    get chat(): ChatOperation | null {
        return this._chat;
    }

    get psystem(): PaymentSystemOperation | null {
        return this._psystem;
    }

    get status(): P_WITHDRAWAL_STATUS | null {
        return this._status;
    }

    get requisites(): RequisitesOperation | null {
        return this._requisites;
    }

    get account(): string | null {
        return this._account;
    }

    get currency(): CurrencyOperation | null {
        return this._currency;
    }

    get amountUsd(): number | null {
        return this._amountUsd;
    }

    get amount(): number | null {
        return this._amount;
    }

    get agent(): Agent | null {
        return this._agent;
    }

    get project(): ProjectOperation | null {
        return this._project;
    }

    get msid(): string | null {
        return this._msid;
    }

    set msid(value: string | null) {
        this._msid = value;
    }

    get id(): number | null {
        return this._id;
    }

    get operationType(): OperationType_N {
        return this._operationType;
    }

    get createdAt(): string | null {
        return this._createdAt;
    }

    public getHumanCreateAt() {
        return dayjs(this._createdAt).format('DD.MM.YYYY HH:mm');
    }

    public resetChatUnreadCount() {
        if (this._chat) {
            this._chat.unread = 0;
        }
    }

    public incrementChatUnreadCount() {
        if (this._chat) {
            this._chat.unread++;
        }
    }
}
