<template>
    <transition
        mode="out-in"
        :enter-active-class="withTransition ? 'slide-in__top-block' : ''"
        :leave-active-class="withTransition ? 'slide-out__top-block' : ''"
    >
        <template v-if="payment && showSubnavbar">
            <div class="payment-view__subnavbar" :class="[{'justify-center': !payment.account}]">
                <template v-if="payment.account">
                    <div class="subnavbar-agent">
                        <p class="subnavbar-agent_name">{{ payment.account }}</p>
                        <p class="subnavbar-agent_online">
                            <template v-if="payment.agent?.isOnline">
                                <i class="icon green"></i>
                                <p>Online</p>
                            </template>
                            <template v-else>
                                <i class="icon red"></i>
                                <p>Offline</p>
                            </template>
                        </p>
                    </div>
                    <p class="subnavbar-chat" @click="toChat"
                       v-if="isShowChatButton">
                        <i class="icon chat-svg"></i>
                        {{ $t('g.chat-with-agent') }}
                        <i class="icon subnavbar-chat__mark" v-if="payment.chat && payment.chat.unread > 0"></i>
                    </p>
                </template>
                <template v-else>
                    <p class="subnavbar-text">{{ $t('payment.searching') }}</p>
                </template>
            </div>
        </template>
    </transition>
</template>

<script setup lang="ts">
import {computed, PropType, toRef, ref} from "vue";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import {ToRef} from "@vue/runtime-core";
import IFrameService from "@/services/iframe/IFrameService";
import UtilsService from "@/services/others/UtilsService";
import ServiceAccount from "@/services/v2/data/service-account/ServiceAccount";


const props = defineProps({
    payment: {
        type: Object as PropType<PurchaseOperation | null>,
        required: true
    },
    withTransition: {
        type: [Boolean] as PropType<boolean>,
        required: true
    },
});
const showSubnavbar: ToRef<boolean> = ref(false);

const withTransition: ToRef<boolean> = toRef(props, 'withTransition');
const emits = defineEmits(['clickBack', 'openInfoPopup', 'toChat']);
const account = ServiceAccount.of().account;
defineExpose({showSubnavbar});

const isShowChatButton = computed(() => {
    if (IFrameService.of().isIFrameView) return false;

    return props.payment && !account.value?.settings.isBanned;
});

function toChat() {
    if (props.payment?.chat === null) {
        UtilsService.of().openTelegramSupportChat();
        return;
    }

    emits('toChat');
}
</script>

<style scoped lang="scss">
.payment-view__subnavbar {
    background-color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: calc(var(--f7-safe-area-top) + 44px);
    height: 44px;
    z-index: 9999;
    //width: calc(100% - 32px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;

    &.justify-center {
        justify-content: center;
    }

    &::after {
        content: '';
        position: absolute;
        background-color: var(--grey4);
        display: block;
        z-index: 15;
        top: auto;
        right: auto;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 100%;
        transform-origin: 50% 100%;
        transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
    }

    .subnavbar-text {
        @include text(12px, 500, var(--grey2), 14.4px);
    }

    .subnavbar-agent {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;

        &_name {
            @include text(16px, 500, var(--brown1), 19.2px);
        }

        &_online {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            @include text(12px, 400, #818E95, 14.4px);

            i {
                width: 6px;
                height: 6px;
                border-radius: 50%;

                &.green {
                    background: #32BF61;
                }

                &.red {
                    background: #818E95;
                }
            }
        }
    }

    .subnavbar-chat {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 0 12px;
        border-radius: 100px;
        background-color: #fff;
        height: 27px;
        position: relative;
        cursor: pointer;
        border: 1px solid var(--blue1);
        @include text(12px, 500, var(--blue1), 27px);

        &.active-state {
            background-color: rgba(var(--f7-theme-color-rgb), 0.15);
        }

        &__mark {
            position: absolute;
            top: 0;
            right: 0;
            width: 8px;
            height: 8px;
            background-color: #FF3B30;
            border-radius: 50%;
        }

        .chat-svg {
            width: 14px;
            height: 14px;
            filter: invert(51%) sepia(79%) saturate(6886%) hue-rotate(201deg) brightness(103%) contrast(106%);
        }
    }
}
</style>
