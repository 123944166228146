<template>
    <f7-page ref="self" no-navbar class="withdrawal-create-page" @page:beforein="PAGE_BEFORE_IN">
        <transition name="fade" mode="out-in">
            <div>
                <div class="title-wrapper">
                    <p class="title">{{ $t('withdrawal-create.title') }}</p>
                </div>
                <f7-list class="amount-wrapper list-strong list-outline">
                    <f7-block-header class="display-flex justify-content-space-between">
                        <span class="title">{{ $t('withdrawal-create.amount-wrapper.title') }}</span>
                    </f7-block-header>
                    <f7-list-input
                        ref="input-amount"
                        class="input-amount"
                        type="text"
                        inputmode='decimal'
                        v-model:value="amount"
                        @focus="amountInputFocus"
                        @blur="amountInputBlur"
                        :class="{'focused': inputFocused}"
                        v-format-number
                    >
                        <template #content>
                            <template v-if="activeRequisite?.psystem.targetType === RequisitePsystemTargetType.P2P">
                                <div class="content" @click="changeInputCurrency">
                                  <span class="currency-name">
                                      <template v-if="inputCurrency === 'RUB'">{{
                                              account.currency.name
                                          }}</template>
                                      <template v-else>USD</template>
                                  </span>
                                </div>
                            </template>
                            <div class="content" @click="clickMax">
                                <span class="max-amount">MAX</span>
                            </div>
                        </template>
                    </f7-list-input>
                    <div class="convert-row block">
                        <template v-if="amountErrors.max && activeRequisiteMinMaxData && activeRequisiteMinMaxData.max">
                            <p @click="setValidAmountWithFeeForWalletRequisite" ref="amount-error" class="amount-error"
                               v-html="t('withdrawal-create.errors.amount.max')"></p>
                        </template>
                        <template
                            v-else-if="amountErrors.min && activeRequisiteMinMaxData && activeRequisiteMinMaxData.min">
                            <p ref="amount-error" class="amount-error"
                               v-html="t('withdrawal-create.errors.amount.min') + ' ' +  activeRequisiteMinMaxData.min + '$'"></p>
                        </template>
                        <template v-else-if="amountErrors.balance">
                            <p ref="amount-error" class="amount-error"
                               v-html="t('withdrawal-create.errors.amount.balance')"></p>
                        </template>
                        <template v-else>
                            <p class="text">≈ <span>{{ usdt }}</span></p>
                        </template>
                    </div>
                </f7-list>

                <div class="requisite-wrapper" @click="changePSystem">
                    <div class="requisite-wrapper_title">
                        <span class="title">{{ $t('withdrawal-create.requisite-wrapper.title') }}</span>
                        <div class="requisite-wrapper_minmax-wrapper">
                            <Transition name="fade" mode="out-in">
                                <template v-if="activeRequisiteMinMaxLoading">
                                    <div class="min-max-loader center"><span></span></div>
                                </template>
                                <template v-else>
                                    <template v-if="canShowMinMaxAgentRanges">
                                    <span class="minmax">
                                        {{ activeRequisiteMinMaxData!.min }}{{
                                            activeRequisiteMinMaxData!.currency!.abbr
                                        }} -
                                        {{ activeRequisiteMinMaxData!.max }}{{
                                            activeRequisiteMinMaxData!.currency!.abbr
                                        }}</span>
                                    </template>
                                </template>
                            </Transition>
                        </div>
                    </div>
                    <div class="requisite">
                        <template v-if="activeRequisite">
                            <div class="left-side">
                                <img :src="activeRequisite.psystem.imgPath">
                                <p class="name">{{ activeRequisite?.psystem?.name }}
                                    <span class="address">{{ makeShortAddress(activeRequisite.address) }}</span>
                                </p>
                            </div>
                            <i class="icon svg-arrow-right"></i>
                        </template>
                        <template v-else>
                            <div class="dimmy-requisite">
                                <p>{{ $t('withdrawal-create.requisite-wrapper.to') }}</p>
                                <div class="after">
                                    <i ref="psystem-alert" class="icon hidden i-svg-warning-round-red"></i>
                                    <i class="icon svg-arrow-right"></i>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div ref="psystem-error" class="psystem-error hidden">
                        <span v-if="!activeRequisite">{{ $t('withdrawal-create.psystem-error') }}</span>
                    </div>
                </div>

                <f7-block class="actions">
                    <f7-button
                        class="theme-type"
                        preloader
                        :loading="nextBtnLoading"
                        @click="handleSubmit"
                    >
                        {{ $t('withdrawal-create.btn-next') }}
                    </f7-button>
                </f7-block>
            </div>
        </transition>
    </f7-page>
</template>

<script lang="ts">
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import {f7} from 'framework7-vue';
import {defineComponent, reactive, Ref, ref, ToRef} from 'vue';
import WithdrawalPopupController from '../WithdrawalPopupController';
import {useI18n} from 'vue-i18n';
import Animations from '@/helpers/animations';
import {formatText} from "@/targets/main/core/directives";
import RouterService from "@/services/RouterService";
import {PageOpenIn} from "@enums/PageOpenIn";
import GlobalRequisitesController from "@/views/requisites-group/GlobalRequisitesController";
import Requisite from "@models/requisite/Requisite";
import {RequisitePsystemTargetType} from "@enums/RequisitePsystemTargetType";
import WithdrawalService from "@/services/operations/withdrawal/WithdrawalService";
import CreateWalletWithdrawalDto from "@/services/operations/withdrawal/dto/CreateWalletWithdrawalDto";
import {getDevice} from "framework7/shared/get-device";
import ErrorsService from "@/services/errors-service/ErrorsService";
import AgentsAmountRanges from "@models/errors/components/AgentsAmountRanges";
import ServiceAccount from "@/services/v2/data/service-account/ServiceAccount";
import ModelAccount from "@models/v2/account/ModelAccount";
import ServiceRates from "@/services/v2/data/service-rates/ServiceRates";
import ModelRates from "@models/v2/rates/ModelRates";

export default defineComponent({
    setup() {
        const routerService: RouterService = AppController.getInstance().routerService;
        const ps = WithdrawalPopupController.getInstance();
        // const vcFA = FindAgentController.getInstance();
        const requisitePs = GlobalRequisitesController.getInstance();
        const {amount, inputCurrency, amountErrors} = ps;
        const {activeRequisite, activeRequisiteAmountRange} = requisitePs;
        const currencySign = ServiceAccount.of().accountCurrencyAbbr;
        const {t} = useI18n({useScope: 'global'});
        const account = ServiceAccount.of().account as Ref<ModelAccount>;
        const rates = ServiceRates.of().rates as Ref<ModelRates>;
        const nextBtnLoading = ref(false);
        const validateController = reactive({
            psystemError: false
        });
        const inputFocused = ref(false);
        const withdrawalService: WithdrawalService = AppController.getInstance().withdrawalService;
        const activeRequisiteMinMaxLoading = ref(false);
        const activeRequisiteMinMaxData: ToRef<AgentsAmountRanges | null> = ref(null);
        const minValue = ref(1);

        function formatPrettyNumber(text: string): string {
            let regex = new RegExp(currencySign.value, 'g');
            const sign = text.match(regex);
            text = text.replace(/\s/g, '');
            const parts = text.toString().split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

            let result = parts.join(".");
            if (sign) {
                let regex = new RegExp(sign[0], 'g');
                result = result.replace(regex, ` ${sign[0]}`);
            }
            return result;
        }

        return {
            minValue,
            activeRequisiteMinMaxLoading,
            activeRequisiteMinMaxData,
            amountErrors,
            withdrawalService,
            routerService,
            validateController,
            account,
            inputCurrency,
            formatPrettyNumber,
            // vcFA,
            activeRequisiteAmountRange,
            rates,
            activeRequisite,
            requisitePs,
            amount,
            currencySign,
            ps,
            t,
            nextBtnLoading,
            inputFocused
        }
    },
    computed: {
        canShowMinMaxAgentRanges() {
            return this.activeRequisiteMinMaxData
                && this.activeRequisiteMinMaxData.currency
                && this.activeRequisiteMinMaxData.max! > 0
        },
        RequisitePsystemTargetType() {
            return RequisitePsystemTargetType
        },
        textMaxMin() {
            const {min, max} = this.activeRequisiteAmountRange!;
            if (max === 0) return '';
            return `${this.formatPrettyNumber(min.toString())} $ - ${this.formatPrettyNumber(max.toString())} $`;
        },
        usdt() {
            if (this.activeRequisite) {
                if (this.activeRequisite.psystem.targetType === RequisitePsystemTargetType.P2P) {
                    if (this.inputCurrency === this.account.currency.name) {
                        if (this.amount === '' || parseInt(this.amount) === 0) {
                            return 0 + ` ${this.account.currency.abbr}`;
                        } else {
                            const amount = parseFloat(this.amount.replaceAll(/\s+/g, ''));
                            const result = (amount * this.rates.sell).toFixed(0) + ` ${this.account.currency.abbr}`;
                            return formatText(result)
                        }
                    } else {
                        if (this.amount === '' || parseFloat(this.amount) === 0) {
                            return 0 + " $";
                        } else {
                            const resultNum = +(parseFloat(this.amount.replaceAll(/\s+/g, '')) / this.rates.sell).toFixed(2);
                            const resultStr = ((Number.isInteger(resultNum)) ? resultNum.toFixed(0) : resultNum.toFixed(2));
                            return formatText(resultStr + " $");
                        }
                    }
                } else {
                    if (this.amount === '' || parseFloat(this.amount) === 0) {
                        return 0 + " USDT";
                    } else {
                        const amount = parseFloat(this.amount.replaceAll(/\s+/g, ''));
                        return formatText(amount + " USDT");
                    }
                }
            } else {
                if (this.amount === '' || parseInt(this.amount) === 0) {
                    if (this.inputCurrency === "RUB") {
                        return 0 + ` ${this.account.currency.abbr}`;
                    } else {
                        return 0 + " $";
                    }
                } else {
                    if (this.inputCurrency === "RUB") {
                        const amount = parseFloat(this.amount.replaceAll(/\s+/g, ''));
                        const result = (amount * this.rates.sell).toFixed(0) + ` ${this.account.currency.abbr}`;
                        return formatText(result)
                    } else {
                        const resultNum = +(parseFloat(this.amount.replaceAll(/\s+/g, '')) / this.rates.sell).toFixed(2);
                        const resultStr = ((Number.isInteger(resultNum)) ? resultNum.toFixed(0) : resultNum.toFixed(2));
                        return formatText(resultStr + " $");
                    }
                }
            }

        }
    },
    watch: {
        async activeRequisite(newValue: any, oldValue: any) {
            this.clearErrors();
            await this.fetchRequisiteMinMax(newValue);
            if (this.activeRequisiteMinMaxData) this.checkInputValidAgentRanges(this.amount, this.activeRequisiteMinMaxData);
            // this.handleWalletRequisiteAmount(this.amount);
        },
        amount(newValue: string, oldValue: string) {
            this.clearErrors();
            // if (this.activeRequisite?.psystem.targetType === RequisitePsystemTargetType.WALLET) {
            //     this.handleWalletRequisiteAmount(newValue);
            // } else {
            //     this.handleP2PRequisiteAmount(newValue, oldValue);
            // }
        }
    },
    unmounted() {
        if (this.activeRequisite) {
            this.activeRequisite = null;
        }
    },
    methods: {
        checkInputValidAgentRanges(inputValue: string, agentsAmountRanges: AgentsAmountRanges) {
            const inputNumber = +parseFloat(inputValue.length > 0 ? inputValue : '0').toFixed(2);

            if (agentsAmountRanges.min && inputNumber < agentsAmountRanges.min) {
                this.amountErrors.min = true;
                setTimeout(() => this.animateErrorText(), 0);
                return;
            }
            if (agentsAmountRanges.max && inputNumber > agentsAmountRanges.max) {
                this.amountErrors.max = true;
                setTimeout(() => this.animateErrorText(), 0);
                return;
            }
        },
        async fetchRequisiteMinMax(requisite: Requisite) {
            try {
                this.activeRequisiteMinMaxLoading = true;
                this.activeRequisiteMinMaxData = await this.requisitePs.fetchRequisiteMinMax(requisite);
                this.checkRequisiteMinMaxAndSetInput();
            } catch (e: any) {
                ErrorsService.of().handle(e);
            } finally {
                this.activeRequisiteMinMaxLoading = false;
            }
        },
        checkRequisiteMinMaxAndSetInput() {
            if (!this.activeRequisiteMinMaxData) return;

            const inputNumber = +parseFloat(this.amount.length > 0 ? this.amount : '0').toFixed(2);

            this.inputCurrency = "RUB";

            if (this.activeRequisiteMinMaxData.min && inputNumber < this.activeRequisiteMinMaxData.min) {
                this.amount = (this.activeRequisiteMinMaxData.min).toFixed(0) + `$`;
                return;
            }
            if (this.activeRequisiteMinMaxData.max && inputNumber > this.activeRequisiteMinMaxData.max) {
                this.amount = (this.activeRequisiteMinMaxData.max).toFixed(0) + `$`;
                return;
            }
        },
        setValidAmountWithFeeForWalletRequisite() {
            const {balance} = this.account;
            const sing = this.inputCurrency === "USDT" ? this.currencySign : "$";
            const value = Math.max((balance!.amount - 0.5), 0);
            this.amount = value == 0 ? "0" : value.toFixed(2) + sing;
        },
        handleWalletRequisiteAmount(newValue: string): boolean {
            const self = this;
            const {balance} = this.account;
            const value = +parseFloat(newValue.length > 0 ? newValue : '0').toFixed(2);
            const amountDollars = this.getAmountLikeDollars();

            clearErrors();
            if (!checkBalance(value, this.inputCurrency === "USDT" ? balance!.amountC : balance!.amount)) return false;
            if (!checkMinAmount(amountDollars)) return false;
            if (typeof this.activeRequisite?.psystem.targetType !== "undefined") {
                if (this.activeRequisite?.psystem.targetType === RequisitePsystemTargetType.WALLET) {
                    if (!checkFee(value, balance!.amount)) return false;
                }
            }

            return true;

            function checkBalance(value: number, balance: number): boolean {
                if (value > balance) {
                    self.amountErrors.balance = true;
                    setTimeout(() => {
                        animateErrorText();
                    }, 0);
                    return false;
                }
                return true;
            }

            function checkFee(value: number, balance: number): boolean {
                const amountWithFee = +(value + 0.5).toFixed(2);
                if (amountWithFee > balance) {
                    self.amountErrors.max = true;
                    setTimeout(() => {
                        animateErrorText();
                    }, 0);
                    return false;
                }
                return true;
            }

            function checkMinAmount(value: number): boolean {
                if (self.activeRequisiteMinMaxData && self.activeRequisiteMinMaxData?.min) {
                    self.minValue = self.activeRequisiteMinMaxData.min;
                } else {
                    self.minValue = 5;
                }
                if (value < self.minValue) {
                    self.amountErrors.min = true;
                    setTimeout(() => {
                        animateErrorText();
                    }, 0);
                    return false;
                }
                return true;
            }

            function animateErrorText() {
                // @ts-ignore
                const el = self.$refs["amount-error"] as HTMLElement;
                if (el) Animations.shakeAnimation(el);
            }

            function clearErrors(): void {
                self.amountErrors.min = false;
                self.amountErrors.max = false;
                self.amountErrors.balance = false;
            }
        },
        animateErrorText() {
            // @ts-ignore
            const el = this.$refs["amount-error"] as HTMLElement;
            if (el) Animations.shakeAnimation(el);
        },
        clearErrors(): void {
            this.amountErrors.min = false;
            this.amountErrors.max = false;
            this.amountErrors.balance = false;
        },
        handleP2PRequisiteAmount(newValue: string, oldValue: string) {
            let re = /[^0-9.,$₽]/gi;

            this.amountErrors.min = false;
            this.amountErrors.max = false;
            this.amountErrors.balance = false;

            if (this.inputCurrency === "USDT") {
                this.amount = newValue.replace(re, '').replace(',', '').replace('.', '');
            } else {
                if (newValue.slice(-1) === ',' || newValue.slice(-1) === '.') {
                    if (oldValue.length === 0) {
                        this.amount = newValue.replace(',', '').replace('.', '');
                        return;
                    }
                    if (/^[0-9]*[.,][0-9]*$/.test(oldValue)) {
                        this.amount = newValue.slice(0, -1);
                    }
                    return;
                }
                this.amount = newValue.replace(re, '').replace(',', '.');
            }
        },
        closePopup() {
            // @ts-ignore
            const self = this.$refs['self'].$el;
            const popupEl: HTMLDivElement = self.closest('.withdrawal-popup')!;
            f7.popup.get(popupEl).close();
        },
        changeInputCurrency() {
            if (this.inputCurrency === "RUB") {
                this.inputCurrency = "USDT";
                if (this.amount.length !== 0) {
                    const amount = parseFloat(this.amount.replaceAll(/\s+/g, ''));
                    this.amount = (amount * this.rates.sell).toFixed(0) + `${this.account.currency.abbr}`;
                }
            } else {
                this.inputCurrency = this.account.currency.name;
                if (this.amount.length !== 0) {
                    const amount = parseFloat(this.amount.replaceAll(/\s+/g, ''));
                    if (amount !== 0) {
                        const resultNum = +(amount / this.rates.sell).toFixed(2);
                        this.amount = ((Number.isInteger(resultNum)) ? resultNum.toFixed(0) : resultNum.toFixed(2)) + "$";
                    } else {
                        this.amount = 0 + "$";
                    }
                }
            }
        },
        makeShortAddress(value: string) {
            let result = value.slice(-4);
            return '*'.concat(result);
        },
        PAGE_BEFORE_IN() {
            const popupHTML = document.querySelector('.withdrawal-popup');
            (popupHTML as HTMLDivElement).classList.remove('height-100');

            // this.ps.updateAmount();
            this.handleCurrencyForActiveRequisite();

            // if (this.activeRequisite?.psystem.targetType === RequisitePsystemTargetType.WALLET) {
            //     this.handleWalletRequisiteAmount(this.amount);
            // }
        },
        handleCurrencyForActiveRequisite() {
            if (typeof this.activeRequisite?.psystem.targetType !== "undefined") {
                if (this.activeRequisite?.psystem.targetType === RequisitePsystemTargetType.WALLET) {
                    this.inputCurrency = "$";
                } else {
                    this.inputCurrency = "USDT";
                }
                this.changeInputCurrencyForActiveRequisite();
            }
        },
        changeInputCurrencyForActiveRequisite() {
            if (this.inputCurrency === this.account.currency.name) {
                this.inputCurrency = "USDT";
                if (this.amount.length !== 0) {
                    const amount = parseFloat(this.amount.replaceAll(/\s+/g, ''));
                    this.amount = (amount).toFixed(0) + `${this.account.currency.abbr}`;
                }
            } else {
                this.inputCurrency = this.account.currency.name;
                if (this.amount.length !== 0) {
                    const amount = parseFloat(this.amount.replaceAll(/\s+/g, ''));
                    if (amount !== 0) {
                        const amount = parseFloat(this.amount.replaceAll(/\s+/g, ''));
                        const resultNum = +(amount).toFixed(2);
                        this.amount = ((Number.isInteger(resultNum)) ? resultNum.toFixed(0) : resultNum.toFixed(2)) + "$";
                    } else {
                        this.amount = 0 + "$";
                    }

                }
            }
        },
        clickMax() {
            let max = this.account.balance!.amount;
            if (this.inputCurrency === this.account.currency.name) {
                this.amount = max.toFixed(max === 0 ? 0 : 2) + ` $`;
            } else {
                if (this.activeRequisite) {
                    if (this.activeRequisite?.psystem.targetType === RequisitePsystemTargetType.P2P) {
                        this.amount = (max * this.rates.sell).toFixed(0) + ` ${this.currencySign}`;
                    } else {
                        this.amount = max.toFixed(max === 0 ? 0 : 2) + ` $`;
                    }
                } else {
                    this.amount = max.toFixed(max === 0 ? 0 : 2) + ` $`;
                }
            }
        },
        openPopupNoAgents() {
            const el: HTMLDivElement = document.querySelector('.no-agents-sheet')!;
            const sheet = f7.sheet.create({
                el,
            });
            sheet.open();
        },
        closeKeyboardFocusInput(callback: Function) {
            // @ts-ignore
            const inputEl: HTMLInputElement = (this.$refs['input-amount'].$el as HTMLElement)!;
            if (getDevice().cordova && inputEl.classList.contains("focused")) {
                inputEl.blur();
                setTimeout(async () => {
                    await callback();
                }, 500);
                return false;
            }
            callback();
        },
        async handleSubmit() {
            this.closeKeyboardFocusInput(async () => {
                await this.next()
            });
        },
        async next() {
            try {
                if (this.amount === "") return;

                const amount = parseFloat(this.amount.replaceAll(/\s+/g, ''));
                const resultNum = (amount).toFixed(2);
                if (!this.handleWalletRequisiteAmount(resultNum)) return false;

                this.nextBtnLoading = true;

                if (this.validateDataForCreatePayment()) {
                    if ((this.activeRequisite as Requisite).psystem.targetType === RequisitePsystemTargetType.P2P) {
                        await this.ps.create2P2Withdrawal();
                        f7.view.main.router.navigate('/withdrawal');
                    } else {
                        // const withdrawal = await this.withdrawalService.createWalletWithdrawal();
                        this.openFullSizePopup();
                        await this.routerService.toWithdrawalWalletCreatedPage(new CreateWalletWithdrawalDto(
                            this.ps.extractAmount(),
                            this.activeRequisite as Requisite,
                            (this.activeRequisite as Requisite).psystem
                        ), PageOpenIn.POPUP);
                    }
                }
            } catch (e: any) {
                this.closePopup();
                ErrorsService.of().handle(e);
            } finally {
                this.nextBtnLoading = false;
            }
        },
        openFullSizePopup() {
            const popupHTML = document.querySelector('.withdrawal-popup');
            (popupHTML as HTMLDivElement).classList.add('height-100');
        },
        validateDataForCreatePayment() {
            if (this.activeRequisite == null) {
                const alertIcon = this.$refs['psystem-alert'] as HTMLElement;
                const hintPsystem = this.$refs['psystem-error'] as HTMLElement;
                alertIcon.classList.remove('hidden');
                hintPsystem.classList.remove('hidden');
                Animations.shakeAnimation(alertIcon);
                Animations.shakeAnimation(hintPsystem);
                return false;
            }

            return true;
        },
        async changePSystem() {
            this.closeKeyboardFocusInput(async () => {
                const alertIcon = this.$refs['psystem-alert'] as HTMLElement;
                const hintPsystem = this.$refs['psystem-error'] as HTMLElement;
                if (alertIcon) {
                    alertIcon.classList.add('hidden');
                }
                hintPsystem.classList.add('hidden');

                const popupHTML = document.querySelector('.withdrawal-popup');
                f7.preloader.show();

                if (this.activeRequisite || this.requisitePs.requisites.value.length > 0) {
                    await this.routerService.toRequisitesPage(PageOpenIn.POPUP, function () {
                        (popupHTML as HTMLDivElement).classList.add('height-100');
                        f7.preloader.hide();
                    });

                    return;
                }


                try {
                    f7.preloader.show();
                    await this.requisitePs.fetchPSystems();
                    await this.requisitePs.fetchAddressTypes();
                    this.requisitePs.initRequisitePayload();

                    await this.routerService.toRequisitesPage(PageOpenIn.POPUP, function () {
                        (popupHTML as HTMLDivElement).classList.add('height-100');
                    });
                    // this.requisitePs.setOpenIn(OpenIn.popup);
                    //f7.view.current.router.navigate('/settings/requisites/select-psystem');
                    // f7.view.current.router.navigate('/settings/requisites/requisite');
                } catch (e: any) {
                    f7.dialog.alert(this.t('g.errors.alert.default-text'), this.t('g.errors.alert.default-title'));
                } finally {
                    f7.preloader.hide();
                }
            });
        },
        amountInputFocus() {
            this.inputFocused = true;
            this.amount = '';
        },
        getAmountLikeDollars() {
            let result = null;
            if (this.ps.inputCurrency.value === "USDT") {
                result = +(
                    (Math.ceil(
                        parseInt(this.amount.replaceAll(/\s+/g, '')
                        )) / this.rates.sell)
                ).toFixed(2);
            } else {
                result = parseFloat(this.amount.replaceAll(/\s+/g, ''))
            }
            if (result == null) {
                return 0;
            }
            return result;
        },
        amountInputBlur(e: Event) {
            let timeout = 0;
            if (getDevice().cordova) timeout = 314;
            setTimeout(() => {
                this.inputFocused = false;
            }, timeout);

            const targetElement = e.target as HTMLInputElement;
            if (targetElement.value.toString().length === 0) {
                if (this.inputCurrency === this.account.currency.name) {
                    this.amount = 0 + ` $`;
                } else {
                    this.amount = 0 + ` ${this.currencySign}`;
                }
            } else {
                if (this.inputCurrency === this.account.currency.name) {
                    this.amount = targetElement.value + ` $`;
                } else {
                    this.amount = targetElement.value + ` ${this.currencySign}`;
                }
            }
        },
    }
});
</script>

<style lang="scss">
.withdrawal-create-page {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    --f7-navbar-height: 0px;

    .page-content {
        --f7-page-navbar-offset: 0;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .title-wrapper {
        margin: 24px 16px;

        .title {
            font-weight: 700;
            font-size: 24px;
            line-height: 26px;
            letter-spacing: 0.337647px;
            color: #000000;
        }
    }

    .requisite-wrapper {
        margin-top: 24px;

        &_title {
            display: flex;
            margin-bottom: 10px;
            justify-content: space-between;
            margin-right: 16px;
        }

        .requisite-wrapper_minmax-wrapper {
            height: 12px;
            display: flex;

            .minmax {
                @include text(12px, 400, var(--grey2));
                height: 12px;
            }

            .min-max-loader {
                position: relative;
                display: block;
                height: 14px;
                width: 14px;
            }

            .min-max-loader span {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                height: 14px;
                width: 14px;
                clip: rect(4px, 14px, 14px, 0);
                -webkit-animation: min-max-loader-3 0.7s linear infinite;
                animation: min-max-loader-3 0.7s linear infinite;
            }

            @-webkit-keyframes min-max-loader-3 {
                0% {
                    -webkit-transform: rotate(0deg);
                }
                100% {
                    -webkit-transform: rotate(360deg);
                }
            }
            @keyframes min-max-loader-3 {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }

            .min-max-loader span::after {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                height: 10px;
                width: 10px;
                border: 1px solid var(--grey2);
                border-radius: 50%;
            }
        }

        .title {
            margin: 0px 16px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14.4px;
            text-transform: uppercase;
            color: #818E95;
        }

        .title + .requisite {
            margin-top: 8px;
        }

        .requisite {
            cursor: pointer;
            position: relative;
            height: 48px;
            display: flex;
            background-color: #FFFFFF;
            //width: calc(100% - 32px);
            align-items: center;
            padding: 0px 16px;

            &:after {
                content: '';
                position: absolute;
                background-color: var(--f7-list-border-color);
                display: block;
                z-index: 15;
                top: auto;
                right: auto;
                bottom: 0;
                left: 0;
                height: 1px;
                width: 100%;
                transform-origin: 50% 100%;
                transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
            }

            &:before {
                content: '';
                position: absolute;
                background-color: var(--f7-list-border-color);
                display: block;
                z-index: 15;
                top: 0;
                right: auto;
                bottom: auto;
                left: 0;
                height: 1px;
                width: 100%;
                transform-origin: 50% 0%;
                transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
            }

            .left-side {
                display: flex;
                align-items: center;
                flex: 1;
                gap: 8px;

                img {
                    width: 24px;
                    height: 24px;
                }

                .name {
                    @include text(16px, 400, var(--brown1), 19.2px);

                    .address {
                        @include text(16px, 400, #818E95, 19.2px);
                    }
                }
            }

            .dimmy-requisite {
                display: flex;
                align-items: center;
                flex: 1;
                justify-content: space-between;

                p {
                    @include text(16px, 400, #BFBFBF);
                }

                .after {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;
                }
            }
        }

        .psystem-error {
            display: flex;
            padding: 6px 16px 0px;
            min-height: 14px;
            @include text(12px, 400, #FF3B30, 14px);
            width: fit-content;
        }
    }

    .amount-wrapper {
        margin-top: 12px;

        .amount-error {
            @include text(12px, 400, var(--red1), 14px);

            span {
                cursor: pointer;
                color: var(--blue1);
            }
        }

        .block-header {
            .title {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 120%;
                color: #818E95;
            }

            .minmax-wrapper {
                align-self: flex-end;

                .minmax {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 120%;
                    color: #818E95;
                    // margin-bottom: 8px;
                }
            }
        }

        .input-amount {
            input {
                height: 62px !important;
                font-weight: 400 !important;
                font-size: 26px !important;
                line-height: 120% !important;
                letter-spacing: -0.41px !important;

                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
            }

            .content {
                height: 62px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 16px;
                cursor: pointer;

                .max-amount {
                    margin-left: 16px;
                    color: var(--f7-theme-color);
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 20px;
                }

                .currency-name {
                    margin-left: 16px;
                    color: var(--f7-theme-color);
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 20px;
                }
            }
        }

        .message-error {
            margin-top: 56px;
        }
    }

    .convert-row {
        margin-top: 8px;
        margin-bottom: 0px;
        height: 12px;

        .text {
            @include text(12px, 400, var(--grey2));
        }
    }

    .actions {
        margin-top: 20px;
        margin-bottom: 16px;

        .button {

            height: 46px;
        }
    }
}
</style>
