<template>
    <div class="withdrawal__status accepted">
        <template v-if="requisites">
            <p class="withdrawal__status_text">{{ $t('withdrawal.statuses.accepted.help-text1') }}</p>
            <div class="withdrawal__requisites">
                <WithdrawalCard
                    :withdrawal="withdrawal!"
                />
            </div>
            <transition-group name="fade" mode="out-in">
                <WithdrawalRequisiteComment key="1" :comment="requisites.comment"/>
            </transition-group>
            <WithdrawalMeta :withdrawal="withdrawal"/>
            <WithdrawalCancelOperationHint />
        </template>
    </div>
</template>

<script setup lang="ts">
import {computed, PropType, ref, toRef, UnwrapRef, watch} from "vue";
import {ToRef} from "@vue/runtime-core";
import WithdrawalContentHeader from "@/views/withdrawal-group/withdrawal/components/atomic/WithdrawalContentHeader.vue";
import WithdrawalOperation from "@models/operations/WithdrawalOperation";
import WithdrawalCard from "@/views/withdrawal-group/withdrawal/components/atomic/WithdrawalCard.vue";
import WithdrawalRequisiteComment
    from "@/views/withdrawal-group/withdrawal/components/atomic/WithdrawalRequisiteComment.vue";
import WithdrawalMeta from "@/views/withdrawal-group/withdrawal/components/atomic/WithdrawalMeta.vue";
import WithdrawalCancelOperationHint
    from "@/views/withdrawal-group/withdrawal/components/atomic/WithdrawalCancelOperationHint.vue";
import PaymentViewRequisites from "@/views/payment/components/PaymentViewRequisites.vue";
import WithdrawalViewRequisites from "@/views/withdrawal-group/withdrawal/components/WithdrawalViewRequisites.vue";

enum TIMER_STATUS_TYPE {
    DEFAULT = "DEFAULT",
    WARNING = "WARNING",
    ALERT = "ALERT"
}

const setTimerColors = {
    DEFAULT: "#1890FF",
    WARNING: "#E69700",
    ALERT: "#FF3B30"
};

const props = defineProps({
    withdrawal: {
        type: Object as PropType<WithdrawalOperation>,
        required: true
    },
    timeText: {
        type: [String, null] as PropType<string | null>,
        required: true
    },
    percent: {
        type: [Number] as PropType<number>,
        required: true
    }
});

const withdrawal: ToRef<WithdrawalOperation> = toRef(props, 'withdrawal');
const requisites = computed(() => withdrawal.value!.requisites);
const percent: ToRef<number> = toRef(props, 'percent');
const timeText: ToRef<string | null> = toRef(props, 'timeText');
const buyingIsAvailable = ref(true);
const timerBorderColor = ref(setTimerColors[TIMER_STATUS_TYPE.DEFAULT]);
const timerTextColor = ref(setTimerColors[TIMER_STATUS_TYPE.DEFAULT]);

detectBuyingIsAvailable(timeText.value);

watch(timeText, (value) => {
    if (value) {
        detectBuyingIsAvailable(value);
    }
});

const emits = defineEmits(['cancelPayment']);

function cancelPayment() {
    emits('cancelPayment');
}

function detectBuyingIsAvailable(value: string | null) {
    if (value === null) {
        buyingIsAvailable.value = false;
        return;
    }

    const minute = +value.split(':')[0];
    const seconds = +value.split(':')[1];

    if (minute <= 4 && seconds < 59) {
        timerBorderColor.value = setTimerColors[TIMER_STATUS_TYPE.WARNING];
        timerTextColor.value = setTimerColors[TIMER_STATUS_TYPE.WARNING];
    } else if (minute == 0 && seconds <= 59) {
        timerBorderColor.value = setTimerColors[TIMER_STATUS_TYPE.ALERT];
        timerTextColor.value = setTimerColors[TIMER_STATUS_TYPE.ALERT];
    } else if (minute < 0) {
        buyingIsAvailable.value = false;
    }
}

</script>

<style src="./index.scss"></style>
<style lang="scss">
.withdrawal__status {
    &_text {
        @include text(14px, 400, var(--brown1), 16.8px);
        margin-bottom: 16px;
    }
    &.accepted {
        padding-top: 20px;

        .withdrawal__requisites {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .requisites {
                &_title {
                    @include text(16px, 700, var(--brown1), 19.2px);
                }
            }
        }

        .cancel {
            cursor: pointer;
            margin-top: 32px;
            font-style: normal;
            font-weight: 500;
            font-size: 17px;
            line-height: 22px;
            letter-spacing: -0.41px;
            color: #FF3B30;
            display: block;
            text-align: center;
        }
    }
}
</style>
