import i18n from "@/langs/i18n";
import Keyable from "@/interfaces/Keyable";
import { Operation } from "@/repositories/v2/repository-operations/types";

export default class Helper {
    private static _instance: Helper | null = null;

    public static getInstance() {
        if (this._instance === null) this._instance = new Helper();
        return this._instance;
    }

    public getPaymentStatusText(status: string) {
        let result = 'Undefined';
        switch (status) {
            case 'CASHED':
                // @ts-ignore
                result = i18n.global.t("g.payments.statuses.CASHED");
                break;
            case 'ONAPPEAL':
                result = i18n.global.t("g.payments.statuses.ONAPPEAL");
                break;
            case 'CONFIRMED':
                result = i18n.global.t("g.payments.statuses.CONFIRMED");
                break;
            case 'CREATED':
                result = i18n.global.t("g.payments.statuses.CREATED");
                break;
            case 'ACCEPTED':
                result = i18n.global.t("g.payments.statuses.ACCEPTED");
                break;
            case 'REJECTED':
                result = i18n.global.t("g.payments.statuses.REJECTED");
                break;
            case 'DISPUTED':
                result = i18n.global.t("g.payments.statuses.DISPUTED");
                break;
            case 'DECLINED':
                result = i18n.global.t("g.payments.statuses.DECLINED");
                break;
            case 'EXPIRED':
                result = i18n.global.t("g.payments.statuses.EXPIRED");
                break;
            case 'CANCELED':
                result = i18n.global.t("g.payments.statuses.CANCELED");
                break;
            case 'COMPLETED':
                result = i18n.global.t("g.payments.statuses.COMPLETED");
                break;
            case 'ACTIVATED':
                result = i18n.global.t("g.payments.statuses.ACTIVATED");
                break;
        }
        return result;
    }

    public convertToHumanPaymentStatus(operations: Operation) {
        let result = 'Undefined';
        switch (operations.status) {
            case 'EMPTY':
                result = i18n.global.t("g.payments.statuses.EMPTY");
                break;
            case 'CASHED':
                if (operations.operationType === 'sale') {
                    // @ts-ignore
                    result = i18n.global.t("g.payments.statuses.withdrawal.CASHED");
                } else {
                    result = i18n.global.t("g.payments.statuses.CASHED");
                }
                break;
            case 'ONAPPEAL':
                result = i18n.global.t("g.payments.statuses.ONAPPEAL");
                break;
            case 'CONFIRMED':
                if (operations.operationType === 'sale') {
                    result = i18n.global.t("g.payments.statuses.withdrawal.CONFIRMED");
                } else {
                    result = i18n.global.t("g.payments.statuses.CONFIRMED");
                }
                break;
            case 'CREATED':
                result = i18n.global.t("g.payments.statuses.CREATED");
                break;
            case 'ACCEPTED':
                if (operations.operationType === 'sale') {
                    result = i18n.global.t("g.payments.statuses.withdrawal.ACCEPTED");
                } else {
                    result = i18n.global.t("g.payments.statuses.ACCEPTED");
                }
                break;
            case 'REJECTED':
                result = i18n.global.t("g.payments.statuses.REJECTED");
                break;
            case 'DISPUTED':
                result = i18n.global.t("g.payments.statuses.DISPUTED");
                break;
            case 'DECLINED':
                result = i18n.global.t("g.payments.statuses.DECLINED");
                break;
            case 'EXPIRED':
                result = i18n.global.t("g.payments.statuses.EXPIRED");
                break;
            case 'CANCELED':
                result = i18n.global.t("g.payments.statuses.CANCELED");
                break;
            case 'COMPLETED':
                result = i18n.global.t("g.payments.statuses.COMPLETED");
                break;
            case 'ACTIVATED':
                result = i18n.global.t("g.payments.statuses.ACTIVATED");
                break;
            case 'PENDING':
                result = i18n.global.t("g.payments.statuses.PENDING");
                break;
            case 'FAILED':
                result = i18n.global.t("g.payments.statuses.FAILED");
                break;
        }
        return result;
    }

    public static getStructureProperty(key: string, json: Keyable) {
        if (json.hasOwnProperty(key)) return json[key];
        throw new Error(`${key} isn't exists in passed structure ${json}`);
    }

    public getDateString(date: Date) {
        let day = date.getDate();
        let dayString = day < 10 ? `0${day}` : day;
        let month = date.getMonth() + 1;
        let monthString = month < 10 ? `0${month}` : month;
        let year = date.getFullYear();
        let hours = date.getHours();
        let hoursString = hours < 10 ? `0${hours}` : hours;
        let minutes = date.getMinutes();
        let minutesString = minutes < 10 ? `0${minutes}` : minutes;
        let seconds = date.getSeconds();
        let secondsString = seconds < 10 ? `0${seconds}` : seconds;

        return `${dayString}.${monthString}.${year} ${hoursString}:${minutesString}:${secondsString}`;
    }
}
