import ServiceAccount from "@/services/v2/data/service-account/ServiceAccount";

export default class FirebaseEventBuilder {
    private static _instance: FirebaseEventBuilder | null = null;
    private readonly _accountService: ServiceAccount;

    private constructor() {
        this._accountService = ServiceAccount.of();
    }

    public static of() {
        if (FirebaseEventBuilder._instance === null) FirebaseEventBuilder._instance = new FirebaseEventBuilder();
        return FirebaseEventBuilder._instance;
    }

    public build(payload: any = {}) {
        const result: any = {...payload};
        const project = this._accountService.getAccountProjectSlug();
        if (project) result.project = project;
        return result;
    }
}
