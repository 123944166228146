<template>
    <f7-app v-bind="vc.config">
        <Transition name="fade" mode="out-in">
            <MainViews v-if="(isReady && isAuth) || loading" />
            <f7-view main url="/auth-error" v-else :preload-previous-page="false" :ios-swipe-back="false"></f7-view>
        </Transition>

        <payment-feedback-cancel
            v-if="vc.paymentService.feedbackCancelService.modalIsVisible.value"
        />
        <payment-amount-changed-modal v-if="vc.paymentService.isAmountWasChangedModalVisible.value"/>
    </f7-app>
</template>

<script lang="ts" setup>
// @ts-ignore
import {f7, f7ready} from 'framework7-vue';
import AppController from "./ts/AppController";
import MainViews from "../views/MainViews.vue";

const vc = AppController.of();
const isReady = vc.isReady;
const isAuth = vc.isAuth;
const loading = ref(false);

onBeforeMount(async () => {
    try {
        loading.value = true;
        await vc.beforeInitStep().init();
        await runCheckReleazioVersion();
    } finally {
        loading.value = false;
    }
});

async function runCheckReleazioVersion() {
    const serviceRealizio = AppController.of().service<ServiceReleazio>("releazio");
    if (serviceRealizio) {
        await serviceRealizio.fetchVersionConfig(LangService.getInstance().get());
        serviceRealizio.updateViewPost();
        serviceRealizio.checkCurrentVersionOld();

        if (serviceRealizio.isNeedUpdate.value) {
            serviceRealizio.openNeedUpdatePopup();
        }
    }
}

import {onBeforeMount, onMounted, ref} from "vue";
import ServiceReleazio from "@/services/v2/service-releazio/ServiceReleazio";
import LangService from "@/services/LangService";
import PaymentFeedbackCancel from "@components/modals/payment-feedback-cancel/PaymentFeedbackCancel.vue";
import PaymentAmountChangedModal from "@components/modals/payment-amount-changed/PaymentAmountChangedModal.vue";
</script>

<style lang="scss">
.no-data-page {
    .page-content {
        .info-block {
            margin-top: 56px;
        }
    }
}
</style>
