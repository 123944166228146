<!--TODO перенести в atomic->chat-->
<template>
    <div class="no-messages">
        <i class="icon i-svg-messages"></i>
        <p v-html="$t('chat.noMessages')" />
    </div>
</template>

<style lang="scss">
.no-messages {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 32px;
    gap: 16px;

    .i-svg-messages {
        width: 73px;
        height: 72px;
        background-image: url("data:image/svg+xml,%3Csvg width='73' height='72' viewBox='0 0 73 72' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1632_60337)'%3E%3Cpath d='M72.2639 63.1375C72.5001 63.3856 72.5642 63.7534 72.426 64.0773C72.2864 64.4087 71.9826 64.6 71.6339 64.6C67.5557 64.6 64.0851 63.4772 61.3412 62.1272C58.2464 63.5987 54.6914 64.6 50.9001 64.6C40.774 64.6 32.3039 58.2033 29.9639 49.5812C41.9339 47.185 50.9001 37.8925 50.9001 26.8C50.9001 26.194 50.8476 25.5996 50.7951 25.0045C50.8326 25 50.8664 25 50.9001 25C62.8251 25 72.5001 33.865 72.5001 44.8C72.5001 49.3709 70.7935 53.5694 67.954 56.9163C69.7889 60.4712 72.2301 63.1037 72.2639 63.1375Z' fill='%23007AFF'/%3E%3Cpath opacity='0.4' d='M23.9002 7C10.9773 7 0.500209 15.865 0.500209 26.8C0.500209 31.2516 2.25746 35.3455 5.18808 38.6575C3.34421 42.343 0.774709 45.106 0.734209 45.1465C0.497959 45.3946 0.433834 45.7624 0.572096 46.0863C0.713959 46.4087 1.01793 46.6 1.36196 46.6C5.67633 46.6 9.32246 45.349 12.1327 43.8955C15.5977 45.5987 19.6027 46.6 23.9002 46.6C36.8265 46.6 47.3002 37.735 47.3002 26.8C47.3002 15.865 36.8265 7 23.9002 7Z' fill='%23007AFF'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1632_60337'%3E%3Crect width='72' height='72' fill='white' transform='translate(0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    }
    p {
        text-align: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: -0.41px;
        color: #ACB4BE;
        margin: 0px;
    }
}
</style>
