import {Expose, Type} from "class-transformer";
import PaymentFeedbackCancelOptionParams from "@models/operations/payment/feedback/PaymentFeedbackCancelOptionParams";

export default class PaymentFeedbackCancelOption {
    @Expose({name: 'id'})
    private _id: number | null = null;
    @Expose({name: 'optionKey'})
    private _optionKey: string | null = null;
    @Expose({name: 'optionText'})
    private _optionText: string | null = null;
    @Expose({name: 'params'})
    @Type(() => PaymentFeedbackCancelOptionParams)
    private _params: PaymentFeedbackCancelOptionParams | null = null;

    get id(): number | null {
        return this._id;
    }

    get optionKey(): string | null {
        return this._optionKey;
    }

    get optionText(): string | null {
        return this._optionText;
    }

    get params(): PaymentFeedbackCancelOptionParams | null {
        return this._params;
    }
}