import {OperationType} from "@enums/operation/OperationType";

export interface RepositoryFetchOperationInterface {
    fetchOperation(payload: FetchOperationPayload): any;
}

export type FetchOperationPayload = {
    type: OperationType,
    msid: string
};

/**
 * This class aims to fetch data about the operation
 */
export default class ServiceFetchOperation {
    private _repositoryFetchOperation: RepositoryFetchOperationInterface;

    public constructor(repositoryOperation: RepositoryFetchOperationInterface) {
        this._repositoryFetchOperation = repositoryOperation;
    }

    public async fetchOperation(payload: FetchOperationPayload) {
        return await this._repositoryFetchOperation.fetchOperation(payload);
    }
}
