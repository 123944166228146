<template>
<f7-navbar class="payments-view__navbar">
    <f7-nav-title>
        <NavbarTitleContent
            :account="account"
        />
    </f7-nav-title>
    <f7-subnavbar>
        <div class="subnavbar-left">
            <a href="#" class="link icon-only" :class="{'disabled': !account }" @click="TO_SETTINGS">
                <i class="icon i-svg-settings"></i>
            </a>
        </div>
        <div class="title">
            <TransitionGroup name="list" tag="div">
                <i ref="sloth-m" class="icon sloth-logo-m-svg"/>
                <i v-if="!viewNavbarLogo" class="icon logo-small-svg"/>
            </TransitionGroup>
        </div>
        <div class="subnavbar-right">
            <Transition name="fade" mode="out-in">
                <a href="#" class="link icon-only" @click="openAlertToTelegram">
                    <i class="icon telegram-svg"></i>
                </a>
            </Transition>
        </div>
    </f7-subnavbar>
</f7-navbar>
</template>

<script lang="ts">
import {defineComponent, ref, toRef} from "vue";
import {f7, useStore} from "framework7-vue";
import {getDevice} from "framework7/shared/get-device";
import NavbarTitleContent from "@/targets/integration/views/payments/components/NavbarTitleContent.vue";
import {useI18n} from "vue-i18n";
// @ts-ignore
import anime from "animejs";
import ServiceAccount from "@/services/v2/data/service-account/ServiceAccount";

export default defineComponent({
    components: {NavbarTitleContent},
    computed: {},
    props: ['viewNavbarBalance', 'viewNavbarLogo'],
    setup(props: any) {
        const account = ServiceAccount.of().account;
        const nextbtnLoading = ref(false);
        const viewNavbarBalance = toRef(props, 'viewNavbarBalance')
        const viewNavbarLogo = toRef(props, 'viewNavbarLogo')
        const needHideLogoM = ref(false);
        const allowAnimateShow = ref(true);
        const {t} = useI18n({useScope: 'global'});
        return {
            t,
            allowAnimateShow,
            needHideLogoM,
            viewNavbarLogo,
            viewNavbarBalance,
            nextbtnLoading,
            account,
            getDevice
        }
    },
    watch: {},
    methods: {
        openAlertToTelegram() {
            f7.dialog.create({
                title: this.t("g.alerts.to-telegram.title"),
                text: this.t("g.alerts.to-telegram.text"),
                cssClass: "simple",
                buttons: [
                    {
                        text: this.t("g.no")
                    },
                    {
                        text: this.t("g.yes"),
                        onClick: async () => {
                            try {
                                const url = "https://t.me/BroMoney_robot";
                                if (getDevice().ios) {
                                    // @ts-ignore
                                    window.webkit.messageHandlers.iosListener.postMessage(JSON.stringify({
                                        "deeplink": "https://t.me/BroMoney_robot"
                                    }));
                                    // window.open(url, "_self");
                                    // return;
                                } else if (getDevice().android) {
                                    // @ts-ignore
                                    window.webInterface.onMessage(JSON.stringify({
                                        "deeplink": "https://t.me/BroMoney_robot"
                                    }));
                                    // window.open(url, "_self");
                                } else {
                                    window.open(url, "_target");
                                }
                            } catch (e) {
                                window.open('https://t.me/BroMoney_robot', "_target");
                                // if (getDevice().android) {
                                //     window.open('https://t.me/BroMoney_robot', "_target");
                                // }
                            }
                        }
                    }
                ]
            }).open();
        },
        TO_SETTINGS() {
            f7.views.current.router.navigate('/settings');
        },
        openSheetModalForNewPayment() {
            this.nextbtnLoading = true;
            this.$emit('openSheetModalForNewPayment', () => {
                this.nextbtnLoading = false;
            });
        },
        animateLogo(init: boolean) {
            const element = this.$refs['sloth-m'];
            let title: HTMLElement | null = null;
            title = document.querySelector('.payments-view__navbar .title')!;
            const m = title.offsetWidth / 2 - 12;

            if (init) {
                // if (!this.allowAnimateShow) return;
                // this.allowAnimateShow = false;
                anime({
                    targets: element,
                    keyframes: [
                        {
                            translateX: m
                        },
                    ],
                    duration: 700,
                    // update: () => {
                    //     if (this.needHideLogoM) {
                    //         element.style.opacity = 0;
                    //         element.style.transform = `translateX(${-m}px)`;
                    //     }
                    // },
                    // complete: () => {
                    //     this.allowAnimateShow = true;
                    // }
                });
            } else {
                anime({
                    targets: element,
                    keyframes: [
                        {
                            translateX: -m
                        },
                    ],
                    duration: 700,
                });
                // this.needHideLogoM = true;
                // element.style.opacity = 0;
                // element.style.transform = `unset`;
                // setTimeout(() => {
                //     this.needHideLogoM = false;
                // }, 701);
            }
        }
    }
});
</script>

<style lang="scss">
.payments-view__navbar {
    //.subnavbar-left {
    //    flex: 1;
    //}
    //
    //.subnavbar-right {
    //    flex: 1;
    //    display: flex;
    //    justify-content: flex-end
    //}
}
</style>
