<template>
    <div class="payment__status accepted">
        <template v-if="requisites">
            <block-warning
                v-if="isAmountWasChanged && isAmountWasChangedShowBlockWarning"
                :message="$t('payment.warnings.amount-was-changed')"
            />
            <payment-amount-title
                v-if="isAmountFullData"
                :abbr="payment.currency!.getABBR()"
                :amount="payment.amount"
            />
            <div class="payment__requisites">
                <PaymentCard
                    :payment="payment!"
                />
            </div>
            <transition-group name="fade" mode="out-in">
                <PaymentRequisiteComment key="1" :comment="requisites.comment"/>
                <PaymentRequisiteAdvices key="2" :address-type="requisites.addressType"/>
            </transition-group>
            <PaymentMeta :payment="payment"/>
            <span class="cancel" @click="cancelPayment">{{ $t('light-payment.status.created.cancel') }}</span>
        </template>
    </div>
</template>

<script setup lang="ts">
import {computed, PropType, ref, toRef, UnwrapRef, watch} from "vue";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import PaymentCard from "@/views/payment/components/atomic/payment-card/PaymentCard.vue";
import {ToRef} from "@vue/runtime-core";
import PaymentMeta from "@/views/payment/components/atomic/PaymentMeta.vue";
import PaymentContentHeader from "@/views/payment/components/atomic/PaymentContentHeader.vue";
import PaymentRequisiteComment from "@/views/payment/components/atomic/PaymentRequisiteComment.vue";
import PaymentRequisiteAdvices from "@components/atomic/payment/requisite-advices/PaymentRequisiteAdvices.vue";
import PaymentAmountTitle from "@/views/payment/components/atomic/PaymentAmountTitle.vue";
import BlockWarning from "@components/atomic/block-warning/BlockWarning.vue";
import i18n from "@/langs/i18n";

enum TIMER_STATUS_TYPE {
    DEFAULT = "DEFAULT",
    WARNING = "WARNING",
    ALERT = "ALERT"
}
const setTimerColors = {
    DEFAULT: "#1890FF",
    WARNING: "#E69700",
    ALERT: "#FF3B30"
};
const isAmountFullData = computed(() => {
   return payment.value.amount && payment.value.currency;
});
const props = defineProps({
    payment: {
        type: Object as PropType<PurchaseOperation>,
        required: true
    },
    timeText: {
        type: [String, null] as PropType<string | null>,
        required: true
    },
    percent: {
        type: [Number] as PropType<number>,
        required: true
    },
    isAmountWasChanged: {
        type: [Boolean] as PropType<boolean>,
        required: true,
        default: false
    },
    isAmountWasChangedShowBlockWarning: {
        type: [Boolean] as PropType<boolean>,
        required: true,
        default: false
    }
});
const payment: ToRef<PurchaseOperation> = toRef(props, 'payment');
const requisites = computed(() => payment.value!.requisites);
const percent: ToRef<number> = toRef(props, 'percent');
const timeText: ToRef<string | null> = toRef(props, 'timeText');
const buyingIsAvailable = ref(true);
const timerBorderColor = ref(setTimerColors[TIMER_STATUS_TYPE.DEFAULT]);
const timerTextColor = ref(setTimerColors[TIMER_STATUS_TYPE.DEFAULT]);
const isAmountWasChanged = toRef(props, 'isAmountWasChanged');
const isAmountWasChangedShowBlockWarning = toRef(props, 'isAmountWasChangedShowBlockWarning');
detectBuyingIsAvailable(timeText.value);

watch(timeText, (value) => {
    if (value) {
        detectBuyingIsAvailable(value);
    }
});

const emits = defineEmits(['cancelPayment']);

function cancelPayment() {
    emits('cancelPayment');
}

function detectBuyingIsAvailable(value: string | null) {
    if (value === null) {
        buyingIsAvailable.value = false;
        return;
    }

    const minute = +value.split(':')[0];
    const seconds = +value.split(':')[1];

    if (minute <= 4 && seconds < 59) {
        timerBorderColor.value = setTimerColors[TIMER_STATUS_TYPE.WARNING];
        timerTextColor.value = setTimerColors[TIMER_STATUS_TYPE.WARNING];
    } else if (minute == 0 && seconds <= 59) {
        timerBorderColor.value = setTimerColors[TIMER_STATUS_TYPE.ALERT];
        timerTextColor.value = setTimerColors[TIMER_STATUS_TYPE.ALERT];
    } else if (minute < 0) {
        buyingIsAvailable.value = false;
    }
}

</script>

<style src="./index.scss"></style>
<style lang="scss">
.payment__status {
    &_text {
        @include text(14px, 400, var(--brown1), 16.8px);
        margin-bottom: 16px;
    }

    &.accepted {
        margin-top: 20px;

        .payment__requisites {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .requisites {
                &_title {
                    @include text(16px, 700, var(--brown1), 19.2px);
                }
            }
        }

        .cancel {
            cursor: pointer;
            margin-top: 32px;
            font-style: normal;
            font-weight: 500;
            font-size: 17px;
            line-height: 22px;
            letter-spacing: -0.41px;
            color: #FF3B30;
            display: block;
            text-align: center;
        }
    }
}
</style>
