<template>
    <a
        @click="toLink"
        href="#"
        :class="[
            'link home-url external',
            {'disabled': !account}
        ]"
    >
        <i class="icon i-svg-cross"></i>
        {{ $t('g.close') }}
    </a>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { getDevice } from "framework7/shared/get-device";
import ModelAccount from "@models/v2/account/ModelAccount";

export default defineComponent({
    props: {
        account: {
            type: Object as PropType<ModelAccount | null>,
            default: null
        }
    },
    methods: {
        toLink() {
            if (this.account === null) return;
            try {
                if (getDevice().ios) {
                    // @ts-ignore
                    window.webkit.messageHandlers.iosListener.postMessage('close');
                    setTimeout(() => {
                        window.open(this.account!.settings.homeUrl);
                    }, 700);
                } else if (getDevice().android) {
                    // @ts-ignore
                    window.webInterface.onMessage('close');
                    setTimeout(() => {
                        window.open(this.account!.settings.homeUrl);
                    }, 700);
                } else {
                    this.safeTryingSendMessageToAndroid();
                    this.safeTryingSendMessageToIos();
                    setTimeout(() => {
                        window.open(this.account!.settings.homeUrl);
                    }, 700);
                }
            } catch (e) {
                this.safeTryingSendMessageToAndroid();
                this.safeTryingSendMessageToIos();
                setTimeout(() => {
                    window.open(this.account!.settings.homeUrl);
                }, 700);
            }
        },
        safeTryingSendMessageToAndroid() {
            try {
                // @ts-ignore
                window.webInterface.onMessage('close');
            } catch (e) {
            }
        },
        safeTryingSendMessageToIos() {
            try {
                // @ts-ignore
                window.webkit.messageHandlers.iosListener.postMessage('close');
            } catch (e) {
            }
        },
        prepareLinkName(link: string) {
            return link.replace(/(http)s?:\/\//, '').replace(/\..+/, '');
        },
    }
})
</script>
