import "reflect-metadata";
// @ts-ignore
import {createApp} from 'vue';
// @ts-ignore
import Framework7 from 'framework7/lite-bundle'
// @ts-ignore
import Framework7Vue, { registerComponents } from 'framework7-vue/bundle'
import 'framework7/css/bundle';
import '@/scss/app.scss';
import '@/scss/icons.scss';
import '@target/scss/app.scss';
import '@/scss/animations.scss';
import App from '@target/components/App/App.vue';
// @ts-ignore
import {color} from "@target/core/directives";
import i18n from "@/langs/i18n";
import disableBounce from "@/helpers/disable-bounce";
import * as Sentry from "@sentry/vue";

Framework7.use(Framework7Vue);
const app = createApp(App);
app.use(i18n);
registerComponents(app);

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
        Sentry.replayIntegration({
            maskAllText: true,
            blockAllMedia: true,
        }),
    ],
});

app.mount('#app');
app.directive(color.name, color.fn);

disableBounce(window);
