import RepositoryAccount from "@/repositories/v2/repository-account/RepositoryAccount";
import ModelAccount from "@models/v2/account/ModelAccount";
import { computed, ComputedRef, ref, Ref } from "vue";
import FirebaseFcmToken from "@models/firebase/FirebaseFcmToken";
import { getDevice } from "framework7/shared/get-device";
import { autobind } from "@/decorators/autobind";

export default class ServiceAccount {
    private static _instance: ServiceAccount | null = null;
    private readonly _repositoryAccount: RepositoryAccount;
    private readonly _account: Ref<ModelAccount | null>;

    constructor() {
        this._account = ref(null);
        this._repositoryAccount = new RepositoryAccount();
    }

    public static of() {
        if (ServiceAccount._instance === null) {
            ServiceAccount._instance = new ServiceAccount();
        }

        return ServiceAccount._instance;
    }

    public get account(): Ref<ModelAccount | null> {
        return this._account;
    }

    public get accountCurrencyAbbr(): ComputedRef<string | undefined> {
        return computed(() => this._account.value?.currency.abbr);
    }

    public set account(value: ModelAccount | null) {
        this._account.value = value;
    }

    public getAccountProjectSlug(): string | undefined {
        return this._account.value?.project.slug;
    }

    public async fetchAccount(): Promise<void> {
        this._account.value = await this._repositoryAccount.fetchAccount();
    }

    @autobind
    public async updateDeviceTokens(tokens: (FirebaseFcmToken)[]) {
        if (!getDevice().cordova) {
            return;
        }

        const payload = {
            // @ts-ignore
            "device_id": device.uuid,
            "tokens": tokens.map((el: FirebaseFcmToken) => {
                return {
                    "type": el.type,
                    "token": el.token
                }
            })
        }
        await this._repositoryAccount.updateDeviceTokens(payload);
    }

    public async patchAccount(payload: object): Promise<void> {
        this._account.value = await this._repositoryAccount.patchAccount(payload);
    }

    public async deleteAccount(): Promise<void> {
        await this._repositoryAccount.deleteAccount();
        this._account.value = null;
    }
}
