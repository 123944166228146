import humps from "lodash-humps-ts";
import ServerResponseError from "@/errors/ServerResponseError";
import ServerAuthError from "@/errors/ServerAuthError";
import {f7} from "framework7-vue";
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import {RequestPayloadDto} from "@/services/api-service/dto/RequestPayloadDto";
import {FirebaseService} from "@/services/firebase/FirebaseService";
import {RequestHeadersPayloadDto} from "@/services/api-service/dto/RequestHeadersPayloadDto";
import {FirebaseEvents} from "@/services/firebase/analytics/FirebaseEvents";
import FirebaseEventBuilder from "@/services/firebase/analytics/FirebaseEventBuilder";
import { UserError } from "@enums/ServerErrorCodes";
import ServiceAccount from "@/services/v2/data/service-account/ServiceAccount";

type Payload = {
    path: string,
    type: string,
    params: any,
    file?: boolean,
}

//TODO Помечен для удаления в будущем
export default class MainApiService {
    private savedRequest: Payload | null = null;
    private HEADERS: any = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
    private config: any = {
        'domain': import.meta.env.VITE_API_URL,
        'headers': this.HEADERS
    };
    private isRefreshing: boolean = false;
    private REQUEST_TIMEOUT: number = 3000;
    private MAX_ATTEMPTS: number = 3;
    private timeoutInterval: number | null = null;
    private _MAX_FETCH_TIMEOUT = 10000;

    public updateConfig() {
        this.config = {
            'domain': this.config.domain,
            'headers': this.HEADERS
        };
    }

    public setConfigDomain(domain: string) {
        this.config.domain = domain;
    }

    public getConfigDomain() {
        return this.config.domain;
    }

    public updateUpdateHeadersWithXToken() {
        this.HEADERS["X-Token"] = localStorage.getItem('ltoken')!;
    }

    private updateHeadersWithRequestUuid(uuid: string) {
        this.HEADERS["Request-Uuid"] = uuid;
    }

    private deleteHeadersWithRequestUuid() {
        delete this.HEADERS["Request-Uuid"];
    }

    private deleteHeadersContentTypeJson() {
        delete this.HEADERS["Content-Type"];
    }

    private addHeadersContentTypeJson() {
        this.HEADERS["Content-Type"] = "application/json";
    }

    private updateHeadersWithAppType() {
        this.HEADERS["App-Type"] = import.meta.env.VITE_APP_TYPE;
    }

    private updateHeadersWithRetryPayload(payload: RequestHeadersPayloadDto) {
        const {retryAttempt, retryRequestUuid} = payload;
        this.HEADERS["Retry-For"] = retryRequestUuid;
        this.HEADERS["Retry-Attempt"] = retryAttempt;
    }

    private updateHeadersWithoutRetryPayload() {
        delete this.HEADERS["Retry-For"];
        delete this.HEADERS["Retry-Attempt"];
    }

    private setTimeoutInterval(controller: AbortController) {
        this.timeoutInterval = setTimeout(() => {
            // LogService.of().log("ApiService@setTimeoutInterval", "abort request");
            // controller.abort("Request was aborted, timeout");
            this.timeoutInterval = null;
        }, this.REQUEST_TIMEOUT);
    }

    private clearTimeoutInterval() {
        if (this.timeoutInterval) {
            // LogService.of().log("ApiService@setTimeoutInterval", "clear interval");
            clearTimeout(this.timeoutInterval);
        }
    }

    private static instance?: MainApiService;

    private constructor() {
        if (localStorage.getItem('ltoken')!) this.updateUpdateHeadersWithXToken();
        this.updateHeadersWithAppType();
    }

    static getInstance() {
        if (typeof MainApiService.instance === 'undefined') {
            MainApiService.instance = new MainApiService();
        }
        return MainApiService.instance;
    }

    private async makeRequest(requestDto: RequestPayloadDto): Promise<Response | null> {
        const {url, method, attempt, payload} = requestDto;

        const controller = new AbortController();
        this.setTimeoutInterval(controller);

        const config: any = {
            method: method,
            headers: this.config.headers,
            // signal: AbortSignal.timeout(this._MAX_FETCH_TIMEOUT)
        };

        if (["POST", "PATCH", "DELETE"].includes(method)) {
            config.body = payload
        }

        const response: Response = await fetch(url, config);
        this.clearTimeoutInterval();

        return response;
    }

    private resetRequestTimeout() {
        this.REQUEST_TIMEOUT = 2000;
    }

    private async makeRequestWithRetry(requestDto: RequestPayloadDto): Promise<Response | null> {
        const {url, method, attempt, retryRequestUuid, baseRequestUuid, payload} = requestDto;
        this.updateHeadersWithRequestUuid(retryRequestUuid ? retryRequestUuid : requestDto.baseRequestUuid);
        try {
            return await this.makeRequest(requestDto)
        } catch (e: any) {
            FirebaseService.of().analyticsEvent(
                FirebaseEvents.REQUEST_RETRY,
                FirebaseEventBuilder.of().build()
            );
            if (requestDto.attempt < this.MAX_ATTEMPTS) {
                let nextAttempt = attempt + 1;
                this.updateHeadersWithRetryPayload({
                    retryAttempt: nextAttempt - 1,
                    retryRequestUuid: baseRequestUuid
                });
                return await this.makeRequestWithRetry({
                    url: url,
                    method: method,
                    attempt: nextAttempt,
                    baseRequestUuid: baseRequestUuid,
                    retryRequestUuid: window.crypto.randomUUID(),
                    payload: payload
                });
            }
            return null;
        }
    }

    get = async (path = "/", data = {}) => {
        let url = this.config.domain + path;
        if (Object.keys(data).length !== 0) url += '?' + new URLSearchParams(data).toString();

        this.deleteHeadersWithRequestUuid();
        this.updateHeadersWithoutRetryPayload();
        const response: Response | null = await this.makeRequestWithRetry({
            url: url,
            method: "GET",
            attempt: 1,
            baseRequestUuid: window.crypto.randomUUID(),
            retryRequestUuid: null
        });
        // this.resetRequestTimeout();

        if (response === null) throw new ServerResponseError("Unknown network error", -1);

        if (!response.ok) {
            if (response.status === 401) {
                return await this.handle401({path, params: data, type: "GET"});
            }
            const json: { code: number, error: string } = await response.json();
            throw new ServerResponseError(json.error, json.code);
        }

        const json: { data: object, meta: any } = await response.json();
        return humps({
            data: json.data,
            meta: json.meta
        });
    }
    post = async (path = "/", data: any, file: boolean = false) => {
        this.deleteHeadersWithRequestUuid();
        this.updateHeadersWithoutRetryPayload();

        let url = this.config.domain + path;

        if (file) this.deleteHeadersContentTypeJson();
        else this.addHeadersContentTypeJson();

        const response: Response | null = await this.makeRequestWithRetry({
            url: url,
            method: "POST",
            attempt: 1,
            baseRequestUuid: window.crypto.randomUUID(),
            retryRequestUuid: null,
            payload: file ? data : JSON.stringify(data)
        });

        if (response === null) throw new ServerResponseError("Unknown network error", -1);

        if (response.ok === false) {
            if (response.status === 401) {
                return this.handle401({path, params: data, type: "POST"});
            }
            const json: {
                code: number,
                error: string,
                ban: { comment: string, unban_at: string },
                data?: any
            } = await response.json();

            if (json.code === UserError.UserTemporarilyBlocked) {
                //TODO Заменят методы из store
                await ServiceAccount.of().fetchAccount();
                setTimeout(() => {
                    //@ts-ignore
                    const sheet = f7.sheet.create({
                        el: document.querySelector('.ban-sheet')! as HTMLElement,
                    });
                    sheet.open();
                }, 314);
            }
            throw new ServerResponseError(json.error, json.code);
        }

        const json: { data: object } = await response.json();

        return humps(json.data);
    }
    patch = async (path = "/", data = {}) => {
        this.deleteHeadersWithRequestUuid();
        this.updateHeadersWithoutRetryPayload();

        let url = this.config.domain + path;

        const response: Response | null = await this.makeRequestWithRetry({
            url: url,
            method: "PATCH",
            attempt: 1,
            baseRequestUuid: window.crypto.randomUUID(),
            retryRequestUuid: null,
            payload: JSON.stringify(data)
        });

        if (response === null) throw new ServerResponseError("Unknown network error", -1);

        if (!response.ok) {
            if (response.status === 401) {
                return this.handle401({path, params: data, type: "PATCH"});
            }
            const json: { code: number, error: string, data?: any } = await response.json();

            // @ts-ignore
            throw new ServerResponseError(json.error, json.code);
        }

        const json: { data: object } = await response.json();

        return humps(json.data);
    }
    delete = async (path = "/", data: any = {}) => {
        this.deleteHeadersWithRequestUuid();
        this.updateHeadersWithoutRetryPayload();
        // this.updateConfig();
        let url = this.config.domain + path;

        const response: Response | null = await this.makeRequestWithRetry({
            url: url,
            method: "DELETE",
            attempt: 1,
            baseRequestUuid: window.crypto.randomUUID(),
            retryRequestUuid: null,
            payload: JSON.stringify(data)
        });

        if (response === null) throw new ServerResponseError("Unknown network error", -1);

        if (response.ok === false) {
            if (response.status === 401) {
                return await this.handle401({path, params: data, type: "DELETE"});
            }
            const json: {
                code: number,
                error: string,
                ban: { comment: string, unban_at: string }
            } = await response.json();

            if (json.code === UserError.UserTemporarilyBlocked) {
                //TODO Заменят методы из store
                await ServiceAccount.of().fetchAccount();
                setTimeout(() => {
                    //@ts-ignore
                    const sheet = f7.sheet.create({
                        el: document.querySelector('.ban-sheet')! as HTMLElement,
                    });
                    sheet.open();
                }, 314);
                throw new ServerResponseError(json.error, json.code);
            }

            throw new ServerResponseError(json.error, json.code);
        }

        const json: { data: object } = await response.json();

        return humps(json.data);
    }

    refreshToken = async (csp: string, refreshToken: string): Promise<any> => {
        let url = this.config.domain + '/api/mobile/auth/refresh';
        let response: any = await fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Csp': csp
            },
            body: JSON.stringify({refresh_token: refreshToken})
        });
        if (response.ok !== false) {
            response = await response.json();
            localStorage.setItem('ltoken', response.access_token);
            localStorage.setItem('lrefreshToken', response.refresh_token);

            this.updateConfig();
            this.updateUpdateHeadersWithXToken();

            if (this.savedRequest != null && this.isRefreshing) {
                const {path, type, params, file} = this.savedRequest;
                let request;
                if (type === "GET") {
                    request = await this.get(path, params);
                }
                if (type === "POST") {
                    request = await this.post(path, params);
                }
                if (type === "PATCH") {
                    request = await this.patch(path, params);
                }
                this.savedRequest = null;
                this.isRefreshing = false;
                return request;
            } else {
                AppController.getInstance().logout(null);
                throw new ServerAuthError("Unauthorized", 401);
            }
        } else {
            AppController.getInstance().logout(null);
            throw new ServerAuthError("Unauthorized", 401);
        }
    }

    private async handle401(payload: Payload) {
        this.savedRequest = {
            path: payload.path,
            type: payload.type,
            params: payload.params,
            file: false
        };

        if (localStorage.getItem('lrefreshToken') && localStorage.getItem('csp') && !this.isRefreshing) {
            this.isRefreshing = true;
            return await this.refreshToken(localStorage.getItem('csp')!, localStorage.getItem('lrefreshToken')!);
        }
        this.isRefreshing = false;
        this.savedRequest = null;
        AppController.getInstance().logout(null);
        throw new ServerAuthError("Unauthorized", 401);
    }
}
