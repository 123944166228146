<template>
    <div class="payment-feedback-cancel-modal hide smooth-height">
        <div class="payment-feedback-cancel-modal_header">
            <div class="payment-feedback-cancel-modal_header-title"></div>
            <div class="payment-feedback-cancel-modal_header-close-wrapper"
                 @click="closeModal">
                <i class="icon close-svg"></i>
            </div>
        </div>
        <div class="payment-feedback-cancel-modal_content">
            <SlothBlock
                :title="''"
                :subtitle="''"
                :type="SLOTH_TYPE.QUESTION"
            />
            <p class="payment-feedback-cancel-modal_title">{{ $t("modals.feedback-cancel.title") }}</p>
            <f7-list
                class="payment-feedback-cancel-modal_content-options"
                :class="[{
                    'validate-error': commentHasValidateError && activeOperation.id === localOptions[localOptions.length - 1].id
                }]"
            >
                <template v-for="option in localOptions" :key="option.id">
                    <f7-list-item
                        radio
                        radio-icon="start"
                        :title="$t(`modals.feedback-cancel.options.${option.optionKey}`)"
                        class="payment-feedback-cancel-modal_content-option"
                        :value="option.id"
                        :checked="option.id === activeOperation.id"
                        @change="changeOption"
                    />
                    <div
                        v-if="option.params.input.enabled && option.optionKey == activeOperation.optionKey"
                        class="payment-feedback-cancel-modal_content-textarea"
                        :class="[{'validate-error': commentHasValidateError}]"
                    >
                        <f7-list-input
                            :type="'textarea'"
                            :placeholder="$t(`modals.feedback-cancel.comment-placeholder.${option.optionKey}`)"
                            v-model:value="comment"
                            clear-button
                            :rows="2"
                        ></f7-list-input>
                        <p
                            v-show="commentHasValidateError"
                            class="payment-feedback-cancel-modal_validate-error"
                        >{{ commentHasValidateErrorText }}</p>
                    </div>
                </template>
            </f7-list>
        </div>
        <div class="payment-feedback-cancel-modal_footer">
            <f7-button
                class="theme-type"
                preloader
                @click="submit"
            >
                {{ $t("modals.feedback-cancel.submit") }}
            </f7-button>
            <p class="payment-feedback-cancel-modal_skip" @click="closeModal">{{
                    $t("modals.feedback-cancel.skip")
                }}</p>
        </div>
    </div>
</template>

<script setup lang="ts">
import {computed, onMounted, PropType, ref, toRef, ToRef, watch} from "vue";
import PurchaseOperation from "@models/operations/PurchaseOperation";
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import SlothBlock from "@components/messages/SlothBlock.vue";
import {SLOTH_TYPE} from "@/entities/enums/SlothType";
import PaymentFeedbackCancelOption from "@models/operations/payment/feedback/PaymentFeedbackCancelOption";
import i18n from "@/langs/i18n";
import {OperationType} from "@enums/operation/OperationType";
import ErrorsService from "@/services/errors-service/ErrorsService";
import {f7} from "framework7-vue";

const commentHasValidateError = ref(false);
const commentHasValidateErrorText = ref("");
const props = defineProps({
    isVisible: {
        type: Object as PropType<boolean>,
        required: false
    },
});
const comment = ref("");
const controller = AppController.of().paymentService;
const feedbackCancelService = controller.feedbackCancelService;

const localOptions = computed(() => {
    return controller.feedbackCancelService.options.value.options.map((option: any, index: number) => {
        option.checked = index === 0;
        return option
    });
});
const activeOperation: ToRef<PaymentFeedbackCancelOption> = ref(localOptions.value[0]);

watch(activeOperation, (value: any) => {
    clearErrors();
    comment.value = "";
});

watch(comment, (value: any) => {
    clearErrors();
});

function changeOption(e: any) {
    activeOperation.value = controller.feedbackCancelService.options.value!.options!.find((el: any) => el.id == e.target.value)!;
}
function closeModal() {
    feedbackCancelService.hideModal();
    AppController.getInstance().routerService.toPayments();
}

async function submit() {
    try {
        if (!validateComment()) {
            return;
        }
        const payload = {
            "survey_id": controller.feedbackCancelService.options.value.id,
            "survey_option_id": activeOperation.value.id,
            "operable_type": OperationType.CLIENT_P2P_BUY,
            "operable_id": controller.payment.value.id,
            "description": comment.value.length === 0 ? "-" : comment.value,
        };
        f7.preloader.show();
        await controller.feedbackCancelService.sendFeedback(payload);
        closeModal();
    } catch (e) {
        await ErrorsService.of().handle(e);
    } finally {
        f7.preloader.hide();
    }
}

function clearErrors() {
    commentHasValidateErrorText.value = "";
    commentHasValidateError.value = false;
}

function validateComment() {
    try {
        if (activeOperation.value.params!.input?.enabled) {
            activeOperation.value.params!.input!.allowedTypes.map((el: any) => {
                if (el === 'string') {
                    if (comment.value.length === 0) {
                        throw new Error(i18n.global.t("modals.feedback-cancel.errors.string"));
                    }
                    if (comment.value.length > 200) {
                        throw new Error(i18n.global.t("modals.feedback-cancel.errors.max-length", {max: 200}));
                    }
                }
                if (el === 'number') {
                    if (!/^\d+$/.test(comment.value.trim())) {
                        throw new Error(i18n.global.t("modals.feedback-cancel.errors.number"));
                    }
                    if (comment.value.length > 12) {
                        throw new Error(i18n.global.t("modals.feedback-cancel.errors.max-length", {max: 12}));
                    }
                }
            });
        }
        return true;
    } catch (e: any) {
        commentHasValidateErrorText.value = e.message;
        commentHasValidateError.value = true;
        return false;
    }
}

// const isVisible: ToRef<boolean> = toRef(props, 'isVisible');
onMounted(() => {
    // const a = document.querySelector(".popup-backdrop");
    // console.log(a)
})
</script>

<style lang="scss">
.payment-feedback-cancel-modal {
    position: absolute;
    z-index: 13501;
    background: var(--white1);
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) scale(1);
    will-change: transform, opacity;
    transition-property: transform, opacity;
    transition-duration: 400ms;
    overflow: hidden;
    border-radius: 16px;
    padding: 0;
    display: block;
    width: calc(100% - 32px);
    max-width: 320px;

    &.smooth-height {

    }

    &_header {
        height: 40px;
        display: flex;
        padding: 14px 16px 0;

        &-title {
            flex: 1;
        }

        &-close-wrapper {
            cursor: pointer;

            .close-svg {
                background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.3635 9.00012L15.4672 3.89625C15.8443 3.51922 15.8443 2.9098 15.4672 2.53277C15.0902 2.15574 14.4808 2.15574 14.1038 2.53277L9 7.63664L3.89622 2.53277C3.5192 2.15574 2.90979 2.15574 2.53277 2.53277C2.15574 2.9098 2.15574 3.51922 2.53277 3.89625L7.63655 9.00012L2.53277 14.104C2.15574 14.481 2.15574 15.0904 2.53277 15.4675C2.7208 15.6555 2.96764 15.75 3.21449 15.75C3.46134 15.75 3.70819 15.6555 3.89622 15.4675L9 10.3636L14.1038 15.4675C14.2918 15.6555 14.5387 15.75 14.7855 15.75C15.0324 15.75 15.2792 15.6555 15.4672 15.4675C15.8443 15.0904 15.8443 14.481 15.4672 14.104L10.3635 9.00012Z' fill='%23D5D7D8'/%3E%3C/svg%3E%0A");
            }
        }
    }

    &_validate-error {
        color: var(--red1);
        @include text(12px, 400, var(--red1), 14px);
        padding: 8px;
        min-height: 30px;
    }

    &_content {
        padding: 0 24px;

        &-option {
            & + .payment-feedback-cancel-modal_content-textarea {
                margin-top: 6px;
            }
        }

        &-textarea {
            //& + .payment-feedback-cancel-modal_content-option {
            //    margin-top: 30px;
            //}
            &.validate-error {
                .item-input {
                    border-color: var(--red1);
                }

                & + .payment-feedback-cancel-modal_content-option {
                    margin-top: 0;
                }
            }
        }

        &-options {
            margin-top: 16px;
            margin-bottom: 16px;

            &.validate-error {
                margin-bottom: 0;
            }

            ul {
                .payment-feedback-cancel-modal_content-textarea {
                    &:has(~ .payment-feedback-cancel-modal_content-option) {
                        margin-bottom: 30px;
                    }

                    margin-bottom: 30px;

                    &.validate-error {
                        margin-bottom: 0;
                    }
                }
            }

            .active-state {
                background-color: unset !important;
            }

            input[type='radio']:checked ~ .icon-radio:after {
                transform: scale(1);
            }

            input[type='radio']:checked ~ .icon-radio {
                border: 2px solid var(--blue1);
            }

            .item-radio {
                --f7-list-item-padding-horizontal: 0;
            }

            .icon-radio {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 2px solid var(--grey4);
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px !important;

                &:after {
                    content: "";
                    width: 10px;
                    height: 10px;
                    background-color: var(--blue1);
                    border-radius: 50%;
                    transform: scale(0);
                }
            }
        }

        .item-input {
            --f7-list-item-padding-horizontal: 12px;
            border: 1px solid var(--grey4);
            border-radius: 8px;
        }
    }

    &_footer {
        //height: 105px;
        padding: 8px 24px 16px;
    }

    .sloths-svg {
        width: 88px;
        height: 88px;
    }

    &.hide {
        transform: translate3d(-50%, -50%, 0) scale(1.185);
        opacity: 0;
        display: none;
    }

    &_form {
        margin: 0;
    }

    &_skip {
        text-align: center;
        @include text(14px, 500, var(--grey2));
        cursor: pointer;
        margin-top: 8px;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 14px;
        color: var(--grey2);
        height: 30px;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    //&_close-wrapper {
    //    position: absolute;
    //    right: 16px;
    //    top: 14px;
    //    cursor: pointer;
    //}

    &_content {

    }

    &_title {
        margin-top: 12px;
        text-align: center;
        @include text(20px, 600, var(--brown1));
    }

    &_input-comment {
        margin-top: 16px;
        border: 1px solid var(--grey4);
        border-radius: 8px;
        margin-bottom: 24px;
    }
}

.payment-feedback-cancel-modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    will-change: transform, opacity;
    transition-property: transform, opacity;
    transition-duration: 400ms;
    z-index: 11000;
    opacity: 0;
}
</style>
