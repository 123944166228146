import {Ref, ref, ToRef} from "vue";
import ServiceMainApi from "@/services/v2/service-main-api/ServiceMainApi";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import {plainToInstance} from "class-transformer";
import {validateOrReject} from "class-validator";
import PaymentFeedbackCancel from "@models/operations/payment/feedback/PaymentFeedbackCancel";

export default class PaymentServiceFeedbackCancel {
    private _modalIsVisible: Ref<boolean> = ref(false);
    private _callback: Function | null = null;
    private _options: ToRef<PaymentFeedbackCancel | null> = ref(null);
    constructor() {
    }

    set modalIsVisible(value: Ref<boolean>) {
        this._modalIsVisible = value;
    }
    get modalIsVisible(): Ref<boolean> {
        return this._modalIsVisible;
    }

    get options(): ToRef<PaymentFeedbackCancel | null> {
        return this._options;
    }

    get callback(): Function | null {
        return this._callback;
    }

    set callback(value: Function | null) {
        this._callback = value;
    }

    public async fetchOptions() {
        const response = await ServiceMainApi.of().get<{
            data: any
        }>('/api/mobile/client/surveys/get?action=cancel_buy_order');
        this._options.value = await this.plainToModel(response.data);
        return this;
    }

    public async sendFeedback(payload: any) {
        const response = await ServiceMainApi.of().post<{ data: any }>('/api/mobile/client/surveys/response', payload);
        return this;
    }

    private async plainToModel(data: object): Promise<PaymentFeedbackCancel> {
        const operation: PaymentFeedbackCancel = plainToInstance(PaymentFeedbackCancel, data, {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });

        await validateOrReject(operation);

        return operation;
    }

    public showModal(callback: Function | null = null) {
        this._modalIsVisible.value = true;
        this._callback = callback;
        setTimeout(() => {
            this.showModalBackdrop();
            this.showModalContent();
        }, 0);
    }

    public hideModal() {
        this.removeModalBackdrop();
        this.hideModalContent();
        setTimeout(() => {
            this._modalIsVisible.value = false;
            if (this._callback) this._callback();
            this._callback = null;
        }, 314);
    }

    private removeModalBackdrop() {
        const el: HTMLElement | null = document.querySelector(".payment-feedback-cancel-modal-backdrop");
        if (el) {
            el.style.opacity = "0";
            setTimeout(() => {
                el.remove();
            }, 314);
        }
    }

    private hideModalContent() {
        const el: HTMLElement | null = document.querySelector(".payment-feedback-cancel-modal");
        if (el != null) {
            el.style.opacity = "0";
            el.classList.remove("hide");
            setTimeout(() => {
                el.style.display = "none";
            }, 314)
        }
    }

    private showModalBackdrop() {
        const el = document.createElement("div");
        el.classList.add("payment-feedback-cancel-modal-backdrop");
        // el.addEventListener("click", () => {
        //     this.hideModal();
        // });
        const parent = document.getElementById("framework7-root");
        if (parent) parent.appendChild(el);
        setTimeout(() => {
            el.style.opacity = "1";
        }, 50);
    }

    private showModalContent() {
        const el: HTMLElement | null = document.querySelector(".payment-feedback-cancel-modal");
        if (el != null) {
            el.style.display = "block";
            setTimeout(() => {
                el.style.opacity = "1";
                el.classList.remove("hide");
            }, 50)
        }
    }
}