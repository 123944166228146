//TODO возможно следует придумать лучше название для репозитория, работающего с криптой
import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import ServiceMainApi from "@/services/v2/service-main-api/ServiceMainApi";
import WithdrawalWalletOperation from "@models/operations/withdrawal/wallet/WithdrawalWalletOperation";
import { CreateWalletWithdrawalPayload } from "./types";

export default class RepositoryWithdrawalWallet {
    async createWithdrawalWallet(payload: CreateWalletWithdrawalPayload): Promise<WithdrawalWalletOperation> {
        const { amount, requisite_id } = payload;
        const response = await ServiceMainApi.of().post<{ data: object }>('/api/mobile/client/transfers/create', {
            amount_fiat: amount,
            requisite_id
        });

        return this.plainToModel(response.data);
    }

    async fetchWithdrawalWallet(msid: string): Promise<WithdrawalWalletOperation> {
        const response = await ServiceMainApi.of().get<{ data: object }>(`/api/mobile/client/transfers/get/${msid}`);

        return this.plainToModel(response.data);
    }

    async plainToModel(data: object): Promise<WithdrawalWalletOperation> {
        const operation: WithdrawalWalletOperation = plainToInstance(WithdrawalWalletOperation, data, {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });

        await validateOrReject(operation);

        return operation;
    }
}
