<template>
    <div
        ref="componentRef"
        :class="[
            'operation-card',
            'payment',
            `payment-${operation.id}`
        ]"
        @click="toPayment"
    >
        <div class="operation-card__header">
            <div class="operation-card__content">
                <slot name="icon">
                    <i class="icon round-bill-svg"></i>
                </slot>
                <div class="operation-card__amount">
                    <span class="operation-card__amount-main" v-format-number>{{ mainAmount }} {{ mainAmountAbbr }}</span>
                    <span class="operation-card__amount-convert">{{ convertAmountText }} {{ convertAmount }} $</span>
                </div>
            </div>
            <div class="operation-card__status">
                <OperationStatus :operation="operation" />
            </div>
        </div>
        <div class="operation-card__footer">
            <p class="operation-card__details">
                <span class="operation-card__id">{{ $t('g.request') }} {{ operation.id }}</span>
                <span class="operation-card__date">{{ ' ' + operation.getHumanCreateAt() }}</span>
            </p>
            <div
                v-if="operation instanceof PurchaseOperation || operation instanceof WithdrawalOperation"
                class="operation-card__chat"
            >
                <i
                    v-if="operation.chat && operation.chat.unread > 0"
                    class="icon alert-message-svg"
                />
                <p
                    v-if="operation.account"
                    class="operation-card__chat-agent"
                >
                    {{ operation.account }}
                </p>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, Ref } from "vue";
import OperationStatus from "@components/atomic/operations/status/OperationStatus.vue";
import { Operation } from "@/repositories/v2/repository-operations/types";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import WithdrawalOperation from "@models/operations/WithdrawalOperation";

interface Emits {
    (e: 'toOperation'): void;
}

interface Props {
    operation: Operation;
    mainAmount: number;
    mainAmountAbbr: string;
    convertAmount: number;
    convertAmountText: string;
}

const emits = defineEmits<Emits>();

defineProps<Props>();

const componentRef: Ref<HTMLDivElement | null> = ref(null);

const toPayment = async () => {
    componentRef.value?.classList.add('active-state');

    emits('toOperation');

    setTimeout(() => {
        componentRef.value?.classList.remove('active-state');
    }, 100);
}
</script>

<style lang="scss">
.operation-card {
    position: relative;
    background-color: #FFFFFF;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 16px 16px 8.5px;

    &.active-state {
        background-color: #00000026;
    }

    & + .operation-card {
        &:before {
            content: '';
            position: absolute;
            background-color: var(--f7-list-border-color);
            display: block;
            z-index: 15;
            top: 0;
            right: auto;
            bottom: auto;
            left: 16px;
            height: 1px;
            width: calc(100% - 16px);
            transform-origin: 50% 0%;
            transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__content {
        display: inline-flex;
        align-items: center;
        gap: 0.5rem;

        img {
            width: 32px;
            height: 32px;
        }
    }

    &__amount {
        flex-direction: column;
        align-items: flex-start;
        display: inline-flex;
    }

    &__amount-main {
        @include text(16px, 700, #423636, 19.2px);
    }

    &__amount-convert {
        @include text(12px, 400, #818E95, 14.4px);
    }

    &__footer {
        margin-top: 8.5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 24px;
    }

    &__id {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #818E95;
        margin: 0rem;
        display: inline-flex;
        gap: 8px;
    }

    &__date {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #ACB4BE;
    }

    &__status {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: flex-start;
    }

    &__chat {
        display: flex;
        align-items: center;
        gap: 8px;

        .alert-message-svg {
            width: 29px;
            height: 20px;
        }
    }

    &__chat-agent {
        @include text(12px, 400, #818E95, 20px);
    }
}
</style>
