import {OperationType_N} from "@enums/OperationType_N";
import {Expose, Type} from "class-transformer"
import ProjectOperation from "@models/operations/components/ProjectOperation";
import CurrencyOperation from "@models/operations/components/CurrencyOperation";
import {BuyingOperationStatus} from "@enums/BuyingOperationStatus";
import dayjs from "dayjs";

export default class PaymentIncomeOperation {
    @Expose({name: 'id'})
    private _id: number | null = null;

    @Expose({name: 'project'})
    @Type(() => ProjectOperation)
    private _project: ProjectOperation | null = null;

    @Expose({name: 'extId'})
    private _extId: string | null = null;

    @Expose({name: 'msid'})
    private _msid: string | null = null;

    @Expose({name: 'amount'})
    private _amount: number | null = null;

    @Expose({name: 'fromAmount'})
    private _fromAmount: number | null = null;

    @Expose({name: 'fromRate'})
    private _fromRate: number | null = null;

    @Expose({name: 'fromCurrency'})
    @Type(() => CurrencyOperation)
    private _fromCurrency: CurrencyOperation | null = null;

    @Expose({name: 'operationType'})
    private _operationType: OperationType_N | null = null;

    @Expose({name: 'comment'})
    private _comment: string | null = null;

    @Expose({name: 'status'})
    private _status: BuyingOperationStatus | null = null;

    @Expose({name: 'createdAt'})
    private _createdAt: string | null = null;

    @Expose({name: 'updatedAt'})
    private _updatedAt: string | null = null;

    get id(): number | null {
        return this._id;
    }

    get extId(): string | null {
        return this._extId;
    }

    get msid(): string | null {
        return this._msid;
    }

    get amount(): number | null {
        return this._amount;
    }

    get project(): ProjectOperation | null {
        return this._project;
    }

    get fromRate(): number | null {
        return this._fromRate;
    }

    get comment(): string | null {
        return this._comment;
    }

    get status(): BuyingOperationStatus | null {
        return this._status;
    }

    get fromAmount(): number | null {
        return this._fromAmount;
    }

    get fromCurrency(): CurrencyOperation | null {
        return this._fromCurrency;
    }

    get operationType(): OperationType_N | null {
        return this._operationType;
    }

    get createdAt(): string | null {
        return this._createdAt;
    }

    get updatedAt(): string | null {
        return this._updatedAt;
    }

    public getHumanCreateAt() {
        return dayjs(this._createdAt).format('DD.MM.YYYY HH:mm');
    }
}
