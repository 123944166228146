import FirebasePushNotificationPayload from "@models/firebase/push-notification/FirebasePushNotificationPayload";
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import {FirebasePushNotificationType} from "@enums/firebase/push-notification/FirebasePushNotificationType";
import {OperationType} from "@enums/operation/OperationType";

export class FirebaseNotificationCordovaHandlePush {
    public async handle(funcCheckAuth: Function, payload: FirebasePushNotificationPayload) {
        if (funcCheckAuth()) {
            switch (payload.type) {
                case FirebasePushNotificationType.ClientBuyOrderStatusChangedNotification:
                    await this.handlePurchaseOperation(payload);
                    return;
                case FirebasePushNotificationType.ClientSellOrderStatusChangedNotification:
                    await this.handleWithdrawalOperation(payload);
                    return;
                case FirebasePushNotificationType.ClientTransferCompletedNotification:
                    await this.handleTransferOperation(payload);
                    return;
                case FirebasePushNotificationType.ClientNewIncomeNotification:
                    await this.handleIncomeOperation(payload);
                    return;
                case FirebasePushNotificationType.ClientNewChatMessageNotification:
                    await this.handleChatInsideOperation(payload);
                    return;
                default:
            }
        }
    }

    private async handleChatInsideOperation(payload: FirebasePushNotificationPayload) {
        const timeoutOpeningPage = 500;

        if (payload.operation && payload.operation.msid) {
            switch (payload.operation.type) {
                case OperationType.CLIENT_P2P_BUY:
                    await this.handlePurchaseOperation(payload);
                    setTimeout(async () => {
                        await AppController.getInstance().routerService.toPaymentChatOperationByMsid(
                            payload.operation!.msid!,
                            false
                        );
                    }, timeoutOpeningPage);
                    return;
                case OperationType.CLIENT_P2P_SELL:
                    await this.handleWithdrawalOperation(payload);
                    setTimeout(async () => {
                        await AppController.getInstance().routerService.toWithdrawalChatOperationByMsid(
                            payload.operation!.msid!,
                            false
                        );
                    }, timeoutOpeningPage);
                    return;
            }
        }
    }

    private async handleIncomeOperation(payload: FirebasePushNotificationPayload) {
        if (payload.operation && payload.operation.msid) {
            await AppController.getInstance().routerService.toIncomeOperationByMsid(payload.operation.msid);
        }
    }

    private async handleTransferOperation(payload: FirebasePushNotificationPayload) {
        if (payload.operation && payload.operation.msid) {
            await AppController.getInstance().routerService.toTransferOperationByMsid(payload.operation.msid);
        }
    }

    private async handlePurchaseOperation(payload: FirebasePushNotificationPayload, force: boolean = true) {
        if (payload.operation && payload.operation.msid) {
            await AppController.getInstance().routerService.toPaymentOperationByMsid(payload.operation.msid, force);
        }
    }

    private async handleWithdrawalOperation(payload: FirebasePushNotificationPayload, force: boolean = true) {
        if (payload.operation && payload.operation.msid) {
            await AppController.getInstance().routerService.toWithdrawalOperationByMsid(payload.operation.msid, force);
        }
    }
}