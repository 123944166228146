<template>
    <f7-popup ref="new-payment-popup" class="new-payment-popup" @popup:open="open"
              @popup:opened="opened" @popup:close="eventClosePopup">
        <span class="tint"></span>
        <f7-view url="/payment/create/config">
        </f7-view>
    </f7-popup>
</template>

<script lang="ts">

import PopupPaymentCreateController from "@components/popup/popup-payment-create/PopupPaymentCreateController";
import NewPaymentController from "@/views/payment-create-config/NewPaymentController";

export default {
    // props: ['paymentSystems'],
    components: {},
    setup() {

    },
    methods: {
        open() {
            // f7.views.create('.new-payment-view', {
            //     url: '/popup/new-payment'
            // });
        },
        opened() {
            const popupHTML = document.querySelector('.new-payment-popup');
            (popupHTML as HTMLDivElement).classList.add('transition');

            const func = PopupPaymentCreateController.of().popupOpenedFunction;
            if (func) func();
        },
        eventClosePopup(e: any) {
            this.close();
        },
        async close() {
            const popupHTML = document.querySelector('.new-payment-popup');
            (popupHTML as HTMLDivElement).classList.remove('transition');
            NewPaymentController.getInstance().destructor();
        }
    }
}
</script>

<style lang="scss">
.new-payment-popup {
    //height: calc(var(--f7-safe-area-bottom) + 322px) !important;
    bottom: 0px !important;
    top: unset !important;
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;

    .tint {
        width: 36px;
        height: 4px;
        position: absolute;
        top: 6px;
        z-index: 9999;
        background: #818E95;
        border-radius: 4px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }

    &.transition {
        transition: 0.4s ease height;
    }
}
.with-modal-popup {
    #app {
        .new-payment-popup {
            &.height-100 {
                height: calc(100% - var(--f7-safe-area-top)) !important;
            }
        }
    }
}

//.sheet-modal-new-payment {
//    border-top-left-radius: 16px;
//    border-top-right-radius: 16px;
//
//    .sheet-modal-inner {
//        border-top-left-radius: inherit;
//        border-top-right-radius: inherit;
//    }
//
//    .title {
//        font-weight: 700;
//        font-size: 22px;
//        line-height: 120%;
//        letter-spacing: 0.337647px;
//        color: #000000;
//    }
//}
</style>
