<template>
    <div class="operation-status-text">
        <p class="operation-status-text__title" v-html="title"></p>
        <p class="operation-status-text__description" v-html="text"></p>
        <p v-if="advice" class="operation-status-text__advice" v-html="advice"></p>
    </div>
</template>

<script setup lang="ts">
import {PropType, toRef} from "vue";

const props = defineProps({
    title: {
        type: [String] as PropType<string>,
        required: true
    },
    text: {
        type: [String] as PropType<string>,
        required: true
    },
    advice: {
        type: [String] as PropType<string>,
        required: false
    }
});
const title = toRef(props, 'title');
const text = toRef(props, 'text');
const advice = toRef(props, 'advice');
</script>

<style lang="scss">
.operation-status-text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    text-align: center;

    &__title {
        @include text(20px, 700, var(--brown1), 24px);
    }

    &__description {
        @include text(16px, 400, var(--grey2), 19.2px);
    }

    &__advice {
        @include text(16px, 400, var(--grey2), 19.2px);
    }
}
</style>
