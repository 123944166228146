import ServiceMainApi from "@/services/v2/service-main-api/ServiceMainApi";
import {OperationsLinks, OperationsMeta, Operations, Operation} from "@/repositories/v2/repository-operations/types";
import {OperationType_N} from "@enums/OperationType_N";
import {plainToInstance} from "class-transformer";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import WithdrawalOperation from "@models/operations/WithdrawalOperation";
import PaymentIncomeOperation from "@models/operations/PaymentIncomeOperation";
import WithdrawalWalletOperation from "@models/operations/withdrawal/wallet/WithdrawalWalletOperation";
import {validateOrReject} from "class-validator";

export default class RepositoryOperations {
    public async fetchOperations(payload: any): Promise<{
        operations: Operations,
        meta: OperationsMeta,
        links: OperationsLinks
    }> {
        const {data, meta, links} = await ServiceMainApi.of().get<{
            data: Record<string, any>[],
            meta: any,
            links: any
        }>('/api/mobile/client/operations', payload);

        const operations: Array<Operation | null> = await Promise.all(
            data.map(async (operation: Record<string, any>) => {
                const instanceOperation = this.createInstanceOperation(operation);

                if (instanceOperation !== null) {
                    await validateOrReject(instanceOperation);

                    return instanceOperation;
                }

                return null;
            })
        );

        const filteredOperations = [...operations].filter((operation: Operation | null) => operation !== null) as Operations;

        return {
            operations: filteredOperations,
            meta,
            links
        }
    }

    /**
     * Deprecated, use @repositories/v2/repository-operation/RepositoryOperation, from 27.02.2024
     * @param operation
     * @private
     */
    private createInstanceOperation(operation: Record<string, any>): Operation | null {
        if (operation.operationType === OperationType_N.purchase) {
            return plainToInstance(PurchaseOperation, operation, {
                excludeExtraneousValues: true,
                excludePrefixes: ['_']
            });
        }

        if (operation.operationType === OperationType_N.sale) {
            return plainToInstance(WithdrawalOperation, operation, {
                excludeExtraneousValues: true,
                excludePrefixes: ['_']
            });
        }

        if (operation.operationType === OperationType_N.income) {
            return plainToInstance(PaymentIncomeOperation, operation, {
                excludeExtraneousValues: true,
                excludePrefixes: ['_']
            });
        }

        if (operation.operationType === OperationType_N.clientTransfer) {
            return plainToInstance(WithdrawalWalletOperation, operation, {
                excludeExtraneousValues: true,
                excludePrefixes: ['_']
            });
        }

        return null;
    }
}
