<template>
    <div class="payment-amount-changed-modal hide">
        <div class="payment-amount-changed-modal_content">
            <i class="icon"></i>
            <p class="payment-amount-changed-modal_title">{{ $t("modals.payment-amount-was-changed.title") }}</p>
            <p class="payment-amount-changed-modal_text">{{ $t("modals.payment-amount-was-changed.text", {
                amount: paymentService.payment.value.amountOrigin,
                currency: paymentService.payment.value.currency.abbr
            }) }}</p>
        </div>
        <div class="payment-amount-changed-modal_footer">
            <f7-button
                class="theme-type"
                preloader
                @click="submit"
            >
                {{
                    $t("modals.payment-amount-was-changed.button", {
                        amount: paymentService.payment.value.amount,
                        currency: paymentService.payment.value.currency.abbr
                    })
                }}
            </f7-button>
        </div>
    </div>
</template>

<script setup lang="ts">
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import PaymentService from "@/services/operations/payment/PaymentService";

const paymentService = AppController.of().paymentService as PaymentService;

function submit() {
    closeModal();
}

function closeModal() {
    paymentService.hideAmountWasChangedModal();
    paymentService.isAmountWasChangedShowBlockWarning.value = true;
}

</script>

<style lang="scss">
.payment-amount-changed-modal {
    position: absolute;
    z-index: 13501;
    background: var(--white1);
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) scale(1);
    will-change: transform, opacity;
    transition-property: transform, opacity;
    transition-duration: 400ms;
    overflow: hidden;
    border-radius: 16px;
    display: block;
    width: calc(100% - 32px);
    max-width: 320px;
    padding: 40px 24px 16px;

    &.hide {
        transform: translate3d(-50%, -50%, 0) scale(1.185);
        opacity: 0;
        display: none;
    }

    &_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        i {
            width: 88px;
            height: 88px;
            background-image: url("data:image/svg+xml,%3Csvg width='89' height='88' viewBox='0 0 89 88' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle opacity='0.5' cx='44.5002' cy='43.9987' r='36.6667' fill='%23ACB4BE'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M48.1663 62.3317C48.1663 64.3544 46.5215 66 44.4997 66C42.4778 66 40.833 64.3544 40.833 62.3317C40.833 60.3108 42.4778 58.6667 44.4997 58.6667C46.5215 58.6667 48.1663 60.3108 48.1663 62.3317ZM48.1665 47.6695C48.1665 49.6945 46.5248 51.3362 44.4998 51.3362C42.4749 51.3362 40.8332 49.6945 40.8332 47.6695L40.8332 25.6695C40.8332 23.6446 42.4749 22.0029 44.4998 22.0029C46.5248 22.0029 48.1665 23.6446 48.1665 25.6695L48.1665 47.6695Z' fill='%23ACB4BE'/%3E%3C/svg%3E%0A");
        }
    }

    &_footer {
        margin-top: 24px;
        padding-top: 8px;
    }

    &_title {
        margin-top: 12px;
        @include text(20px, 600, var(--brown1), 24px);
    }

    &_text {
        margin-top: 8px;
        @include text(14px, 400, var(--brown1), 16.8px);
        text-align: center;
    }
}

.payment-amount-changed-modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    will-change: transform, opacity;
    transition-property: transform, opacity;
    transition-duration: 400ms;
    z-index: 11000;
    opacity: 0;
}
</style>