import { plainToInstance } from "class-transformer";
import { validateOrReject } from "class-validator";
import ServiceMainApi from "@/services/v2/service-main-api/ServiceMainApi";
import WithdrawalOperation from "@models/operations/WithdrawalOperation";
import { CreateWithdrawalPayload } from "./types";

export default class RepositoryWithdrawal {
    public async fetchWithdrawal(msid: string) {
        const response = await ServiceMainApi.of().get<{ data: object }>(`/api/mobile/client/sales/get/${msid}`);

        return this.plainToModel(response.data);
    }

    public async createWithdrawal(payload: CreateWithdrawalPayload): Promise<WithdrawalOperation> {
        const response = await ServiceMainApi.of().post<{ data: any }>('/api/mobile/client/sales/create', payload);

        return this.plainToModel(response.data);
    }

    public async confirmWithdrawal(msid: string): Promise<WithdrawalOperation> {
        const response = await ServiceMainApi.of().patch<{ data: object }>('/api/mobile/client/sales/confirm', { msid });

        return this.plainToModel(response.data);
    }

    public async cancelWithdrawal(msid: string): Promise<{ data: string, success: boolean }> {
        return await ServiceMainApi.of().patch<{ data: string, success: boolean }>('/api/mobile/client/sales/cancel', { msid });
    }

    public async appealWithdrawal(msid: string): Promise<WithdrawalOperation> {
        const response = await ServiceMainApi.of().patch<{ data: object }>('/api/mobile/client/sales/appeal', { msid });

        return this.plainToModel(response.data);
    }

    private async plainToModel(data: object): Promise<WithdrawalOperation> {
        const operation: WithdrawalOperation = plainToInstance(WithdrawalOperation, data, {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });

        await validateOrReject(operation);

        return operation;
    }
}
