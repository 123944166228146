import {ToRef} from "@vue/runtime-core";
import {ref} from "vue";
import WithdrawalOperation from "@models/operations/WithdrawalOperation";
import WithdrawalTimerService from "@/services/operations/withdrawal/WithdrawalTimerService";
import WithdrawalWalletOperation from "@models/operations/withdrawal/wallet/WithdrawalWalletOperation";
import RepositoryWithdrawal from "@/repositories/v2/repository-withdrawal/RepositoryWithdrawal";
import RepositoryWithdrawalWallet from "@/repositories/v2/repository-wallet-withdrawal/RepositoryWithdrawalWallet";
import {CreateWithdrawalPayload} from "@/repositories/v2/repository-withdrawal/types";

export default class WithdrawalService {
    private _withdrawal: ToRef<WithdrawalOperation | null> = ref(null);
    private _timerService: WithdrawalTimerService = new WithdrawalTimerService();
    private _isExternalCreated: ToRef<boolean> = ref(false);
    private _repositoryWithdrawalWallet: RepositoryWithdrawalWallet = new RepositoryWithdrawalWallet();
    private readonly _repositoryWithdrawal: RepositoryWithdrawal = new RepositoryWithdrawal();

    public async createWithdrawal(payload: CreateWithdrawalPayload) {
        this._withdrawal.value = await this._repositoryWithdrawal.createWithdrawal(payload);
    }

    public async createWalletWithdrawal(createWalletWithdrawalDto: CreateWithdrawalPayload): Promise<WithdrawalWalletOperation> {
        return await this._repositoryWithdrawalWallet.createWithdrawalWallet(createWalletWithdrawalDto);
    }

    //TODO скорее всего излишний, т.к есть fetchPayment
    public async updateWithdrawal() {
        this.checkMSID();

        this._withdrawal.value = await this._repositoryWithdrawal.fetchWithdrawal(this._withdrawal.value!.msid!);
    }

    public async toAppealWithdrawal() {
        this.checkMSID();

        this._withdrawal.value = await this._repositoryWithdrawal.appealWithdrawal(this._withdrawal.value!.msid!);
    }

    public async fetchWithdrawal( msid: string) {
        const operation = await this._repositoryWithdrawal.fetchWithdrawal(msid);
        this._withdrawal.value = operation;
    }

    public async cancelWithdrawal() {
        this.checkMSID();

        await this._repositoryWithdrawal.cancelWithdrawal(this._withdrawal.value!.msid!);
    }

    public async confirmWithdrawal() {
        this.checkMSID();

        this._withdrawal.value = await this._repositoryWithdrawal.confirmWithdrawal(this._withdrawal.value!.msid!);
    }

    public reset() {
        this._withdrawal.value = null;
        this._timerService.stopIntervals();
    }

    async checkWithdrawalTransactionStatus() {
        try {
            if (this._withdrawal.value === null) return false;

            await this.fetchWithdrawal(this._withdrawal.value!.msid!);
        } catch (e: any) {
            throw new Error(`Код ответа: ${e.code}\nСообщение: ${e.message}`);
        }
    }

    set withdrawal(value: WithdrawalOperation | null) {
        this._withdrawal.value = value;
    }

    get withdrawal(): ToRef<WithdrawalOperation | null> {
        return this._withdrawal;
    }

    get timerService(): WithdrawalTimerService {
        return this._timerService;
    }

    get isExternalCreated(): ToRef<boolean> {
        return this._isExternalCreated;
    }

    set isExternalCreated(value: ToRef<boolean>) {
        this._isExternalCreated = value;
    }

    private checkMSID() {
        if (this._withdrawal.value === null) throw new Error(`[${this.constructor.name}]: payment is null`);
        if (this._withdrawal.value.msid === null) throw new Error(`[${this.constructor.name}]: payment msid is null`);
    }

    private checkCreateWithdrawal() {
        if (this._withdrawal.value === null) throw new Error(`[${this.constructor.name}]: payment is null`);
        if (this._withdrawal.value.msid === null) throw new Error(`[${this.constructor.name}]: payment msid is null`);
        // if (this._withdrawal.value.psystemObj === null) throw new Error(`[${this.constructor.name}]: payment psystemObj is null`);
        // if (this._withdrawal.value.psystemObj.id === null) throw new Error(`[${this.constructor.name}]: payment psystemObj.id is null`);
        if (this._withdrawal.value.currency === null) throw new Error(`[${this.constructor.name}]: payment currency is null`);
        if (this._withdrawal.value.currency.id === null) throw new Error(`[${this.constructor.name}]: payment currency.id is null`);
    }
}

export type createWithdrawalPayload = {
    amount: number,
    requisite_id: number
}
