import {Expose, Type} from "class-transformer";
import FirebasePushNotificationOperation from "@models/firebase/push-notification/FirebasePushNotificationOperation";

export default class FirebasePushNotificationPayload {
    @Expose({name: 'type'})
    private _type: string | null = null;
    @Expose({name: 'clientId'})
    private _clientId: number | null = null;
    @Expose({name: 'operation'})
    @Type(() => FirebasePushNotificationOperation)
    private _operation: FirebasePushNotificationOperation | null = null;

    get clientId(): number | null {
        return this._clientId;
    }
    get type(): string | null {
        return this._type;
    }
    get operation(): FirebasePushNotificationOperation | null {
        return this._operation;
    }
}