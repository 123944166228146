import {
    FetchOperationPayload,
    RepositoryFetchOperationInterface
} from "@/services/v2/data/service-fetch-operation/ServiceFetchOperation";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import {plainToInstance} from "class-transformer";
import {validateOrReject} from "class-validator";
import ServiceMainApi from "@/services/v2/service-main-api/ServiceMainApi";
import {OperationType} from "@enums/operation/OperationType";
import WithdrawalOperation from "@models/operations/WithdrawalOperation";
import PaymentIncomeOperation from "@models/operations/PaymentIncomeOperation";
import WithdrawalWalletOperation from "@models/operations/withdrawal/wallet/WithdrawalWalletOperation";

type ResultOperationType = 'CLIENT_P2P_BUY' | 'CLIENT_P2P_SELL' | 'CLIENT_TRANSFER' | 'CLIENT_INCOME';


export default class RepositoryFetchOperation implements RepositoryFetchOperationInterface {
    public async fetchOperation(payload: FetchOperationPayload) {
        const response = await ServiceMainApi.of().get<{ data: any }>('/api/mobile/client/operations/get', payload);
        return this.plainToModel(payload.type, response.data);
    }

    private async plainToModel(operationType: OperationType, data: object): Promise<ResultOperationType> {
        const operationTypeMap: Record<string, any> = {
            'client_p2p_buy': PurchaseOperation,
            'client_p2p_sell': WithdrawalOperation,
            'client_income': PaymentIncomeOperation,
            'client_transfer': WithdrawalWalletOperation,
        };

        const type = operationTypeMap[operationType] ?? null;

        if (type == null) {
            throw new Error("Unknown type of operation");
        }

        const operation = plainToInstance(type, data, {
            excludeExtraneousValues: true,
            excludePrefixes: ['_']
        });

        // @ts-ignore
        await validateOrReject(operation);
        // @ts-ignore
        return operation;
    }
}