<template>
    <div class="block-warning">
        <i class="icon svg-warning"></i>
        <p>{{ message }}</p>
    </div>
</template>

<script setup lang="ts">
import {PropType, ToRef, toRef} from "vue";

const props = defineProps({
    message: {
        type: Object as PropType<string>,
        required: true
    },
});

const message: ToRef<any> = toRef(props, "message");
</script>

<style lang="scss">
.block-warning {
    display: flex;
    background: rgba(230, 151, 0, 0.1);
    border-radius: 18px;
    padding: 12px;
    color: rgba(207, 117, 41, 1);
    gap: 12px;
    margin: 24px 0;
    align-items: center;

    i {
        min-width: 18px;
    }

    p {
        @include text(14px, 400, #CF7529, 16.8px);
    }
}
</style>