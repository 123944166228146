import WithdrawalWalletOperation from "@models/operations/withdrawal/wallet/WithdrawalWalletOperation";
import {ref, ToRef} from "vue";
import {PageOpenIn} from "@enums/PageOpenIn";
import RepositoryWithdrawalWallet from "@/repositories/v2/repository-wallet-withdrawal/RepositoryWithdrawalWallet";
import {value} from "dom7";

export default class WithdrawalWalletPageController {
    private static _instance: WithdrawalWalletPageController | null = null;
    private _withdrawal: ToRef<WithdrawalWalletOperation | null> = ref(null);
    private _repositoryWithdrawalWallet: RepositoryWithdrawalWallet = new RepositoryWithdrawalWallet();
    private _pageOpenIn: PageOpenIn = PageOpenIn.PAGE;

    public static of() {
        if (this._instance === null) this._instance = new WithdrawalWalletPageController();
        return this._instance;
    }

    get withdrawal(): ToRef<WithdrawalWalletOperation | null> {
        return this._withdrawal;
    }

    set withdrawal(value: WithdrawalWalletOperation | null) {
        this._withdrawal.value = value;
    }

    set pageOpenIn(value: PageOpenIn) {
        this._pageOpenIn = value;
    }

    get pageOpenIn(): PageOpenIn {
        return this._pageOpenIn;
    }

    async updateWithdrawal() {
        this._withdrawal.value = await this._repositoryWithdrawalWallet.fetchWithdrawalWallet(this._withdrawal.value!.msid);
    }

    async init(walletOperation: WithdrawalWalletOperation, pageOpenIn: PageOpenIn) {
        this._pageOpenIn = pageOpenIn;
        this._withdrawal.value = walletOperation;
    }
}
