<template>
    <OperationCard
        :operation="operation"
        @toOperation="toOperation"
        :mainAmount="operation.amount"
        :mainAmountAbbr="operation.currency.abbr"
        :convertAmount="operation.amountUsd"
        :convertAmountText="getOperationTypeText()"
    >
        <template v-if="!isIncludedSlugs" #icon>
            <i class="icon logo-round-plus-svg"></i>
        </template>
    </OperationCard>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import ModelAccount from "@models/v2/account/ModelAccount";
import PurchaseOperation from "@models/operations/PurchaseOperation";
import OperationCard from "@components/atomic/operations/cards/atomic/OperationCard.vue";

const { t } = useI18n({useScope: 'global'});

interface Emits {
    (e: 'toOperation'): void,
}

interface Props {
    operation: PurchaseOperation;
    account: ModelAccount;
}

const emits = defineEmits<Emits>();

const props = defineProps<Props>();

const slugs = ["olimp", "olimp-casino"];

const isIncludedSlugs = computed(() => {
    return slugs.includes(String(props.account.project.slug));
});

const toOperation = async () => {
    emits('toOperation');
}

function getOperationTypeText() {
    switch (props.operation.operationType) {
        case "purchase":
            if (isIncludedSlugs.value) {
                return t('payment.pay');
            }
            return t('payment.purchase');
        case "sale":
            return t('payment.sale');
    }
    return "";
}
</script>

<style lang="scss">
</style>
