<template>
    <f7-page
        class="payment-income_page"
        name="payment"
        :page-content="false"
    >
        <PaymentIncomeViewNavbar @click="closePageHandler" :payment="payment"/>
        <f7-page-content>
            <PaymentIncomeStatusConfirmed :payment="payment"/>
        </f7-page-content>

        <div class="bottom-block" ref="bottom-block">
            <div class="row-actions">
                <f7-button fill class="primary" @click="closePageHandler">{{ $t("payment.close") }}</f7-button>
            </div>
        </div>
    </f7-page>
</template>

<script lang="ts" setup>
import {f7} from "framework7-vue";
import PaymentIncomeViewNavbar from "@/views/payment-income/components/PaymentIncomeViewNavbar.vue";
import PaymentIncomeStatusConfirmed from "@/views/payment-income/components/PaymentIncomeStatusConfirmed.vue";
import ServicePaymentIncome from "@/services/v2/data/service-payment-income/ServicePaymentIncome";
//@ts-ignore
import AppController from "@target/components/App/ts/AppController";

const payment = AppController.of().service<ServicePaymentIncome>('paymentIncome')?.income;

const closePageHandler = () => {
    f7.view.main.router.back('/payments', {force: true, clearPreviousHistory: true});
}
</script>

<style lang="scss">
.payment-income_page {
    .page-content {
        background: #FFF;
        padding-top: calc(var(--f7-page-navbar-offset, 0px) + var(--f7-page-toolbar-top-offset, 0px) + var(--f7-page-subnavbar-offset, 0px) + var(--f7-page-searchbar-offset, 0px) + var(--f7-page-content-extra-padding-top, 0px)) !important;
        --f7-page-content-extra-padding-bottom: 94px;
    }

    &::-webkit-scrollbar {
        width: 0;
    }

    .row-actions {
        width: 100%;
    }
}
</style>

