import {f7} from "framework7-vue";
import RequisitesPage from "@/views/requisites-group/requisites/RequisitesPage.vue";
import Requisite from "@models/requisite/Requisite";
import RequisitesPageController from "@/views/requisites-group/requisites/RequisitesPageController";
// import RequisitesController, {OpenIn} from "@/views/requisites-group/RequisitesController";
import RequisitePage from "@/views/requisites-group/requisite/RequisitePage.vue";
import RequisitePageController from "@/views/requisites-group/requisite/RequisitePageController";
import RequisitePsystemPageController
    from "@/views/requisites-group/select-psystem/RequisitePsystemPageController";
import SelectPSystemPage from "@/views/requisites-group/select-psystem/SelectPSystemPage.vue";
import {PageOpenIn} from "@enums/PageOpenIn";
import WithdrawalWalletCreatedPageController
    from "@/views/withdrawal-group/wallet/wallet-created/WithdrawalWalletCreatedPageController";
import WithdrawalWalletOperation from "@models/operations/withdrawal/wallet/WithdrawalWalletOperation";
import WithdrawalCreatePage from "@/views/withdrawal-popup-group/withdrawal-create/WithdrawalCreatePage.vue";
import WithdrawalWalletCreatedPage
    from "@/views/withdrawal-group/wallet/wallet-created/WithdrawalWalletCreatedPage.vue";
import CreateWalletWithdrawalDto from "@/services/operations/withdrawal/dto/CreateWalletWithdrawalDto";
import WithdrawalWalletPageController from "@/views/withdrawal-group/wallet/wallet/WithdrawalWalletPageController";
import WithdrawalWalletPage from "@/views/withdrawal-group/wallet/wallet/WithdrawalWalletPage.vue";

import ViewPaymentCreateAgents from "@/views/payment-create-agents/ViewPaymentCreateAgents.vue";
import ViewPaymentCreateAgentsController from "@/views/payment-create-agents/ViewPaymentCreateAgentsController";
import LightPaymentPidResponseError from "@models/responses/light-payment-pid/LightPaymentPidResponseError";
import NewPaymentController from "@/views/payment-create-config/NewPaymentController";
import PopupPaymentCreateController from "@components/popup/popup-payment-create/PopupPaymentCreateController";
import Agent from "@models/agents/Agent";
import ReleazioPostsPage from "@/views/releazio-posts/ReleazioPostsPage.vue";
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import ServiceReleazio from "@/services/v2/service-releazio/ServiceReleazio";
import ViewTech from "@/views/v2/view-tech/ViewTech.vue";
import ReleazioPostsPageController from "@/views/releazio-posts/ReleazioPostsPageController";
import PaymentService, {fetchPaymentPayload} from "@/services/operations/payment/PaymentService";
import ErrorsService from "@/services/errors-service/ErrorsService";
import ServiceFetchOperation from "@/services/v2/data/service-fetch-operation/ServiceFetchOperation";
import {OperationType} from "@enums/operation/OperationType";
import WithdrawalService from "@/services/operations/withdrawal/WithdrawalService";
import ServicePaymentIncome from "@/services/v2/data/service-payment-income/ServicePaymentIncome";
import {getDevice} from "framework7/shared/get-device";
import {BuyingOperationStatus} from "@enums/BuyingOperationStatus";

export default class RouterService {
    public async toTechPage() {
        if (typeof f7.views.current.routes.find((r) => r.name === 'settings/tech') === 'undefined') {
            f7.views.current.routes.push({
                name: "settings/tech",
                path: '/settings/tech',
                component: ViewTech,
            });
        }
        f7.views.current.router.navigate("/settings/tech");
    }

    private checkCurrentViewIs(path: string) {
        return f7.views.current.router.currentRoute.path === path;
    }

    private checkHigherViewIs(path: string) {
        // @ts-ignore
        return f7.views.higher.router.currentRoute.path === path;
    }

    private async closeAllWithReturnPayments() {
        f7.popup.close();
        f7.dialog.close();
        f7.sheet.close();
        // @ts-ignore
        // f7.views.higher.router.back("/higher");

        if (f7.views.current.router.currentRoute.path !== "/payments") {
            f7.views.current.router.back('/payments', {
                force: true,
                reloadCurrent: true
            });
        }
    }

    public async toPayments() {
        f7.view.main.router.back('/payments', {force: true, clearPreviousHistory: true});
    }

    public async toPaymentChatOperationByMsid(msid: string, force: boolean = true) {
        if (
            this.checkCurrentViewIs("/chat") &&
            (AppController.getInstance().paymentService as PaymentService).payment.value?.msid === msid
        ) {
            return false;
        }

        if (force) {
            // @ts-ignore
            await this.closeKeyboardFocusInput(async () => {
                await this.closeAllWithReturnPayments();
            });
        }

        try {
            f7.preloader.show();
            // @ts-ignore
            f7.views.higher.router.navigate('/chat', {
                openIn: 'popup'
            });
        } catch (e) {
            await ErrorsService.of().handle(e);
        } finally {
            f7.preloader.hide();
        }
    }

    public async toWithdrawalChatOperationByMsid(msid: string, force: boolean = true) {
        if (
            this.checkCurrentViewIs("/chat-withdrawal") &&
            (AppController.getInstance().withdrawalService as WithdrawalService).withdrawal.value?.msid === msid
        ) {
            return false;
        }

        if (force) {
            // @ts-ignore
            await this.closeKeyboardFocusInput(async () => {
                await this.closeAllWithReturnPayments();
            });
        }

        try {
            f7.preloader.show();
            // @ts-ignore
            f7.views.higher.router.navigate('/chat-withdrawal', {
                openIn: 'popup'
            });
        } catch (e) {
            await ErrorsService.of().handle(e);
        } finally {
            f7.preloader.hide();
        }
    }

    public async toPaymentOperationByMsid(msid: string, force: boolean = true) {

        if (
            (this.checkCurrentViewIs("/payment") || this.checkCurrentViewIs("/chat")) &&
            (AppController.getInstance().paymentService as PaymentService).payment.value?.msid === msid
        ) {
            return false;
        }
        if (force) {
            // @ts-ignore
            await this.closeKeyboardFocusInput(async () => {
                await this.closeAllWithReturnPayments();
            });
        }

        try {
            f7.preloader.show();
            const operation = await (AppController.of().service("fetchOperation") as ServiceFetchOperation).fetchOperation({
                type: OperationType.CLIENT_P2P_BUY,
                msid
            });
            const paymentService = (AppController.getInstance().paymentService as PaymentService);
            paymentService.payment = operation;

            if (paymentService.payment.value?.status === BuyingOperationStatus.ACCEPTED) {
                if (paymentService.isAmountWasChanged) {
                  paymentService.isAmountWasChangedModalWasShowed.value = true;
                    paymentService.isAmountWasChangedShowBlockWarning.value = true;
                }
            }

            f7.views.main.router.navigate('/payment');
        } catch (e) {
            await ErrorsService.of().handle(e);
        } finally {
            f7.preloader.hide();
        }
    }

    public async toWithdrawalOperationByMsid(msid: string, force: boolean = true) {
        if (
            (this.checkCurrentViewIs("/withdrawal") || this.checkCurrentViewIs("/chat-withdrawal")) &&
            (AppController.getInstance().withdrawalService as WithdrawalService).withdrawal.value?.msid === msid
        ) {
            return false;
        }

        if (force) {
            // @ts-ignore
            await this.closeKeyboardFocusInput(async () => {
                await this.closeAllWithReturnPayments();
            });
        }

        try {
            f7.preloader.show();
            const operation = await (AppController.of().service("fetchOperation") as ServiceFetchOperation).fetchOperation({
                type: OperationType.CLIENT_P2P_SELL,
                msid
            });
            (AppController.getInstance().withdrawalService as WithdrawalService).withdrawal = operation;
            f7.views.main.router.navigate('/withdrawal');
        } catch (e) {
            await ErrorsService.of().handle(e);
        } finally {
            f7.preloader.hide();
        }
    }

    public async toTransferOperationByMsid(msid: string, force: boolean = true) {
        if (this.checkCurrentViewIs("/withdrawal-wallet")) {
            return false;
        }

        if (force) {
            // @ts-ignore
            await this.closeKeyboardFocusInput(async () => {
                await this.closeAllWithReturnPayments();
            });
        }

        try {
            f7.preloader.show();
            const operation = await (AppController.of().service("fetchOperation") as ServiceFetchOperation)
                .fetchOperation({
                    type: OperationType.CLIENT_TRANSFER,
                    msid
                });

            WithdrawalWalletPageController.of().withdrawal = operation;
            f7.views.current.router.navigate("/withdrawal-wallet");
        } catch (e) {
            await ErrorsService.of().handle(e);
        } finally {
            f7.preloader.hide();
        }
    }

    public async toIncomeOperationByMsid(msid: string, force: boolean = true) {
        if (this.checkCurrentViewIs("/payment/income")) {
            return false;
        }

        if (force) {
            // @ts-ignore
            await this.closeKeyboardFocusInput(async () => {
                await this.closeAllWithReturnPayments();
            });
        }

        try {
            f7.preloader.show();

            const operation = await (AppController.of().service("fetchOperation") as ServiceFetchOperation)
                .fetchOperation({
                    type: OperationType.CLIENT_INCOME,
                    msid
                });

            (AppController.of().service('paymentIncome') as ServicePaymentIncome).income = operation;
            f7.views.current.router.navigate('/payment/income');
        } catch (e) {
            await ErrorsService.of().handle(e);
        } finally {
            f7.preloader.hide();
        }
    }

    public async toReleazioPostPage() {
        const serviceReleazio = AppController.of().service<ServiceReleazio>("releazio");
        if (!serviceReleazio || serviceReleazio.activeConfig.value === null) return;
        if (!serviceReleazio.hasVersionPost()) return;

        ReleazioPostsPageController.of().openPost = true;
        serviceReleazio.readViewPost();

        if (typeof f7.views.current.routes.find((r) => r.name === 'releazio-posts') === 'undefined') {
            f7.views.current.routes.push({
                name: "releazio-post",
                path: '/releazio-post',
                component: ReleazioPostsPage,
            });
        }
        f7.views.current.router.navigate("/releazio-post");
    }

    public async toReleazioPostsPage() {
        const serviceReleazio = AppController.of().service<ServiceReleazio>("releazio");
        if (!serviceReleazio || serviceReleazio.activeConfig.value === null) return;
        if (!serviceReleazio.hasVersionPost()) return;

        if (!serviceReleazio.isViewedLastPost.value) {
            ReleazioPostsPageController.of().openPost = true;
            serviceReleazio.readViewPost();
        } else {
            ReleazioPostsPageController.of().openPost = false;
        }

        if (typeof f7.views.current.routes.find((r) => r.name === 'releazio-posts') === 'undefined') {
            f7.views.current.routes.push({
                name: "releazio-post",
                path: '/releazio-post',
                component: ReleazioPostsPage,
            });
        }
        f7.views.current.router.navigate("/releazio-post")
    }

    public async toWithdrawalWalletCreatedPage(
        createWalletWithdrawalDto: CreateWalletWithdrawalDto,
        pageOpenIn: PageOpenIn = PageOpenIn.POPUP,
        callback: Function = async () => {
        }
    ): Promise<void> {
        if (typeof f7.views.current.routes.find((r) => r.name === 'withdrawal-wallet-created') === 'undefined') {
            f7.views.current.routes.push({
                name: "withdrawal-wallet-created",
                path: '/withdrawal-wallet-created',
                component: WithdrawalWalletCreatedPage,
            });
        }
        const controller = WithdrawalWalletCreatedPageController.of();
        await controller.init(createWalletWithdrawalDto, pageOpenIn);
        await callback();
        f7.views.current.router.navigate("/withdrawal-wallet-created");
    }

    public async toRequisitesPage(
        pageOpenIn: PageOpenIn = PageOpenIn.PAGE,
        callback: Function = async () => {
        }
    ): Promise<void> {
        if (typeof f7.views.current.routes.find((r) => r.name === 'requisites') === 'undefined') {
            f7.views.current.routes.push({
                name: "requisites",
                path: '/requisites',
                component: RequisitesPage,
            });
        }
        const controller = RequisitesPageController.of();
        await controller.init(pageOpenIn);
        await callback();
        f7.views.current.router.navigate("/requisites");
    }

    public async toRequisitePage(requisite: Requisite | null, pageOpenIn: PageOpenIn = PageOpenIn.PAGE): Promise<void> {
        if (typeof f7.views.current.routes.find((r) => r.name === 'requisite') === 'undefined') {
            f7.views.current.routes.push({
                name: 'requisite',
                path: '/requisite',
                component: RequisitePage,
            });
        }
        const controller = RequisitePageController.of();
        await controller.init(requisite ? requisite.clone() : null, pageOpenIn);
        f7.views.current.router.navigate({name: 'requisite'});
    }

    public async toRequisitePsystemPage(pageOpenIn: PageOpenIn = PageOpenIn.PAGE): Promise<void> {
        if (typeof f7.views.current.routes.find((r) => r.name === 'requisite-select-psystem') === 'undefined') {
            f7.views.current.routes.push({
                name: 'requisite-select-psystem',
                path: '/requisite-select-psystem',
                component: SelectPSystemPage,
            });
        }
        const controller = RequisitePsystemPageController.of();
        await controller.init(RequisitePageController.of(), pageOpenIn);
        f7.views.current.router.navigate({name: 'requisite-select-psystem'});
    }

    // public toCreateRequisiteView(): void {
    //     RequisitesController.getInstance().setOpenIn(OpenIn.page);
    //     f7.views.current.router.navigate({path: '/settings/requisites/requisite'});
    // }

    // public toRequisiteView(requisite: Requisite): void {
    //     RequisitesController.getInstance().setOpenIn(OpenIn.page);
    //     const requisitePs = RequisitesPageController.getInstance();
    //     requisitePs.setRequisitePayload(requisite);
    //     f7.view.current.router.navigate('/settings/requisites/requisite');
    // }

    public async toCreatePaymentPopup(
        popupOpenedFunction: Function | null = null
    ) {
        if (popupOpenedFunction) PopupPaymentCreateController.of().popupOpenedFunction = popupOpenedFunction;
        f7.views.main.router.navigate('/popup/payment/create')
    }

    public async toViewPaymentCreateAgents(
        agent: Agent | null,
        amount: number | null,
        paymentError: LightPaymentPidResponseError | null,
        setAgentFunc: Function | null
    ) {
        ViewPaymentCreateAgentsController.of().paymentError.value = paymentError;
        ViewPaymentCreateAgentsController.of().amount.value = amount;
        ViewPaymentCreateAgentsController.of().agent.value = agent;
        ViewPaymentCreateAgentsController.of().setExternalSetAgentFunc(setAgentFunc);
        if (typeof f7.views.current.routes.find((r) => r.name === 'view-payment-create-agents') === 'undefined') {
            f7.views.current.routes.push({
                name: 'view-payment-create-agents',
                path: '/payment/create/agents',
                component: ViewPaymentCreateAgents,
            });
        }
        f7.views.current.router.navigate('/payment/create/agents');
    }

    public toBack(): void {
        f7.views.current.router.back();
    }

    public async closeKeyboardFocusInput(callback: Function) {
        return new Promise((resolve) => {
            if (getDevice().cordova) {
                // @ts-ignore
                f7.views.main.router.currentPageEl.querySelectorAll('input, textarea').forEach(element => {
                    element.blur();
                });
                // @ts-ignore
                document.querySelector(".popup-router-open-in")?.querySelectorAll('input, textarea').forEach(element => {
                    // @ts-ignore
                    element.blur();
                });

                setTimeout(async () => {
                    await callback();
                    resolve(null);
                }, 314);
            } else {
                callback();
                resolve(null);
            }
        });
    }
}
