import {Expose} from "class-transformer";

export default class FirebasePushNotificationOperation {
    @Expose({name: 'type'})
    private _type: string | null = null;
    @Expose({name: 'msid'})
    private _msid: string | null = null;
    @Expose({name: 'id'})
    private _id: string | null = null;
    @Expose({name: 'state'})
    private _state: string | null = null;

    get state(): string | null {
        return this._state;
    }
    get id(): string | null {
        return this._id;
    }
    get msid(): string | null {
        return this._msid;
    }
    get type(): string | null {
        return this._type;
    }
}