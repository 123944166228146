<template>
    <f7-page ref="self" :page-content="false" class="change-lang-page">
        <f7-navbar>
            <f7-nav-left>
                <a href="#" class="link icon-only" @click="back">
                    <i class="icon-back icon"></i>
                </a>
            </f7-nav-left>
            <f7-nav-title>
                {{ $t("g.settings.selectLanguage.title") }}
            </f7-nav-title>
            <f7-nav-right></f7-nav-right>
        </f7-navbar>
        <f7-page-content>
            <f7-list class="languages list-dividers list-strong list-outline">
                <f7-list-item @change="update" radio radio-icon="end" :title="$t('g.settings.selectLanguage.languages.ru')" value="ru"
                    name="currency" :checked="account.settings.localization == 'ru'"></f7-list-item>
                <f7-list-item @change="update" radio radio-icon="end" :title="$t('g.settings.selectLanguage.languages.en')"
                    value="en" name="currency" :checked="account.settings.localization == 'en'"></f7-list-item>
            </f7-list>
        </f7-page-content>
    </f7-page>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n'
import { f7 } from 'framework7-vue';
import { defineComponent } from "@vue/runtime-core";
import LangService from '@/services/LangService';
import ServiceAccount from "@/services/v2/data/service-account/ServiceAccount";
import ModelAccount from "@models/v2/account/ModelAccount";
import { Ref } from "vue";
import ErrorsService from "@/services/errors-service/ErrorsService";

export default defineComponent({
    setup() {
        const { t } = useI18n({ useScope: 'global' });
        const account = ServiceAccount.of().account as Ref<ModelAccount>;

        return {
            t,
            account
        }
    },
    methods: {
        async update(e: any) {
            try {
                f7.preloader.show();
                await ServiceAccount.of().patchAccount({localization: e.target.value})
                LangService.getInstance().set(e.target.value);
                f7.view.main.router.back();
            } catch (e: any) {
                await ErrorsService.of().handle(e);
            } finally {
                f7.preloader.hide();
            }
        },
        back() {
            f7.view.main.router.back();
        }
    }
});
</script>

<style lang="scss">
.change-lang-page {
    .title-wrapper {
        align-items: center;
        margin: 27px 16px 5px;
        display: flex;
        justify-content: flex-start;
        gap: 8px;
        cursor: pointer;

        .title {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-transform: uppercase;
            color: #818E95;
        }
    }
    .form {
        .input-wrapper  {
            position: relative;
            &::after {
                content: '';
                position: absolute;
                background-color: var(--grey4);
                display: block;
                z-index: 15;
                top: auto;
                right: 0px;
                bottom: 0;
                left: auto;
                height: 1px;
                width: 100%;
                transform-origin: 50% 100%;
                transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
            }
            &::before {
                content: '';
                position: absolute;
                background-color: var(--grey4);
                display: block;
                z-index: 15;
                top: 0;
                right: 0px;
                bottom: auto;
                left: auto;
                height: 1px;
                width: 100%;
                transform-origin: 50% 100%;
                transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
            }
            .short-name {
                width: 100%;
                padding: 12px 16px;
                background-color: #FFF;
                height: 44px;
                @include text(16px, 400, var(--brown1));
                position: relative;

                &::placeholder {
                    color: #BFBFBF;
                }
            }
        }
        .hint {
            padding: 8px 16px 0px;
            @include text(14px, 400, #818E95, 16.8px);
        }

        & + .block {
            margin-top: 40px;
        }
    }
}
</style>
