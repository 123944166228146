<template>
    <list-item-with-title
        :title="$t('requisite.crypto-way-input.title')"
        :media="requisite.psystem.imgPath!"
        v-model:text="requisite.psystem.name!"
        :loading="loading"
        :link="true"
        :showChevron="canEditPsystem"
        @on-item-click="handleItemClick"
    />
    <list-input-with-title
        ref="addressEl"
        :disabled="requisite.id != -1"
        :title="$t('requisite.target-types.wallet.address-input.title')"
        :placeholder="$t('requisite.target-types.wallet.address-input.placeholder')"
        v-model:text="requisite.address"
        :textarea="true"
        :required="true"
        :hint="hintBySysName"
    />
</template>

<script setup lang="ts">
import ListItemWithTitle from "@components/atomic/list-item/ListItemWithTitle.vue";
import {computed, PropType, ref, toRef, watch} from "vue";
import Requisite from "@models/requisite/Requisite";
import ListInputWithTitle from "@components/atomic/list-item/ListInputWithTitle.vue";
import {useI18n} from "vue-i18n";
const props = defineProps({
    requisite: {
        type: Object as PropType<Requisite>,
        required: true
    },
    loading: {
        type: Object as PropType<boolean>,
        required: false
    }
});
const addressEl = ref(null);
const requisite = defineModel<Requisite>("requisite", {
    required: true
});
const canEditPsystem = computed(() => {
    return requisite.value.id === -1;
});
const { t } = useI18n({ useScope: 'global' });

const hintBySysName = computed(() => {
    return t(`requisite.target-types.wallet.address-input.hints.${requisite.value.psystem.sysName}`);
});

const loading = toRef(props, "loading");
const emits = defineEmits(["toSelectPsystem"]);
defineExpose({addressEl})
function handleItemClick() {
    if (requisite.value.id !== -1) {
        return;
    }
    emits("toSelectPsystem");
}
</script>

<style scoped lang="scss">

</style>
