<template>
    <OperationCard
        :operation="operation"
        @toOperation="toOperation"
        :mainAmount="Math.round(operation.fromAmount)"
        :mainAmountAbbr="operation.fromCurrency.abbr"
        :convertAmount="operation.amount"
        :convertAmountText="$t('payment.income.operation-text')"
    />
</template>

<script setup lang="ts">
import PaymentIncomeOperation from "@models/operations/PaymentIncomeOperation";
import OperationCard from "@components/atomic/operations/cards/atomic/OperationCard.vue";

interface Emits {
    (e: 'toOperation'): void,
}

interface Props {
    operation: PaymentIncomeOperation
}

const emits = defineEmits<Emits>();

defineProps<Props>();

const toOperation = async () => {
    emits('toOperation');
}
</script>
