import { f7 } from 'framework7-vue';
import { autobind } from "@/decorators/autobind";
import ViewController from "@/interfaces/ViewController";
import AppController from "@/targets/integration/components/App/ts/AppController";
import ServiceAccount from "@/services/v2/data/service-account/ServiceAccount";
import ServiceOperations from "@/services/v2/data/service-operations/ServiceOperations";

export default class PaymentsViewController implements ViewController {
    private static _instance: PaymentsViewController | null = null;

    public static of() {
        if (PaymentsViewController._instance === null) {
            PaymentsViewController._instance = new PaymentsViewController();
        }
        return PaymentsViewController._instance;
    }

    @autobind
    async updateAccountData() {
        try {
            const routesWithUpdateAccount = ['/withdrawal', '/payment', '/settings'];
            if (routesWithUpdateAccount.includes(f7.views.current.router.previousRoute.path)) {
                await ServiceAccount.of().fetchAccount();
            }
        } catch (e) {
            await AppController.getInstance().logout();
        }
    }

    @autobind
    async updateMyPayments() {
        await ServiceOperations.of().fetchOperations({ refresh: true })
    }

    @autobind
    async updateMyAccount() {
        await ServiceAccount.of().fetchAccount();
    }

    destructor() {
        PaymentsViewController._instance = null;
    }
}
