import { Expose } from "class-transformer";

export default class DestinationOperation {
    @Expose({name: 'id'})
    private readonly _id: number;
    @Expose({name: 'type'})
    private readonly _type: string;

    constructor(id: number, type: string) {
        this._id = id;
        this._type = type;
    }

    public get id(): number {
        return this._id;
    }

    public get type(): string {
        return this._type;
    }
}
